import { CPriceTecRepositoryImpl } from 'features/products-pricetec/data/repositories/pricetec-repository-impl';
import { IPriceTecLab } from 'features/products-pricetec/domain/entities/pricetec-lab';
import { IPricetecReview } from 'features/products-pricetec/domain/entities/pricetec-review';
import { GetPriceTecLabs } from 'features/products-pricetec/domain/usecases/get-pricetec-labs';
import { GetPricetecReviews } from 'features/products-pricetec/domain/usecases/get-pricetec-reviews';
import React from 'react';

type TContextProps = {
	loading: boolean;
	error: string;
	pricetecLabs: IPriceTecLab[];
	getPriceTecLabs: () => Promise<void>;

	reviewsLoading: boolean;
	reviewsError: string;
	pricetecReviews: IPricetecReview[];
	getPricetecReviews: () => Promise<void>
}

export const PriceTecContext = React.createContext({} as TContextProps);

export const PriceTecProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState('');
	const [pricetecLabs, setPriceTecLabs] = React.useState([] as IPriceTecLab[]);

	const [reviewsLoading, setReviewsLoading] = React.useState(false);
	const [reviewsError, setReviewsError] = React.useState('');
	const [pricetecReviews, setPricetecReviews] = React.useState([] as IPricetecReview[]);

	const priceTecRepository = new CPriceTecRepositoryImpl();

	const getPriceTecLabs = async () => {
		setLoading(true);
		const labsUseCase = new GetPriceTecLabs(priceTecRepository);
		const labsResult = await labsUseCase.call();
		if (labsResult.isRight()) {
			setPriceTecLabs(labsResult.value);
		} else {
			setError(labsResult.error);
		}
		setLoading(false);
	};

	const getPricetecReviews = async () => {
		setReviewsLoading(true);
		const reviewsUseCase = new GetPricetecReviews(priceTecRepository);
		const reviewResult = await reviewsUseCase.call();
		if (reviewResult.isRight()) {
			setPricetecReviews(reviewResult.value);
		} else {
			setReviewsError(reviewResult.error);
		}
		setReviewsLoading(false);
	};

	const value = React.useMemo(() => ({
		loading,
		error,
		pricetecLabs,
		getPriceTecLabs,
		reviewsLoading,
		reviewsError,
		pricetecReviews,
		getPricetecReviews,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [loading,
		error,
		pricetecLabs, reviewsLoading,
		reviewsError,
		pricetecReviews]);

	return <PriceTecContext.Provider value={value}>{children}</PriceTecContext.Provider>;
};

export default function usePriceTec() {
	return React.useContext(PriceTecContext);
}
