import { Either } from '@ajtec/either-typescript';
import { IContactRepository } from '../repositories/contact-repository';
import { IContactReq } from '../entities/contact-req';

export class SubmitContact {
	repository: IContactRepository;

	constructor(contactRepository: IContactRepository) {
		this.repository = contactRepository;
	}

	call(params: IContactReq): Promise<Either<string, string>> {
		return this.repository.submitContact(params);
	}
}
