import {
	Box, Stack, styled, Typography, useTheme, Link,
} from '@mui/material';
import Dimens from 'assets/dimens';
import {
	call,
	facebook, linkedin, livro, mail, portugal,
} from 'assets/images';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import AppLinks from 'assets/applink.routes';
import { ajtec } from 'assets/images/icons';
import { politicaDePrivacy, politicaDeCookies, ficha2020 } from 'assets/pdf';
import SizedBox from '../sized-box/SizedBox';
import Space from '../space/Space';
import RemoteAssistance from '../remote-assistance/RemoteAssistance';
import ClientArea from '../client-area/ClientArea';

const LINKED_IN = 'https://pt.linkedin.com/company/ajtec';
const INSTAGRAM = 'https://www.instagram.com/ajtec.pt/';

interface ListItemProps {
	icon: React.ReactNode,
	title: string
}

const ListItem: React.FC<ListItemProps> = ({ icon, title }) => (
	<Stack direction="row">
		{icon}
		<Space width={Dimens.XS_SPACING} />
		<Typography sx={{ fontSize: Dimens.TEXT_DEFAULT, fontWeight: Dimens.TEXT_WEIGHT_LIGHT }}>
			{title}
		</Typography>
	</Stack>
);

const AppFooter = () => {
	const { palette } = useTheme();
	const { t } = useTranslation('translations');

	const BoldText = styled(Typography)(() => ({
		fontSize: Dimens.TEXT_DEFAULT,
		color: palette.textMain.main,
		fontWeight: Dimens.TEXT_WEIGHT_BOLD,
		lineHeight: Dimens.LINE_HEIGHT_DEFAULT,
	}));

	const DefaultText = styled(Typography)(() => ({
		fontSize: Dimens.TEXT_DEFAULT,
		color: palette.textMain.main,
		fontWeight: Dimens.TEXT_WEIGHT_LIGHT,
		lineHeight: Dimens.LINE_HEIGHT_DEFAULT,
	}));

	return (
		<footer>
			<Box sx={{
				backgroundImage: 'linear-gradient(180deg, rgba(103, 226, 194, 0.3) 0%, rgba(180, 255, 153, 0.1) 100%)',
				pt: { md: Dimens.FOOTER_PADDING_TOP, xs: Dimens.FOOTER_PADDING_TOP_MOBILE },
				pb: { md: Dimens.FOOTER_PADDING_BOTTOM, xs: Dimens.FOOTER_PADDING_BOTTOM_MOBILE },
				pr: { xs: 0, md: Dimens.APP_BAR_PADDING },
				pl: { xs: 2, md: Dimens.APP_BAR_PADDING },
			}}
			>
				<Box>
					<Box>
						<img src={ajtec} alt="logo" />
						<SizedBox height={4} />
					</Box>
					<Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
						<Typography sx={{
							fontSize: Dimens.TEXT_DEFAULT,
							fontWeight: Dimens.TEXT_WEIGHT_LIGHT,
							width: Dimens.FOOTER_TEXT_WIDTH,
						}}
						>
							{t('company_info')}
						</Typography>
						<Box sx={{ mt: { xs: 2, md: 0 } }}>
							<ListItem icon={<img src={call} alt="Call" />} title="(+351) 262 839 411" />
							<Typography sx={{
								fontSize: Dimens.TEXT_DEFAULT,
								fontWeight: Dimens.TEXT_WEIGHT_LIGHT,
								color: palette.textLight.main,
							}}
							>
								{t('fix_network_call')}
							</Typography>
							<SizedBox height={2} />
							<ListItem icon={<img src={mail} alt="Email" />} title="ajtec@ajtec.pt" />
						</Box>
						<Box sx={{ display: { xs: 'none', md: 'flex', flexDirection: 'column' } }}>
							<Link to={AppLinks.HOME} component={RouterLink} color="inherit" underline="none">
								<BoldText>
									{t('our_company')}
								</BoldText>
							</Link>
							<SizedBox height={1.5} />
							<Link to={AppLinks.ABOUT_US} component={RouterLink} color="inherit" underline="none">
								<DefaultText>
									{t('about_us')}
								</DefaultText>
							</Link>
							<SizedBox height={1} />
							<Link to={AppLinks.RESEARCH_LAB} component={RouterLink} color="inherit" underline="none">
								<DefaultText>
									{t('r&d_lab')}
								</DefaultText>
							</Link>
							<SizedBox height={1} />
							<Link to={AppLinks.CONTACT_US} component={RouterLink} color="inherit" underline="none">
								<DefaultText>
									{t('contact_us')}
								</DefaultText>
							</Link>
						</Box>
						<Box sx={{ display: { xs: 'none', md: 'flex', flexDirection: 'column' } }}>
							<Link to={AppLinks.PHC_SOLUTIONS} component={RouterLink} color="inherit" underline="none">
								<BoldText>
									{t('phc_solutions')}
								</BoldText>
							</Link>
							<SizedBox height={1.5} />
							<Link to={AppLinks.PRODUCTS} component={RouterLink} color="inherit" underline="none">
								<DefaultText sx={{ fontWeight: Dimens.TEXT_WEIGHT_BOLD }}>
									{t('our_products')}
								</DefaultText>
							</Link>
							<SizedBox height={1} />
							<Link to={AppLinks.FUEL_TEC} component={RouterLink} color="inherit" underline="none">
								<DefaultText>
									{t('fuel_tec')}
								</DefaultText>
							</Link>
							<SizedBox height={1} />
							<Link to={AppLinks.PRICE_TEC} component={RouterLink} color="inherit" underline="none">
								<DefaultText>
									{t('price_tec')}
								</DefaultText>
							</Link>
						</Box>
						<Box sx={{ display: { xs: 'none', md: 'flex', flexDirection: 'column' }, mt: { xs: 2, md: 0 } }}>
							<RemoteAssistance />
							<SizedBox height={3} />
							<ClientArea />
						</Box>
					</Stack>
					<Stack
						direction={{ md: 'row', xs: 'column-reverse' }}
						justifyContent="space-between"
						alignItems={{ md: 'flex-end', xs: 'center' }}
						sx={{ mt: { md: 10, xs: 1 } }}
					>
						<Stack direction="row" sx={{ display: { md: 'flex', xs: 'none' } }}>
							<Link href={LINKED_IN} target="_blank">
								<img src={linkedin} alt="Linkedin" />
							</Link>
							<Space width={Dimens.ICON_SPACING} />
							<Link href={INSTAGRAM} target="_blank">
								<img src={facebook} alt="Facebook" />
							</Link>
						</Stack>
						<Box sx={{ mb: 1, mt: { xs: 2, md: 0 } }}>
							<Typography sx={{
								fontSize: Dimens.TEXT_SMALL,
								fontWeight: Dimens.TEXT_WEIGHT_LIGHT,
							}}
							>
								{t('all_rights_reserved')}
								{' '}
								@
								{new Date().getFullYear()}
							</Typography>
						</Box>
						<a href={ficha2020} target="_blank" rel="noreferrer">
							<img src={portugal} alt="Portugal 2020" />
						</a>
						<Link to="https://www.livroreclamacoes.pt/inicio" component={RouterLink}>
							<img src={livro} alt="Livro d9 reclamacaos" height="44px" width="105px" />
						</Link>
					</Stack>
				</Box>
			</Box>
			<Stack
				direction={{ md: 'row', xs: 'column-reverse' }}
				justifyContent="space-between"
				sx={{
					bgcolor: palette.primary.dark,
					pr: { xs: 2, md: Dimens.APP_BAR_PADDING },
					pl: { xs: 2, md: Dimens.APP_BAR_PADDING },
					pt: Dimens.PADDING_MEDIUM,
					pb: Dimens.PADDING_MEDIUM,
				}}
			>
				<Typography sx={{
					color: palette.primary.contrastText,
					fontSize: Dimens.TEXT_DEFAULT,
					pt: { xs: Dimens.ICON_SPACING, md: 0 },
					textAlign: 'center',
				}}
				>
					{t('company_of')}
					{' '}
					<strong>Grupo Auto Júlio, S.A.</strong>
				</Typography>
				<Stack direction="row" justifyContent="center">

					<Link href={politicaDeCookies} target="_blank" rel="noreferrer">
						<Typography sx={{
							color: palette.primary.contrastText,
							fontSize: Dimens.TEXT_DEFAULT,
						}}
						>
							<strong>{t('cookies_policy')}</strong>
						</Typography>
					</Link>
					<Space width={Dimens.ICON_SPACING} />
					<Link href={politicaDePrivacy} target="_blank">
						<Typography sx={{
							color: palette.primary.contrastText,
							fontSize: Dimens.TEXT_DEFAULT,
						}}
						>
							<strong>{t('data_protection_policy')}</strong>
						</Typography>
					</Link>
				</Stack>
			</Stack>
		</footer>
	);
};

export default React.memo(AppFooter);
