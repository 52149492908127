import TalkToUs from 'features/home/presentation/TalkToUs';
import React from 'react';
import InnovationSection from './InnovationSection';
import { ResearchProvider } from './provider/research.provider';
import ResearchHero from './ResearchHero';
import WorkMethod from './WorkMethod';

const ResearchLab = () => (
	<main>
		<ResearchProvider>
			<ResearchHero />
			<InnovationSection />
			<WorkMethod />
			<TalkToUs />
		</ResearchProvider>
	</main>
);

export default ResearchLab;
