import {
	Box, OutlinedTextFieldProps, styled, TextField as MuiTextField, Typography, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import React from 'react';

type MuiOutlinedTextFieldProps = Omit<OutlinedTextFieldProps, 'variant'>

interface Props extends MuiOutlinedTextFieldProps {
    title?: string
}

const TextField = styled(MuiTextField)(({ theme }) => ({
	fieldset: {
		borderColor: theme.palette.secondary.main,
	},
	'& label.Mui-focused': {
		color: theme.palette.secondary.main,
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: theme.palette.secondary.main,
	},
	'& .MuiFilledInput-underline:after': {
		borderBottomColor: theme.palette.secondary.main,
	},
	'& .MuiOutlinedInput-root': {
		'&.Mui-focused fieldset': {
			borderColor: theme.palette.secondary.main,
		},
	},
}));

const DefaultInput: React.FC<Props> = (props) => {
	const { title, ...remainingProps } = props;
	const theme = useTheme();

	const StyledText = styled(Typography)(() => ({
		fontSize: Dimens.TEXT_MEDIUM,
		fontWeight: Dimens.TEXT_WEIGHT_LIGHT,
		lineHeight: Dimens.LINE_HEIGHT_MEDIUM,
		color: theme.palette.textMain.main,
	}));

	return (
		<Box sx={{ mt: 2 }}>
			<StyledText>{title}</StyledText>
			<TextField
				fullWidth
				size="small"
				variant="outlined"
				InputProps={{
					style: {
						borderTopLeftRadius: Dimens.FIELD_TOP_LEFT_RADIUS,
						borderTopRightRadius: Dimens.FIELD_TOP_RIGHT_RADIUS,
						borderBottomLeftRadius: Dimens.FIELD_BOTTOM_LEFT_RADIUS,
						borderBottomRightRadius: Dimens.FIELD_BOTTOM_RIGHT_RADIUS,
					},
				}}
				{...remainingProps}
			/>
		</Box>
	);
};

export default DefaultInput;
