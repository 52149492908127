import { Box } from '@mui/material';
import Dimens from 'assets/dimens';
import { BodyText, SizedBox, TitleSmall } from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ControlStoreSection = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Box sx={{
				mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				pl: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
				pr: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
			}}
			>
				<TitleSmall textAlign="center">
					{t('control_store_details_statistics')}
				</TitleSmall>
				<SizedBox height={7} showLine align="center" />
			</Box>
			<Box sx={{
				pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
				pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
			}}
			>
				<BodyText darktext textAlign="center">{t('control_store_details_statistics_para_one')}</BodyText>
				<SizedBox height={2} />
				<BodyText darktext textAlign="center">{t('control_store_details_statistics_para_two')}</BodyText>
			</Box>
		</section>
	);
};

export default ControlStoreSection;
