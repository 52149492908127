import { Either, Left, Right } from '@ajtec/either-typescript';
import { PhcProduct } from 'features/phc-business-managment/domain/entities/phc-product';
import { PhcSolution } from 'features/phc-business-managment/domain/entities/phc-solution';
import { IPhcBusinessRepository } from 'features/phc-business-managment/domain/repositories/phc-business-repository';
import { PhcBusinessApiImpl } from '../remote/phc-business-api-impl';

export class CPhcBusinessRepositoryImpl implements IPhcBusinessRepository {
	homeApi = new PhcBusinessApiImpl();

	async getPhcProducts(): Promise<Either<string, PhcProduct[]>> {
		try {
			const products = await this.homeApi.getPhcProducts();
			return new Right(products);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}

	async getPhcSolutions(): Promise<Either<string, PhcSolution[]>> {
		try {
			const products = await this.homeApi.getPhcSolutions();
			return new Right(products);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}
}
