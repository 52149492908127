import { Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import { mission, values, vision } from 'assets/images';
import { ProcessItem } from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CompanyMission = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Stack
				sx={{
					pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				}}
				direction={{ xl: 'row', xs: 'column' }}
				justifyContent="space-around"
				alignItems={{ xl: 'flex-start', xs: 'center' }}
			>
				<ProcessItem image={mission} title={t('mission')} description={t('mission_info')} />
				<ProcessItem image={vision} title={t('vision')} description={t('vision_info')} />
				<ProcessItem image={values} title={t('values')} description="Respeito Transparência e Lealdade Evolução Profissionalismo e Dedicação Confiança" />
			</Stack>
		</section>
	);
};

export default CompanyMission;
