import { Box, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import {
	Label, ProductCarousel, ProductSkeleton, SizedBox, TitleSmall,
} from 'common/presentation/components';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInViewRef } from 'rooks';
import { useNavigate } from 'react-router-dom';
import usePhcBusiness from './provider/phc-business.provider';

const CompanySolution = () => {
	const { phcProducts, loading, getPhcProducts } = usePhcBusiness();
	const [productRef, inView] = useInViewRef();
	const { t } = useTranslation('translations');
	const navigate = useNavigate();

	useEffectCustom(() => {
		if (inView && phcProducts.length === 0) {
			getPhcProducts();
		}
	}, [inView]);

	return (
		<section id="PHC Products" ref={productRef}>
			<Stack
				alignItems="center"
				sx={{
					pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
				}}
			>
				<Label title={t('operating_sectors')} />
				<SizedBox height={3} />
				<TitleSmall>
					{t('solutions_for_company_problems')}
				</TitleSmall>
				<Box sx={{ width: '100%', mt: 3 }}>
					{loading && <ProductSkeleton />}
					{!loading && (
						<ProductCarousel
							data={phcProducts}
							onClick={(id, link) => {
								if (link) {
									navigate(link);
								}
							}}
						/>
					)}
				</Box>
			</Stack>
		</section>
	);
};

export default CompanySolution;
