/* eslint-disable class-methods-use-this */
import { fueltecLogo, phcAdvanced, pricetecLogo } from 'assets/images';
import {
	businessIntegration, phcAdvance, phcCs, phcGo, phcGoDev, sophosPartner,
} from 'assets/images/partners';
import { PartnerModel } from '../dto/partner-model';
import { ProductModel } from '../dto/product-model';
import { QuestionModel } from '../dto/question-model';
import { HomeApi } from './home-api';
import questionEn from '../local/questions-en.json';
import questionPt from '../local/questions-pt.json';

const LANGUAGE_PT = 'pt';

export class HomeApiImpl implements HomeApi {
	getProducts(): Promise<ProductModel[]> {
		try {
			const products = [
				{
					id: 1,
					title: 'PHC ADVANCE PARTNER',
					description: 'Implementações de sistemas ERP PHC Gama Corporate e Advance.',
					image: phcAdvanced,
				},
				{
					id: 2,
					title: 'PRICETEC',
					description: 'Preçários LED para postos de combustível.',
					image: pricetecLogo,
				},
				{
					id: 3,
					title: 'FUELTEC',
					description: 'Controlo de bombas de combustível com PHC POS.',
					image: fueltecLogo,
				},
			];

			const productPromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(products);
				}, 300);
			});
			return productPromise.then(() => products);
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}

	getPartners(): Promise<PartnerModel[]> {
		try {
			const partners = [
				{ id: 1, image: businessIntegration },
				{ id: 2, image: phcGo },
				{ id: 3, image: phcGoDev },
				{ id: 4, image: phcCs },
				{ id: 5, image: phcAdvance },
				{ id: 6, image: sophosPartner },
			];
			const partnerPromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(partners);
				}, 300);
			});
			return partnerPromise.then(() => partners);
		} catch (error) {
			throw new Error('An Error Occured');
		}
	}

	getQuestions(lang: string): Promise<QuestionModel[]> {
		try {
			const questions = lang === LANGUAGE_PT ? questionPt : questionEn;
			const questionPromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(questions);
				}, 300);
			});
			return questionPromise.then(() => questions);
		} catch (error) {
			throw new Error('An Error Occured');
		}
	}
}
