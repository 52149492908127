/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */
import axios from 'axios';
import { IContactReq } from 'features/contact-us/domain/entities/contact-req';
import { IContactApi } from './contact-api';

export class CContactApiImpl implements IContactApi {
	async submitContact(params: IContactReq): Promise<string> {
		try {
			const resp = await axios({
				url: 'https://ajtec.pt/contact_req.php',
				method: 'POST',
				data: params,
			});
			const data = resp?.data?.resultMessage as string;
			return data;
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
