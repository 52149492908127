import { Either } from '@ajtec/either-typescript';
import { PhcComment } from '../entities/phc-comment';
import { IPhcPosRepository } from '../repositories/phc-pos-repository';

export class GetPhcComments {
	repository: IPhcPosRepository;

	constructor(phcRepository: IPhcPosRepository) {
		this.repository = phcRepository;
	}

	call(): Promise<Either<string, PhcComment[]>> {
		return this.repository.getPhcComments();
	}
}
