import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { Product } from 'features/home/domain/entities/product';
import React from 'react';
import Slider from 'react-slick';
import ProductCard from './ProductCard';

interface Props {
	data: Product[]
	onClick: (productId: number, link?: string) => void
}

const ProductCarousel: React.FC<Props> = (props) => {
	const { data, onClick } = props;
	const [currentSlide, setCurrentSlide] = React.useState(0);
	const theme = useTheme();

	const arrowSx = {
		'&:hover': {
			cursor: 'pointer',
			color: theme.palette.primary.main,
		},
	};

	const handleBeforeChange = (_: number, nextSlide: number) => {
		setCurrentSlide(nextSlide);
	};

	const settings = {
		dots: false,
		adaptiveHeight: true,
		arrows: true,
		prevArrow: <KeyboardArrowLeft
			fontSize="large"
			color="primary"
			sx={arrowSx}
		/>,
		nextArrow: <KeyboardArrowRight fontSize="large" color="primary" sx={arrowSx} />,
		variableWidth: true,
		centerMode: true,
		beforeChange: handleBeforeChange,
		responsive: [
			{
				breakpoint: 1536,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 0,
					infinite: false,
				},
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 0,
					infinite: false,
				},
			},
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 0,
					infinite: false,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
				},
			},
		],
	};

	return (
		<Slider {...settings}>
			{data.map((el, index) => (
				index === currentSlide ? (
					<ProductCard
						title={el.title}
						description={el.description}
						image={el.image}
						focused
						onClick={() => onClick(el.id, el.link)}
						key={el.id}
					/>
				) : (
					<ProductCard
						title={el.title}
						description={el.description}
						image={el.image}
						key={el.id}
						onClick={() => onClick(el.id, el.link)}
					/>
				)
			))}
		</Slider>
	);
};

export default ProductCarousel;
