import {
	Box, CircularProgress as MuiCircularProgress,
	circularProgressClasses,
	CircularProgressProps, Typography, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import React, { FC } from 'react';

type Props = CircularProgressProps & { value: number }

const CircularProgress:FC<Props> = (props) => {
	const { value } = props;
	const { palette } = useTheme();
	return (
		<Box sx={{
			position: 'relative',
			display: 'inline-flex',
			// transform: 'rotate(90deg)',
			filter: 'blur(0)',
		}}
		>
			<MuiCircularProgress
				variant="determinate"
				sx={{
					color: palette.success.main,
				}}
				size={120}
				thickness={0.05}
				{...props}
				value={100}
			/>
			<MuiCircularProgress
				thickness={4}
				color="secondary"
				variant="determinate"
				size={120}
				sx={{
					color: palette.success.main,
					animationDuration: '550ms',
					position: 'absolute',
					left: 0,
					[`& .${circularProgressClasses.circle}`]: {
						strokeLinecap: 'round',
					},
				}}
				{...props}
			/>
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					// transform: 'rotate(-90deg)',
				}}
			>
				<Typography
					variant="caption"
					component="div"
					sx={{ fontSize: '26px', color: palette.success.main, fontWeight: Dimens.TEXT_WEIGHT_BOLD }}
				>
					{`${value}%`}
				</Typography>
			</Box>
		</Box>
	);
};

export default CircularProgress;
