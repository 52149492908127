import {
	Box, IconButton, Modal, Stack,
	useTheme,
} from '@mui/material';
import ContactUsForm from 'features/contact-us/presentation/components/ContactUsForm';
import React from 'react';
import { Close } from '@mui/icons-material';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';
import TitleSmall from '../title-small/TitleSmall';
import BodyText from '../body-text/BodyText';

interface Props {
	open: boolean
	handleClose: () => void
}

const ContactDialog: React.FC<Props> = (props) => {
	const { open, handleClose } = props;
	const { t } = useTranslation('translations');
	const theme = useTheme();

	return (
		<Modal open={open} onClose={handleClose}>
			<Box sx={{
				p: { md: Dimens.FOOTER_PADDING_BOTTOM_MOBILE, xs: Dimens.PADDING_SMALL },
				bgcolor: theme.palette.background.default,
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				overflowY: 'auto',
				width: {
					xl: '50%',
					lg: '50%',
					md: '70%',
					xs: '98%',
					sm: '98%',
				},
				height: '90%',
			}}
			>
				<Stack direction="row" justifyContent="space-between">
					<TitleSmall>{t('free_demo_request')}</TitleSmall>
					<IconButton onClick={handleClose}>
						<Close />
					</IconButton>
				</Stack>
				<BodyText>
					{t('free_demo_request_info')}
				</BodyText>
				<ContactUsForm />
			</Box>
		</Modal>
	);
};

export default ContactDialog;
