import { Add, Remove } from '@mui/icons-material';
import {
	Collapse,
	IconButton,
	Stack,
	useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { BodyText } from 'common/presentation/components';
import React, { useState } from 'react';

interface Props {
	question: string;
	answer: string
}

const QuestionItem: React.FC<Props> = (props) => {
	const { question, answer } = props;
	const theme = useTheme();
	const [checked, setChecked] = useState(false);

	const handleChange = () => {
		setChecked((prev) => !prev);
	};

	return (
		<Stack sx={{ width: { md: '800px', xs: '250px' } }}>
			<Stack direction="row" alignItems="center" justifyContent="space-between">
				<BodyText sx={{ color: theme.palette.textMain.main }} fontWeight={Dimens.TEXT_WEIGHT_BOLD}>
					{question}
				</BodyText>
				<IconButton sx={{ p: 0, m: 0 }} onClick={handleChange}>
					{!checked && <Add color="primary" />}
					{checked && <Remove color="primary" />}
				</IconButton>
			</Stack>
			<Collapse component="div" in={checked} timeout="auto" unmountOnExit>
				<BodyText
					sx={{ color: theme.palette.textLight.main }}
					fontWeight={Dimens.TEXT_WEIGHT_NORMAL}
				>
					{answer}
				</BodyText>
			</Collapse>
		</Stack>
	);
};

export default QuestionItem;
