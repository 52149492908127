import { Box, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	title: string
}

const Label: React.FC<Props> = (props) => {
	const { title } = props;
	const { t } = useTranslation('translations');
	const { palette } = useTheme();
	return (
		<Box
			component="span"
			sx={{
				bgcolor: palette.secondary.light,
				color: palette.primary.light,
				borderRadius: '3px 15px 2px',
				padding: '10px 22px',
				fontWeight: 700,
			}}
		>
			{t(title)}
		</Box>
	);
};

export default Label;
