import { Either, Left, Right } from '@ajtec/either-typescript';
import { PhcComment } from 'features/phc-pos/domain/entities/phc-comment';
import { IPhcPosRepository } from 'features/phc-pos/domain/repositories/phc-pos-repository';
import { PhcPosApiImpl } from '../remote/phc-pos-api-impl';

export class CPhcPosRepositoryImpl implements IPhcPosRepository {
	phcPosApi = new PhcPosApiImpl();

	async getPhcComments(): Promise<Either<string, PhcComment[]>> {
		try {
			const products = await this.phcPosApi.getPhcComments();
			return new Right(products);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}
}
