import {
	Stack,
} from '@mui/material';
import Dimens from 'assets/dimens';
import {
	Archivebook, Graph, People, Refreshcircle,
} from 'assets/images';
import {
	BodyText, FeatureCard, SizedBox, TitleSmall,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ReachNextLevel = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Stack
				direction="column"
				alignItems="center"
				sx={{
					pt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
					pr: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
					pl: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
				}}
			>
				<TitleSmall textAlign="center">{t('reach_next_level')}</TitleSmall>
				<SizedBox height={4} showLine />
				<BodyText textAlign="center">{t('reach_next_level_info')}</BodyText>
			</Stack>
			<Stack
				direction="row"
				sx={{
					overflowX: 'scroll',
					'&::-webkit-scrollbar': { display: 'none' },
					scrollbarWidth: 'none',
					msOverflowStyle: 'none',
					pl: { xl: Dimens.APP_BAR_PADDING, xs: 2 },
					mt: { md: Dimens.SECTION_SPACING_MOBILE, xs: Dimens.ICON_SPACING },
					pb: Dimens.PADDING_MEDIUM,
				}}
			>
				<FeatureCard icon={<Archivebook />} title="Monitoriza e controla toda a sua informação" description="As aplicações de gestão são uma ferramenta imprescindível neste processo de gestão da informação. Desde logo, porque estão especificamente preparadas para gerir vários tipos de informação. Vai acabar por pensar como foi possível ter trabalhado sem estas ferramentas." />
				<FeatureCard icon={<People />} title="Agiliza a sua relação com clientes e fornecedores" description="Uma boa aplicação de gestão está desenhada para lhe facilitar a vida no que toca ao relacionamento e troca de informação com os seus fornecedores. Mais importante ainda, ajuda-o a melhorar o seu serviço aos clientes e as suas capacidades para os surpreender." />
				<FeatureCard icon={<Refreshcircle />} title="Liberta-o de procedimentos executados de forma manual" description="Com uma aplicação de software diminui o uso de papel, reduz os custos e ainda torna a sua informação mais integrada e acessível. Além de que não há o risco de se perder aquele dossier onde estava a única cópia daqueles contactos. O software faz backups de tudo." />
				<FeatureCard icon={<Graph color="primary" />} title="Garante-lhe análises e previsões fidedignas sobre o negócio " description="A capacidade de obter análises rigorosas e de realizar estimativas precisas, pode fazer toda a diferença e colocá-lo um passo à frente da concorrência. Um software de gestão é uma ferramenta que lhe traz rigor e lhe permite tomar decisões sustentadas para que a sua empresa chegue mais longe." />
			</Stack>
		</section>
	);
};

export default ReachNextLevel;
