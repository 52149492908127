import {
	AppBar, Box, Link, Stack, Toolbar, Typography, IconButton,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { logo } from 'assets/images';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import Dimens from 'assets/dimens';
import AppLinks from 'assets/applink.routes';
import LanguageSelector from '../language-selector/LanguageSelector';
import AppbarDropdown from '../phc-solutions/PhcSolutions';
import OurProductsMenu from '../our-products-menu/OurProductsMenu';
import NavMenuMobile from '../nav-menu-mobile/NavMenuMobile';

const Topbar = () => {
	const { t } = useTranslation('translations');

	const [mobileMenuOpen, setOpen] = React.useState(false);

	const RouteText = styled(Typography)(({ theme }) => ({
		color: theme.palette.textMain.main,
		fontWeight: Dimens.TEXT_WEIGHT_BOLD,
		fontSize: Dimens.TEXT_DEFAULT,
		lineHeight: Dimens.LINE_HEIGHT_DEFAULT,
	}));

	const handleOpenMenu = () => {
		setOpen(true);
	};

	const handleCloseMenu = () => {
		setOpen(false);
	};

	return (
		<header>
			<Box sx={{ flexGrow: 1 }}>
				<AppBar
					position="fixed"
					color="inherit"
					sx={{
						pr: { xl: Dimens.APP_BAR_PADDING, md: 1 },
						pl: { xl: Dimens.APP_BAR_PADDING, md: 1 },
					}}
				>
					<Toolbar>
						<Box sx={{
							display: 'flex',
							flex: 1,
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
						>
							<Box sx={{ mr: 1 }}>
								<Link to={AppLinks.HOME} component={RouterLink} color="inherit" underline="none">
									<img
										src={logo}
										style={{ paddingTop: '10px', paddingBottom: '10px' }}
										alt="logo"
									/>
								</Link>
							</Box>
							<Box sx={{ display: { md: 'none', xs: 'flex' } }}>
								<IconButton color="inherit" onClick={handleOpenMenu}>
									<MenuIcon />
								</IconButton>
							</Box>
							<Box sx={{
								display: { md: 'flex', xs: 'none' },
								flexDirection: 'row',
								justifyContent: 'space-between',
								alignItems: 'center',
								flex: 1,
							}}
							>
								<Stack
									direction="row"
									justifyContent="space-evenly"
									sx={{
										flex: 1,
									}}
								>
									<Link to={AppLinks.HOME} component={RouterLink} color="inherit" underline="none">
										<RouteText>{t('home_page')}</RouteText>
									</Link>
									<Link to={AppLinks.ABOUT_US} component={RouterLink} color="inherit" underline="none">
										<RouteText>{t('about_us')}</RouteText>
									</Link>
									<Link to={AppLinks.RESEARCH_LAB} component={RouterLink} color="inherit" underline="none">
										<RouteText>{t('r&d_lab')}</RouteText>
									</Link>
									<AppbarDropdown title={t('phc_solutions')} />
									<OurProductsMenu title={t('our_products')} />
									<Link to={AppLinks.CONTACT_US} component={RouterLink} color="inherit" underline="none">
										<RouteText>{t('contact_us')}</RouteText>
									</Link>
								</Stack>
								<Box>
									<LanguageSelector />
								</Box>
							</Box>
						</Box>
					</Toolbar>
				</AppBar>
			</Box>
			<NavMenuMobile open={mobileMenuOpen} handleClose={handleCloseMenu} />
		</header>
	);
};

export default React.memo(Topbar);
