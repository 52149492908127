import React from 'react';
import { Box, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
// import { logoGrey } from 'assets/images';
import { useTranslation } from 'react-i18next';
import {
	SizedBox, TitleMedium, BodyText, ProductSkeleton, ProductCarousel,
} from 'common/presentation/components';
import { useInViewRef } from 'rooks';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import { useNavigate } from 'react-router-dom';
import { getProductDetailUrl } from 'common/utils/helpers';
import useHome from './provider/home.provider';

const OurSolutions = () => {
	const { t } = useTranslation('translations');
	const [productRef, inView] = useInViewRef();
	const { products, loading, getProducts } = useHome();
	const navigate = useNavigate();

	useEffectCustom(() => {
		if (inView && products.length === 0) {
			getProducts();
		}
	}, [inView]);

	return (
		<section id="solutions" ref={productRef}>
			<Stack
				alignItems="center"
				sx={{
					pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				}}
			>
				<TitleMedium textAlign="center">
					{t('our_solutions_description')}
				</TitleMedium>
				<SizedBox height={1.8} />
				<BodyText textAlign="center">
					{t('part_of_our_portfolio')}
				</BodyText>
				<SizedBox height={5} />
				<Box sx={{ width: '100%' }}>
					{loading && <ProductSkeleton />}
					{!loading && (
						<ProductCarousel
							data={products}
							onClick={(id) => navigate(getProductDetailUrl(id))}
						/>
					)}
				</Box>
			</Stack>
		</section>
	);
};

export default OurSolutions;
