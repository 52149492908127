import TalkToUs from 'features/home/presentation/TalkToUs';
import React from 'react';
import AboutusHero from './AboutusHero';
import CompanyMission from './CompanyMission';
import LabTour from './LabTour';
import { AboutProvider } from './provider/about.provider';
import WhatWeDo from './WhatWeDo';

const AboutUs = () => (
	<main>
		<AboutProvider>
			<AboutusHero />
			<CompanyMission />
			<WhatWeDo />
			<LabTour />
			<TalkToUs />
		</AboutProvider>
	</main>
);

export default AboutUs;
