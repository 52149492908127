import { Stack, useTheme } from '@mui/material';
import Dimens from 'assets/dimens';
import { BodyText, SizedBox, TitleSmall } from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CompetitiveManagment = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	return (
		<section>
			<Stack
				alignItems="center"
				sx={{
					pl: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
					pr: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
					pt: {
						md: Dimens.SECTION_SPACING,
						xs: Dimens.SECTION_SPACING_MOBILE,
					},
					pb: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				}}
			>
				<TitleSmall textAlign="center">
					{t('make_your_company_competitive')}
				</TitleSmall>
				<SizedBox height={6} showLine />
				<BodyText sx={{ color: theme.palette.textMain.main }} textAlign="center">
					{t('make_your_company_competitive_info_para_one')}
				</BodyText>
				<SizedBox height={6} />
				<BodyText sx={{ color: theme.palette.textMain.main }} textAlign="center">
					{t('make_your_company_competitive_info_para_two')}
				</BodyText>
			</Stack>
		</section>
	);
};

export default CompetitiveManagment;
