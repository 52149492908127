import { Either, Right } from '@ajtec/either-typescript';
import { IPriceTecLab } from 'features/products-pricetec/domain/entities/pricetec-lab';
import { IPricetecReview } from 'features/products-pricetec/domain/entities/pricetec-review';
import { IPriceTecRepository } from 'features/products-pricetec/domain/repositories/pricetec-repository';
import { PricetecApiImpl } from '../remote/pricetec-api-impl';

export class CPriceTecRepositoryImpl implements IPriceTecRepository {
	priceTecApi = new PricetecApiImpl();

	async getPriceTecLabs(): Promise<Either<string, IPriceTecLab[]>> {
		try {
			const pricetecLabs = await this.priceTecApi.getPricetecLabs();
			return new Right(pricetecLabs);
		} catch (error) {
			throw new Error('An error occured');
		}
	}

	async getPricetecReviews(): Promise<Either<string, IPricetecReview[]>> {
		try {
			const pricetecReviews = await this.priceTecApi.getPriceTecReviews();
			return new Right(pricetecReviews);
		} catch (error) {
			throw new Error('An Error Occured');
		}
	}
}
