import { Box, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import {
	pricetecOne, pricetecThree, pricetecTwo,
} from 'assets/images';
import {
	BodyText, SizedBox, Space,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PriceTecSolution = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Stack
				direction="row"
				alignItems="center"
				sx={{
					pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				}}
			>
				<Box sx={{ width: { md: '50%', xs: '100%' } }}>
					<BodyText darktext>
						{t('price_tec_package_info_one')}
					</BodyText>
					<SizedBox height={3} />
					<BodyText darktext>
						{t('price_tec_package_info_two')}
					</BodyText>
					<SizedBox height={3} />
					<BodyText darktext>
						{t('price_tec_package_info_three')}
					</BodyText>
				</Box>
				<Box sx={{
					width: '50%', display: { md: 'flex', xs: 'none' }, flexDirection: 'column', alignItems: 'center',
				}}
				>
					<img src={pricetecOne} alt="team meeting" style={{ alignSelf: 'flex-end' }} />
					<SizedBox height={4} />
					<Stack sx={{ width: '100%' }} direction="row" justifyContent="flex-end">
						<img src={pricetecTwo} alt="team meeting" height="452px" width="300px" />
						<Space width={Dimens.ICON_SPACING} />
						<img src={pricetecThree} alt="team meeting" height="152px" width="228px" />
					</Stack>
				</Box>
			</Stack>
		</section>
	);
};

export default PriceTecSolution;
