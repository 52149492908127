import { ArrowRightAlt } from '@mui/icons-material';
import { Box, Stack, useTheme } from '@mui/material';
import AppLinks from 'assets/applink.routes';
import Dimens from 'assets/dimens';
import { stairGroup, officeWork, aboutUsImageOne } from 'assets/images';
import {
	BodyText, DefaultButton, SizedBox, TitleMedium,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const CompanyMoto = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<section>
			<Stack
				direction="row"
				alignItems="center"
				sx={{
					pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				}}
			>
				<Box sx={{ width: { md: '50%', xs: '100%' } }}>
					<TitleMedium>
						{t('power_and_simplicity_of_technology')}
					</TitleMedium>
					<SizedBox showLine height={6} />
					<BodyText sx={{ color: theme.palette.textMain.main }}>
						{t('company_history')}
					</BodyText>
					<SizedBox height={7} />
					<DefaultButton variant="text" sx={{ pl: 0 }} onClick={() => navigate(AppLinks.ABOUT_US)} endIcon={<ArrowRightAlt />}>
						{t('discover_us')}
					</DefaultButton>
				</Box>
				<Box sx={{
					width: '50%', display: { md: 'flex', xs: 'none' }, flexDirection: 'column', alignItems: 'center',
				}}
				>
					<Box sx={{
						height: '547px',
						width: '547px',
						backgroundImage: 'radial-gradient(rgb(103, 226, 194, 0.5), rgba(180, 255, 153, 0.3))',
						borderRadius: 547 / 2,
						filter: 'blur(87px)',
						zIndex: -1,
					}}
					/>
					<img src={aboutUsImageOne} alt="team meeting" width="60%" style={{ marginTop: '-80%', alignSelf: 'flex-end', borderRadius: Dimens.FIELD_TOP_RIGHT_RADIUS }} />
					<SizedBox height={4} />
					<Stack sx={{ width: '100%' }} direction="row" justifyContent="space-evenly">
						<img src={stairGroup} alt="team meeting" height="427px" />
						<img src={officeWork} alt="team meeting" height="264px" />
					</Stack>
				</Box>
			</Stack>
		</section>
	);
};

export default CompanyMoto;
