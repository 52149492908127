import { Box, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import {
	BodyText, SizedBox, TitleSmall,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SecurityGuarantee = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Stack>
				<Box sx={{
					width: { xl: '60%', xs: '100%' },
					mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
					pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
				}}
				>
					<BodyText darktext>{t('fuel_tec_pos_info_para_one')}</BodyText>
					<SizedBox height={3} />
					<BodyText darktext>{t('fuel_tec_pos_info_para_two')}</BodyText>
					<SizedBox height={3} />
					<BodyText darktext>{t('fuel_tec_pos_info_para_three')}</BodyText>
				</Box>
			</Stack>
			<Stack
				sx={{
					pl: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
					pr: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
					mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				}}
				alignItems="center"
			>
				<TitleSmall textAlign="center">
					{t('fuel_tec_pos_info_title')}
				</TitleSmall>
				<SizedBox height={5} />
				<BodyText textAlign="center">
					{t('fuel_tec_pos_info_para_four')}
				</BodyText>
				<SizedBox height={3} />
				<BodyText textAlign="center">
					{t('fuel_tec_pos_info_para_five')}
				</BodyText>
				<SizedBox height={3} />
				<BodyText textAlign="center">
					{t('fuel_tec_pos_info_para_six')}
				</BodyText>
				<SizedBox height={3} />
				{/* <DefaultButton variant="text" sx={{ p: 0, m: 0 }}
				endIcon={<ArrowRightAlt />}>{t('know_out_opt_system')}</DefaultButton> */}
			</Stack>
		</section>
	);
};

export default SecurityGuarantee;
