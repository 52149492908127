import { ChevronRight } from '@mui/icons-material';
import { Stack } from '@mui/material';
import AppLinks from 'assets/applink.routes';
import Dimens from 'assets/dimens';
import {
	BodyText, DefaultButton, SizedBox, TitleLarge,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const TalkToUs = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();

	const handleClick = () => {
		navigate(AppLinks.CONTACT_US);
	};

	return (
		<section>
			<Stack
				alignItems="center"
				sx={{
					pl: { xl: Dimens.APP_BAR_PADDING, xs: 2 },
					pr: { xl: Dimens.APP_BAR_PADDING, xs: 2 },
					pt: {
						md: Dimens.SECTION_SPACING,
						xs: Dimens.SECTION_SPACING_MOBILE,
					},
					pb: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				}}
			>
				<TitleLarge textAlign="center">
					{t('talk_to_us')}
				</TitleLarge>
				<SizedBox showLine height={6} align="center" />
				<BodyText textAlign="center">
					{t('our_clients_description')}
				</BodyText>
				<SizedBox height={3} />
				<BodyText textAlign="center">
					{t('our_software_places_description')}
				</BodyText>
				<SizedBox height={4} />
				<DefaultButton endIcon={<ChevronRight />} onClick={handleClick}>
					{t('want_to_contact')}
				</DefaultButton>
			</Stack>
		</section>
	);
};

export default TalkToUs;
