enum Dimens{
	// Padding
	APP_BAR_PADDING = '200px',
	FOOTER_PADDING_TOP = '160px',
	FOOTER_PADDING_TOP_MOBILE = '55px',
	FOOTER_PADDING_BOTTOM = '60px',
	FOOTER_PADDING_BOTTOM_MOBILE = '20px',
	PADDING_MEDIUM = '30px',
	PADDING_SMALL = '10px',
	PADDING_MEDIUM_MOBILE = '20px',
	PAGE_PADDING_LARGE = '470px',

	// Margins

    // Text
	TEXT_SMALL = '12px',
    TEXT_DEFAULT = '14px',
	TEXT_MEDIUM_SMALL = '16px',
	TEXT_MEDIUM = '20px',
	TEXT_TITLE = '50px',
	TEXT_TITLE_MEDIUM = '48px',
	TEXT_TITLE_SMALL = '36px',
	TEXT_TITLE_EXTRA_SMALL = '30px',
	TEXT_TITLE_EXTRA_SMALL_MOBILE = '18px',
	TEXT_TITLE_SMALL_MOBILE = '22px',
	TEXT_TITLE_MOBILE = '28px',
	TEXT_TITLE_LARGE_MOBILE = '38px',
	TEXT_SUBTITLE = '32px',

	// Line Height
	LINE_HEIGHT_DEFAULT = '20px',
	LINE_HEIGHT_MEDIUM_SMALL = '24px',
	LINE_HEIGHT_MEDIUM = '30px',

	// Font Weights
	TEXT_WEIGHT_BOLD = '700',
	TEXT_WEIGHT_LIGHT = '400',
	TEXT_WEIGHT_NORMAL = '500',

    // Menu
	FOOTER_TEXT_WIDTH = '285px',
	DEFAULT_CARD_WIDTH = '400px',
	CARD_WIDTH_UNFOCUSED = '301px',
	CARD_WIDTH_UNFOCUSED_MOBILE = '198px',
	DEFAULT_CARD_WIDTH_MOBILE= '264px',
	DEFAULT_CARD_HEIGHT = '456px',
	CARD_HEIGHT_UNFOCUSED = '344px',
	CARD_HEIGHT_UNFOCUSED_MOBILE = '226px',
	DEFAULT_CARD_HEIGHT_MOBILE= '300px',
	CARD_HEIGHT_SMALL = '176px',
	CARD_WIDTH_SMALL = '160px',

	COMMENT_CARD_WIDTH = '578px',
	COMMENT_CARD_HEIGHT = '385px',
	COMMENT_CARD_WIDTH_MOBILE = '360px',
	COMMENT_CARD_HEIGHT_MOBILE = '240px',

	PARTNER_CARD_WIDTH = '904px',
	PARTNER_CARD_HEIGHT = '502px',
	PARTNER_CARD_WIDTH_MOBILE = '264px',
	PARTNER_CARD_HEIGHT_MOBILE = '301px',

	FEATURE_CARD_WIDTH = '352px',
	FEATURE_CARD_HEIGHT = '354px',
	FEATURE_CARD_WIDTH_MOBILE = '300px',
	FEATURE_CARD_HEIGHT_MOBILE = '300px',

	LARGE_MENU_HEIGHT = '872px',
	LARGE_MENU_WIDTH = '825px',
	SMALL_MENU_HEIGHT = '380px',

	// Border Radius
	CARD_BORDER_RADIUS = '28px',
	CARD_BORDER_RADIUS_MOBILE = '18px',
	FIELD_TOP_LEFT_RADIUS= '4px',
	FIELD_TOP_RIGHT_RADIUS= '20px',
	FIELD_BOTTOM_LEFT_RADIUS= '4px',
	FIELD_BOTTOM_RIGHT_RADIUS= '4px',

	// Spacing
	ICON_SPACING = '38px',
	XS_SPACING = '15px',
	MD_SPACING = '18px',
	USER_ACTION_SPACING = '80px',
	SECTION_SPACING = '256px',
	SECTION_SPACING_MOBILE = '80px'
}

export default Dimens;
