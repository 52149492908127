import { Either } from '@ajtec/either-typescript';
import { IPricetecReview } from '../entities/pricetec-review';
import { IPriceTecRepository } from '../repositories/pricetec-repository';

export class GetPricetecReviews {
	repository: IPriceTecRepository;

	constructor(fueltecRepository: IPriceTecRepository) {
		this.repository = fueltecRepository;
	}

	call(): Promise<Either<string, IPricetecReview[]>> {
		return this.repository.getPricetecReviews();
	}
}
