import { Box, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import { BodyText, SizedBox, TitleSmall } from 'common/presentation/components';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInViewRef } from 'rooks';
import useFuelTec from './provider/fueltec.provider';

const FuelTecPartners = () => {
	const { t } = useTranslation('translations');
	const { partners, getPartners, loading } = useFuelTec();

	const [partnersRef, inView] = useInViewRef();

	useEffectCustom(() => {
		if (inView && partners.length === 0) {
			getPartners();
		}
	}, [inView]);

	return (
		<section id="Fuel tec Partners" ref={partnersRef}>
			<Stack
				direction="column"
				alignItems="center"
				sx={{
					mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
					pl: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
					pr: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
				}}
			>
				<BodyText textAlign="center" darktext>
					{t('fuel_tec_info_para_one')}
				</BodyText>
				<SizedBox height={2} />
				<BodyText textAlign="center" darktext>
					{t('fuel_tec_info_para_two')}
				</BodyText>
				<SizedBox height={4} />
				<TitleSmall>
					{t('fuel_tec_partner')}
				</TitleSmall>
				<SizedBox height={6} showLine align="center" />
				<Stack spacing={3} alignItems="center" direction="row" flexWrap="wrap">
					{!loading && partners.map((el) => <Box key={el.id}><img src={el.image} alt={`fuel tec partner ${el.id}`} /></Box>)}
				</Stack>
			</Stack>
		</section>
	);
};

export default FuelTecPartners;
