import React from 'react';
import {
	Stack, Switch, Typography, styled,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'common/presentation/providers/cookies.provider';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
	width: 37,
	height: 22,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		margin: 1,
		padding: 2,
		transform: 'translateX(1px)',
		'&.Mui-checked': {
			color: '#fff',
			transform: 'translateX(15px)',
			'& .MuiSwitch-thumb:before': { backgroundColor: theme.palette.success.main },
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor: theme.palette.textMain.light,
			},
		},
	},
	'& .MuiSwitch-thumb': {
		width: 16,
		height: 16,
		'&:before': {
			content: "''",
			position: 'absolute',
			width: '100%',
			height: '100%',
			left: 0,
			top: 0,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundColor: theme.palette.error.main,
			borderRadius: 19,
		},
	},
	'& .MuiSwitch-track': {
		opacity: 1,
		backgroundColor: theme.palette.textMain.main,
		borderRadius: 31 / 2,
	},
}));

const SocialMediaText = styled(Typography)(({ theme }) => ({
	fontSize: Dimens.TEXT_SMALL,
	color: theme.palette.textLight.main,
	fontWeight: Dimens.TEXT_WEIGHT_LIGHT,
	'&:hover': {
		cursor: 'pointer',
	},
}));

const CookieSwitch = () => {
	const { cookie, handleChangeCookie } = useCookies();
	const { t } = useTranslation('translations');
	const handleChange = () => {
		handleChangeCookie();
	};
	return (
		<Stack direction="row" sx={{ opacity: { xs: 0.8, md: 1 }, bgcolor: { md: 'transparent' } }} spacing={1} alignItems="center">
			<SocialMediaText>{t('manage_cookies')}</SocialMediaText>
			<MaterialUISwitch checked={cookie} onChange={handleChange} />
		</Stack>
	);
};

export default CookieSwitch;
