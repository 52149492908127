import { Either } from '@ajtec/either-typescript';
import { ILogisticComments } from '../entities/logistic-comments';
import { ILogisticsRepository } from '../repositories/logistic-repository';

export class GetLogisticComments {
	repository: ILogisticsRepository;

	constructor(logisticRepository: ILogisticsRepository) {
		this.repository = logisticRepository;
	}

	call(): Promise<Either<string, ILogisticComments[]>> {
		return this.repository.getLogisticComments();
	}
}
