import React from 'react';
import { ContactProvider } from 'features/contact-us/presentation/provider/contact.provider';
import BusinessAutomation from './BusinessAutomation';
import FleetMobilityHero from './FleetMobilityHero';
import FleetSolutions from './FleetSolutions';
// eslint-disable-next-line import/no-unresolved
import GetInTouchFleet from './GetInTouchFleet';
import { FleetMobilityProvider } from './provider/fleet-mobility.provider';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SuccessStories from './SuccessStories';

const FleetAndMobility = () => (
	<main>
		<FleetMobilityProvider>
			<ContactProvider>
				<FleetMobilityHero />
				<FleetSolutions />
				<BusinessAutomation />
				{/* <SuccessStories /> */}
				<GetInTouchFleet />
			</ContactProvider>
		</FleetMobilityProvider>
	</main>
);

export default FleetAndMobility;
