import { Box, useTheme } from '@mui/material';
import React from 'react';

interface Props {
	height: number;
	showLine?: boolean;
	align?: 'center' | 'start'
}

const SizedBox: React.FC<Props> = (props) => {
	const theme = useTheme();
	const { height, showLine, align = 'start' } = props;
	return (
		<Box sx={{
			pt: theme.spacing(height / 2),
			pb: theme.spacing(height / 2),
			display: 'flex',
			flexDirection: 'column',
			alignItems: align,
		}}
		>
			{showLine && (
				<Box
					component="center"
					sx={{
						bgcolor: theme.palette.success.main,
						height: '4px',
						width: '84px',
						opacity: 0.3,
						borderRadius: '2px 0px',
						filter: 'blur(0.5px)',
					}}
				/>
			)}
		</Box>
	);
};

export default SizedBox;
