import { Typography, TypographyProps, useTheme } from '@mui/material';
import Dimens from 'assets/dimens';
import React from 'react';

interface Props extends TypographyProps {
	children: string
}

const TitleSmall: React.FC<Props> = (props) => {
	const theme = useTheme();
	const {
		children, sx,
	} = props;
	return (
		<Typography
			variant="h3"
			{...props}
			sx={{
				fontWeight: Dimens.TEXT_WEIGHT_BOLD,
				lineHeight: {
					lg: Dimens.TEXT_TITLE_SMALL,
					xl: Dimens.TEXT_TITLE_SMALL,
					md: Dimens.TEXT_TITLE_SMALL,
					xs: Dimens.TEXT_TITLE_SMALL,
				},
				fontSize: {
					lg: Dimens.TEXT_TITLE_SMALL,
					xl: Dimens.TEXT_TITLE_SMALL,
					md: Dimens.TEXT_TITLE_SMALL,
					xs: Dimens.TEXT_TITLE_SMALL_MOBILE,
					sm: Dimens.TEXT_TITLE_SMALL_MOBILE,
				},
				color: theme.palette.textMain.main,
				...sx,
			}}
		>
			{children}

		</Typography>
	);
};

export default TitleSmall;
