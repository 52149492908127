import { Either } from '@ajtec/either-typescript';
import { IHomeRepository } from 'features/home/domain/repositories/home-repository';
import { Product } from '../entities/product';

export class GetProducts {
	repository: IHomeRepository;

	constructor(homeRepository: IHomeRepository) {
		this.repository = homeRepository;
	}

	call(): Promise<Either<string, Product[]>> {
		return this.repository.getProducts();
	}
}
