import { Card, SvgIcon, useTheme } from '@mui/material';
import Dimens from 'assets/dimens';
import { BodyText } from 'common/presentation/components';
import React, { useState } from 'react';

interface Props {
	title: string;
	icon: React.ReactNode;
	onClick: () => void
}

const SolutionCard: React.FC<Props> = (props) => {
	const { title, icon, onClick } = props;
	const { palette } = useTheme();

	const [mouseOver, setMouseOver] = useState(false);

	const handleMouseOver = () => {
		setMouseOver(true);
	};

	const handleMouseOut = () => {
		setMouseOver(false);
	};

	return (
		<Card
			elevation={4}
			onMouseOver={handleMouseOver}
			onMouseOut={handleMouseOut}
			sx={{
				minHeight: Dimens.CARD_HEIGHT_SMALL,
				minWidth: Dimens.CARD_WIDTH_SMALL,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'space-around',
				ml: 5,
				bgcolor: mouseOver ? palette.primary.main : palette.background.default,
				cursor: 'pointer',
			}}
			onClick={onClick}
		>
			<SvgIcon
				component="div"
				sx={{ color: mouseOver ? palette.background.default : palette.primary.main, height: '45px', width: '45px' }}
			>
				{icon}
			</SvgIcon>
			<BodyText
				textAlign="center"
				maxLines={1}
				sx={{
					fontSize: Dimens.TEXT_DEFAULT,
					fontWeight: Dimens.TEXT_WEIGHT_NORMAL,
					color: mouseOver ? palette.background.default : palette.textMain.main,
					pl: 1,
					pr: 1,
				}}
			>
				{title}
			</BodyText>
		</Card>
	);
};

export default SolutionCard;
