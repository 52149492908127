import { CPhcPosRepositoryImpl } from 'features/phc-pos/data/repositories/phc-pos-repository-impl';
import { PhcComment } from 'features/phc-pos/domain/entities/phc-comment';
import { GetPhcComments } from 'features/phc-pos/domain/usecases/get-phc-comments';
import React from 'react';

type TProps = {
	loading: boolean;
	error: string;
	phcComments: PhcComment[];
	getPhcComments: () => Promise<void>
}

const PhcPosContext = React.createContext({} as TProps);

export const PhcPosProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState('');
	const [phcComments, setPhcComments] = React.useState([] as PhcComment[]);

	const phcPosRepository = new CPhcPosRepositoryImpl();

	const getPhcComments = async () => {
		setLoading(true);
		const phcPosUseCase = new GetPhcComments(phcPosRepository);
		const commentsResult = await phcPosUseCase.call();
		if (commentsResult.isRight()) {
			setPhcComments(commentsResult.value);
		} else {
			setError(commentsResult.error);
		}
		setLoading(false);
	};

	const value = React.useMemo(() => ({
		loading,
		error,
		phcComments,
		getPhcComments,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [loading, error, phcComments]);

	return <PhcPosContext.Provider value={value}>{children}</PhcPosContext.Provider>;
};

export default function usePhcPos() {
	return React.useContext(PhcPosContext);
}
