import { Skeleton, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import React from 'react';

const ProductSkeleton = () => {
	const width = { md: Dimens.DEFAULT_CARD_WIDTH, xs: Dimens.DEFAULT_CARD_WIDTH_MOBILE };
	const height = { md: Dimens.DEFAULT_CARD_HEIGHT, xs: Dimens.DEFAULT_CARD_HEIGHT_MOBILE };

	const smallWidth = { md: Dimens.CARD_WIDTH_UNFOCUSED, xs: Dimens.CARD_WIDTH_UNFOCUSED_MOBILE };
	const smallHeight = { md: Dimens.CARD_HEIGHT_UNFOCUSED, xs: Dimens.CARD_HEIGHT_UNFOCUSED_MOBILE };
	return (
		<Stack direction="row" justifyContent={{ md: 'space-between', xs: 'center' }}>
			<Skeleton variant="rectangular" sx={{ display: { xs: 'none', md: 'flex' }, height: smallHeight, width: smallWidth }} />
			<Skeleton variant="rectangular" sx={{ height, width }} />
			<Skeleton variant="rectangular" sx={{ display: { xs: 'none', md: 'flex' }, height: smallHeight, width: smallWidth }} />
		</Stack>
	);
};

export default ProductSkeleton;
