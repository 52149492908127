import { Box, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import {
	ActionButtons, BodyText, SizedBox, TitleLarge,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FuelTecHero = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Box sx={{ pl: { md: Dimens.APP_BAR_PADDING, xs: 2 }, pr: { md: 0, xs: 2 }, pb: 5 }}>
				<ActionButtons text={`${t('our_products')}/FUELTEC`} showLine />
			</Box>
			<Stack
				direction={{ md: 'row', xs: 'column' }}
				sx={{
					pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					mt: { md: Dimens.PADDING_MEDIUM, xs: Dimens.PADDING_MEDIUM_MOBILE },
				}}
			>
				<Box sx={{ width: { md: '70%', xs: '100%' } }}>
					<TitleLarge>
						{t('fuel_pump_control')}
					</TitleLarge>
					<SizedBox height={3} />
					<BodyText darktext>
						{t('fuel_pump_control_info')}
					</BodyText>
					<SizedBox height={3} />
					{/* <DefaultButton variant="text" sx={{ p: 0, m: 0 }} endIcon={<ArrowRightAlt />}>
						{t('know_products_and_complements')}
					</DefaultButton> */}
				</Box>
			</Stack>
		</section>
	);
};

export default FuelTecHero;
