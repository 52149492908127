import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { ptFlag, ukFlag } from 'assets/images';
import useLanguage from 'common/presentation/providers/language.provider';
import i18next from 'i18next';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
	width: 51,
	height: 31,
	padding: 1,
	'& .MuiSwitch-switchBase': {
		margin: 1,
		padding: 2,
		transform: 'translateX(0px)',
		'&.Mui-checked': {
			color: '#fff',
			transform: 'translateX(20px)',
			'& .MuiSwitch-thumb:before': { backgroundImage: `url(${ptFlag})` },
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor: theme.palette.textMain.main,
			},
		},
	},
	'& .MuiSwitch-thumb': {
		width: 25,
		height: 25,
		'&:before': {
			content: "''",
			position: 'absolute',
			width: '100%',
			height: '100%',
			left: 0,
			top: 0,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundImage: `url(${ukFlag})`,
		},
	},
	'& .MuiSwitch-track': {
		opacity: 1,
		backgroundColor: theme.palette.textMain.main,
		borderRadius: 31 / 2,
	},
}));

const LanguageSelector = () => {
	const { changeLocale } = useLanguage();

	const handleChange = () => {
		const lang = i18next.language.substring(0, 2) === 'en' ? 'pt' : 'en';
		const locale = i18next.language.substring(0, 2) === 'en' ? 'ptPT' : 'enUS';
		i18next.changeLanguage(lang);
		changeLocale(locale);
	};

	return (
		<MaterialUISwitch checked={i18next.language.substring(0, 2) === 'pt'} onChange={handleChange} />
	);
};

export default LanguageSelector;
