import { Either, Left, Right } from '@ajtec/either-typescript';
import { Comment } from 'features/phc-business-core/domain/entities/comment';
import { Feature } from 'features/phc-business-core/domain/entities/feature';
import { IBusinessCoreRepository } from 'features/phc-business-core/domain/repositories/business-core-repository';
import { BusinessCoreApiImpl } from '../remote/business-core-api-impl';

export class CBusinessCoreRepositoryImpl implements IBusinessCoreRepository {
	businessCoreApi = new BusinessCoreApiImpl();

	async getProducts(): Promise<Either<string, Feature[]>> {
		try {
			const products = await this.businessCoreApi.getFeatures();
			return new Right(products);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}

	async getComments(): Promise<Either<string, Comment[]>> {
		try {
			const comments = await this.businessCoreApi.getComments();
			return new Right(comments);
		} catch (error) {
			return new Left('An Error Occured');
		}
	}
}
