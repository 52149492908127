import { Either } from '@ajtec/either-typescript';
import { Feature } from '../entities/feature';
import { IBusinessCoreRepository } from '../repositories/business-core-repository';

export class GetFeatures {
	repository: IBusinessCoreRepository;

	constructor(homeRepository: IBusinessCoreRepository) {
		this.repository = homeRepository;
	}

	call(): Promise<Either<string, Feature[]>> {
		return this.repository.getProducts();
	}
}
