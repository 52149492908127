import LocalStorageWrapper, { LocalStorageKeys } from 'common/utils/storage.utils';
import React, { useMemo } from 'react';

export interface IContextProps {
	cookie: boolean
	handleChangeCookie: () => void
}

export const CookieContext = React.createContext({} as IContextProps);

export const CookieProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const mCookie = LocalStorageWrapper.get(LocalStorageKeys.COOKIES) as boolean;
	const [cookie, setCookie] = React.useState(mCookie || false);

	const handleChangeCookie = () => {
		setCookie(!cookie);
		LocalStorageWrapper.set(LocalStorageKeys.COOKIES, !cookie);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const value = useMemo(() => ({ cookie, handleChangeCookie }), [cookie]);

	return <CookieContext.Provider value={value}>{children}</CookieContext.Provider>;
};

export function useCookies() {
	return React.useContext(CookieContext);
}
