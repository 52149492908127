import { Either } from '@ajtec/either-typescript';
import { PhcProduct } from '../entities/phc-product';
import { IPhcBusinessRepository } from '../repositories/phc-business-repository';

export class GetPhcProducts {
	repository: IPhcBusinessRepository;

	constructor(homeRepository: IPhcBusinessRepository) {
		this.repository = homeRepository;
	}

	call(): Promise<Either<string, PhcProduct[]>> {
		return this.repository.getPhcProducts();
	}
}
