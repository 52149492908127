import { Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import { BodyText, Label, SizedBox } from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DevelopmentCycle } from './components';

const OurServices = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Stack
				alignItems="center"
				sx={{
					pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				}}
			>
				<Label title="our_services" />
				<SizedBox height={3} />
				<BodyText textAlign="center">
					{t('design_exceptional_product_and_result')}
				</BodyText>
				<SizedBox height={5} />
			</Stack>
			<DevelopmentCycle />
		</section>
	);
};

export default OurServices;
