import BusinessNeeds from 'features/logistics/presentation/BusinessNeeds';
import React from 'react';
import { ContactProvider } from 'features/contact-us/presentation/provider/contact.provider';
import { TechnicalSupportProvider } from './provider/technical-support.provider';
import TechnicalCompanyGains from './TechnicalCompanyGains';
import TechnicalContact from './TechnicalContact';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import TechnicalReviews from './TechnicalReviews';
import TechnicalSupportHero from './TechnicalSupportHero';

const TechnicalSupport = () => (
	<main>
		<TechnicalSupportProvider>
			<ContactProvider>
				<TechnicalSupportHero />
				<TechnicalCompanyGains />
				<BusinessNeeds />
				{/* <TechnicalReviews /> */}
				<TechnicalContact />
			</ContactProvider>
		</TechnicalSupportProvider>
	</main>
);

export default TechnicalSupport;
