/* eslint-disable class-methods-use-this */
import {
	doms, phcCs, ryd, tokheim,
} from 'assets/images/fueltec-partners';
import { FuelTecPartnerModel } from '../dto/fueltec-partner-model';
import { FuelTecReviewModel } from '../dto/fueltec-review-model';
import { FueltecApi } from './fueltec-api';

export class FuelTecApiImpl implements FueltecApi {
	getFueltecPartners(): Promise<FuelTecPartnerModel[]> {
		try {
			const partners = [
				{ id: 1, image: tokheim },
				{ id: 2, image: phcCs },
				{ id: 3, image: doms },
				{ id: 4, image: ryd },
			];
			const partnerPromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(partners);
				}, 300);
			});
			return partnerPromise.then(() => partners);
		} catch (error) {
			throw new Error('An error Occured');
		}
	}

	getFuelTecReviews(): Promise<FuelTecReviewModel[]> {
		try {
			const comments = [
				{
					id: 1, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 2, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 3, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 4, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 5, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
			];
			const commentPromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(comments);
				}, 300);
			});
			return commentPromise.then(() => comments);
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
