import { ThemeProvider, Theme, createTheme } from '@mui/material';
import { LanguageProvider } from 'common/presentation/providers/language.provider';
import { getDesignTokens } from 'common/utils/theme.util';
import Main from 'Main';
import React, { useState } from 'react';
import './App.css';
import CookiesDialog from 'common/presentation/components/cookies-dialog/CookiesDialog';
import { useCookies } from 'common/presentation/providers/cookies.provider';
import { FeedbackProvider } from 'common/presentation/providers/feedback.provider';

const App = () => {
	const [appTheme, setAppTheme] = React.useState<Theme>();
	const { cookie } = useCookies();
	const [show, setShow] = useState(cookie);

	React.useEffect(() => {
		getDesignTokens('light').then((th) => {
			setAppTheme(createTheme(th));
		});
	}, []);

	const handleShow = () => {
		setShow(true);
	};

	return (
		<div className="App">
			<LanguageProvider>
				<FeedbackProvider>
					{
						!appTheme ? <div /> : (
							<ThemeProvider theme={appTheme}>
								<CookiesDialog show={!show} setShow={handleShow} />
								<Main />
							</ThemeProvider>
						)
					}
				</FeedbackProvider>
			</LanguageProvider>
		</div>
	);
};

export default App;
