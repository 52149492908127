import { CFleetMobilityRepositoryImpl } from 'features/fleet-mobility/data/repositories/fleet-mobility-repository-impl';
import { AutomationFeature } from 'features/fleet-mobility/domain/entities/automation-feature';
import { MobilityComment } from 'features/fleet-mobility/domain/entities/mobility-comment';
import { GetAutomationFeatures } from 'features/fleet-mobility/domain/usecases/get-automation-features';
import { GetMobilityComments } from 'features/fleet-mobility/domain/usecases/get-mobility-comments';
import React from 'react';

type TContextProps = {
	loading: boolean;
	error: string;
	automationFeatures: AutomationFeature[];
	getAutomationFeatures: () => Promise<void>;

	commentsLoading: boolean;
	commentsError: string;
	mobilityComments: MobilityComment[];
	getMobilityComments: () => Promise<void>
}

const FleetMobilityContext = React.createContext({} as TContextProps);

export const FleetMobilityProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState('');
	const [automationFeatures, setAutomationFeatures] = React.useState([] as AutomationFeature[]);

	const [commentsLoading, setCommentsLoading] = React.useState(false);
	const [commentsError, setCommentsError] = React.useState('');
	const [mobilityComments, setMobilityComments] = React.useState([] as MobilityComment[]);

	const fleetMobilityRepository = new CFleetMobilityRepositoryImpl();

	const getAutomationFeatures = async () => {
		setLoading(true);
		const automationFeaturesUseCase = new GetAutomationFeatures(fleetMobilityRepository);
		const automationFeaturesResult = await automationFeaturesUseCase.call();
		if (automationFeaturesResult.isRight()) {
			setAutomationFeatures(automationFeaturesResult.value);
		} else {
			setError(automationFeaturesResult.error);
		}
		setLoading(false);
	};

	const getMobilityComments = async () => {
		setCommentsLoading(true);
		const mobilityCommentsUseCase = new GetMobilityComments(fleetMobilityRepository);
		const mobityCommentsResult = await mobilityCommentsUseCase.call();
		if (mobityCommentsResult.isRight()) {
			setMobilityComments(mobityCommentsResult.value);
		} else {
			setCommentsError(mobityCommentsResult.error);
		}
		setCommentsLoading(false);
	};

	const value = React.useMemo(() => ({
		loading,
		error,
		automationFeatures,
		getAutomationFeatures,
		commentsLoading,
		commentsError,
		mobilityComments,
		getMobilityComments,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [loading, error, automationFeatures]);

	return <FleetMobilityContext.Provider value={value}>{children}</FleetMobilityContext.Provider>;
};

export default function useFleetMobility() {
	return React.useContext(FleetMobilityContext);
}
