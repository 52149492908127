import { Either } from '@ajtec/either-typescript';
import { Comment } from '../entities/comment';
import { IBusinessCoreRepository } from '../repositories/business-core-repository';

export class GetComments {
	repository: IBusinessCoreRepository;

	constructor(homeRepository: IBusinessCoreRepository) {
		this.repository = homeRepository;
	}

	call(): Promise<Either<string, Comment[]>> {
		return this.repository.getComments();
	}
}
