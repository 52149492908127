import {
	Skeleton, Stack, useMediaQuery, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { ILabs } from 'features/about-us/domain/entities/lab-images';
import React, { useState } from 'react';

interface Props {
	labs: ILabs[];
	loading?: boolean
}

const ImageScrollView: React.FC<Props> = (props) => {
	const { labs, loading } = props;
	const theme = useTheme();
	const isXl = useMediaQuery(theme.breakpoints.up('xl'));
	const [width, setWidth] = useState('578px');
	const [height, setHeight] = useState('385px');

	React.useEffect(() => {
		if (isXl) {
			setWidth('578px');
			setHeight('385px');
		} else {
			setWidth('300px');
			setHeight('200px');
		}
	}, [isXl]);

	return (
		<Stack
			direction="row"
			sx={{
				overflowX: 'scroll',
				'&::-webkit-scrollbar': { display: 'none' },
				scrollbarWidth: 'none',
				msOverflowStyle: 'none',
				zindex: 1,
				pl: isXl ? 0 : 2,
			}}
		>
			{loading && (
				<Stack width="100%" height={height} direction="row" justifyContent="space-around">
					<Skeleton variant="rectangular" sx={{ display: { xl: 'flex', xs: 'none' }, height: { xl: '578px', xs: '300px' }, width: { xl: '385px', xs: '200px' } }} />
					<Skeleton variant="rectangular" sx={{ height: { xl: '578px', xs: '300px' }, width: { xl: '385px', xs: '200px' } }} />
					<Skeleton variant="rectangular" sx={{ display: { xl: 'flex', xs: 'none' }, height: { xl: '578px', xs: '300px' }, width: { xl: '385px', xs: '200px' } }} />
				</Stack>
			)}
			{!loading && labs.map((lab, index) => (
				<img
					src={lab.image}
					key={lab.id}
					alt={`lab ${index}`}
					style={{
						marginRight: Dimens.PADDING_MEDIUM,
						height,
						width,
						borderRadius: Dimens.FIELD_TOP_RIGHT_RADIUS,
					}}
				/>
			))}
		</Stack>
	);
};

export default ImageScrollView;
