import { Box, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import { phcCsPos } from 'assets/images';
import {
	ActionButtons, BodyText, SizedBox, TitleLarge,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PhcPosHero = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Stack sx={{
				pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
			}}
			>
				<ActionButtons text={`${t('phc_solutions')} / PHC CS POS`} showLine />
				<Stack direction={{ xl: 'row', xs: 'column' }} sx={{ mt: { md: Dimens.SECTION_SPACING_MOBILE, xs: Dimens.MD_SPACING }, pr: { md: Dimens.APP_BAR_PADDING, xs: 2 } }}>
					<Box sx={{ width: { xl: '50%', xs: '100%' }, display: { md: 'flex', xs: 'none' } }}>
						<img src={phcCsPos} width="80%" alt="Fleet and Mobility" />
					</Box>
					<Stack direction="column" alignItems="flex-end" sx={{ mt: 4, width: { xl: '50%' } }}>
						<TitleLarge textAlign={{ xl: 'right', xs: 'left' }}>{t('business_success_access')}</TitleLarge>
						<SizedBox height={6} />
						<BodyText textAlign={{ xl: 'right', xs: 'left' }}>
							{t('business_success_access_info')}
						</BodyText>
						<SizedBox height={5} />
						{/* <DefaultButton sx={{ m: 0, p: 0 }} variant="text"
						endIcon={<ArrowRightAlt />}>{t('know_our_success_cases')}</DefaultButton> */}
					</Stack>
				</Stack>
			</Stack>
		</section>
	);
};

export default PhcPosHero;
