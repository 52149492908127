import {
	Box, Stack, Typography, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import {
	call, gps, location, mail,
} from 'assets/images';
import {
	ActionButtons,
	BodyText,
	SizedBox,
	Space,
	TitleLarge,
	TitleSmall,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ContactUsForm from './components/ContactUsForm';

interface Props {
	icon: React.ReactNode;
	title: string;
	description?: string
}

const ListItemWithIcon: React.FC<Props> = (props) => {
	const { icon, title, description } = props;
	const { palette } = useTheme();
	return (
		<Box sx={{ mt: 2 }}>
			<Stack direction="row">
				{icon}
				<Space width={Dimens.XS_SPACING} />
				<Typography sx={{ fontSize: Dimens.TEXT_DEFAULT, fontWeight: Dimens.TEXT_WEIGHT_LIGHT }}>
					{title}
				</Typography>
			</Stack>
			<Typography sx={{
				fontSize: Dimens.TEXT_DEFAULT,
				fontWeight: Dimens.TEXT_WEIGHT_LIGHT,
				color: palette.textLight.main,
			}}
			>
				{description}
			</Typography>
		</Box>
	);
};

const ContactForm = () => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	return (
		<section>
			<ActionButtons />
			<Stack
				direction={{ md: 'row', xs: 'column' }}
				alignItems="center"
				justifyContent="space-between"
				spacing={4}
				sx={{
					pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pt: { md: Dimens.USER_ACTION_SPACING, xs: Dimens.MD_SPACING },
					pb: { md: Dimens.USER_ACTION_SPACING, xs: Dimens.MD_SPACING },
				}}
			>
				<Box sx={{ width: { xl: '60%', xs: '100%' } }}>
					<TitleLarge>
						{t('our_solutions_fit_info')}
					</TitleLarge>
					<SizedBox height={4} />
					<TitleSmall
						sx={{ color: theme.palette.secondary.light, fontWeight: Dimens.TEXT_WEIGHT_NORMAL }}
					>
						{t('make_your_contact')}
					</TitleSmall>
					<SizedBox height={4} />
					<BodyText>
						{t('make_your_contact_info')}
					</BodyText>
					<ContactUsForm />
				</Box>
				<Box sx={{ display: { xl: 'block', xs: 'none' }, width: { xl: '30%', xs: '100%' } }}>
					<TitleSmall fontSize={Dimens.TEXT_SUBTITLE}>
						{t('our_contacts')}
					</TitleSmall>
					<SizedBox height={3} />
					<ListItemWithIcon icon={<img src={call} alt="Call" />} title="(+351) 262 839 411" description={t('fix_network_call')} />
					<ListItemWithIcon icon={<img src={mail} alt="Email" />} title="ajtec@ajtec.pt" />
					<ListItemWithIcon icon={<img src={location} alt="Email" />} title="Rua General Amílcar Mota 43, Edifício BP 2500-209 Caldas da Rainha" />
					<ListItemWithIcon icon={<img src={gps} alt="Email" />} title="GPS 39°23'32.2" />
				</Box>
			</Stack>
		</section>
	);
};

export default ContactForm;
