/* eslint-disable class-methods-use-this */
import {
	labFour, labOne, labThree, labTwo,
} from 'assets/images/lab';
import { CLabModel } from '../dto/lab-model';
import { IAboutApi } from './about-api';

export class CAboutApiImpl implements IAboutApi {
	getLabs(): Promise<CLabModel[]> {
		try {
			const labs = [{
				id: 1,
				image: labOne,
			},
			{
				id: 2,
				image: labTwo,
			},
			{
				id: 3,
				image: labThree,
			},
			{
				id: 4,
				image: labFour,
			},
			];

			const labsPromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(labs);
				}, 300);
			});

			return labsPromise.then(() => labs);
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
