import { Box, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import { laptopFleet } from 'assets/images';
import {
	ActionButtons, BodyText, SizedBox, TitleLarge,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FleetMobilityHero = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Stack sx={{
				pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
				pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
			}}
			>
				<ActionButtons text={`${t('phc_solutions')} / ${t('fleet_and_mobility')}`} showLine />
				<Stack direction={{ md: 'row', xs: 'column' }} sx={{ mt: { md: Dimens.SECTION_SPACING_MOBILE, xs: Dimens.MD_SPACING } }}>
					<Box sx={{ width: { md: '55%', xs: '100%' } }}>
						<TitleLarge>{t('fleet_managment')}</TitleLarge>
						<SizedBox height={6} />
						<BodyText>
							{t('fleet_managment_info')}
						</BodyText>
						<SizedBox height={5} />
						{/* <DefaultButton sx={{ m: 0, p: 0 }} variant="text"
						endIcon={<ArrowRightAlt />}>{t('know_our_success_cases')}</DefaultButton> */}
					</Box>
					<Box sx={{ mt: 7, width: { md: '45%' }, display: { md: 'flex', xs: 'none' } }}>
						<img src={laptopFleet} width="90%" alt="Fleet and Mobility" />
					</Box>
				</Stack>
			</Stack>
		</section>
	);
};

export default FleetMobilityHero;
