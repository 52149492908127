import { Either, Left, Right } from '@ajtec/either-typescript';
import { IContactReq } from 'features/contact-us/domain/entities/contact-req';
import { IContactRepository } from 'features/contact-us/domain/repositories/contact-repository';
import { CContactApiImpl } from '../remote/contact-api-impl';

export class CContactRepositoryImpl implements IContactRepository {
	contactApi = new CContactApiImpl();

	async submitContact(params: IContactReq): Promise<Either<string, string>> {
		try {
			const contactResp = await this.contactApi.submitContact(params);
			return new Right(contactResp);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}
}
