import {
	Box, Stack, SvgIcon, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { Archivetick } from 'assets/images';
import React from 'react';
import BodyText from '../body-text/BodyText';
import Space from '../space/Space';

interface Props {
	icon?: string;
	title: string;
	description?: string
}

const FeatureListItem: React.FC<Props> = (props) => {
	const { icon, title, description } = props;
	const theme = useTheme();
	return (
		<Stack direction="row" alignItems="flex-start">
			{!icon ? <SvgIcon component="div"><Archivetick /></SvgIcon> : <img src={icon} alt="Tick" />}
			<Space width={Dimens.ICON_SPACING} />
			<Box>
				<BodyText
					sx={{ color: theme.palette.textMain.main, fontWeight: Dimens.TEXT_WEIGHT_BOLD }}
				>
					{title}
				</BodyText>
				{description && (
					<BodyText
						sx={{
							fontWeight: Dimens.TEXT_WEIGHT_LIGHT,
							fontSize: Dimens.TEXT_DEFAULT,
							mt: 1,
						}}
					>
						{description}
					</BodyText>
				)}
			</Box>
		</Stack>
	);
};

export default FeatureListItem;
