import { Typography, TypographyProps, useTheme } from '@mui/material';
import Dimens from 'assets/dimens';
import React from 'react';

interface Props extends TypographyProps {
	children: string;
	darktext?: boolean;
	maxLines?: number
}

const BodyText: React.FC<Props> = (props) => {
	const {
		children, sx, darktext, maxLines = 100, ...remainingProps
	} = props;
	const theme = useTheme();
	return (
		<Typography
			{...remainingProps}
			sx={{
				fontSize: { md: Dimens.TEXT_MEDIUM, xs: Dimens.TEXT_DEFAULT },
				fontWeight: Dimens.TEXT_WEIGHT_LIGHT,
				color: darktext ? theme.palette.textMain.main : theme.palette.textLight.main,
				overflow: 'hidden',
				display: '-webkit-box',
				WebkitBoxOrient: 'vertical',
				WebkitLineClamp: maxLines,
				whiteSpace: 'pre-wrap',
				...sx,
			}}
		>
			{children}
		</Typography>
	);
};

export default BodyText;
