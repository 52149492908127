import { Either } from '@ajtec/either-typescript';
import { IHomeRepository } from 'features/home/domain/repositories/home-repository';
import { Partner } from '../entities/partner';

export class GetPartners {
	repository: IHomeRepository;

	constructor(homeRepository: IHomeRepository) {
		this.repository = homeRepository;
	}

	call(): Promise<Either<string, Partner[]>> {
		return this.repository.getPartners();
	}
}
