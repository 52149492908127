import {
	Box, Stack, Theme, useMediaQuery, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { aboutUsImageOne, ajtecMeeting } from 'assets/images';
import {
	ActionButtons,
	BodyText,
	SizedBox, TitleLarge, TitleSmall,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AboutusHero = () => {
	const { t } = useTranslation('translations');
	const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
	const theme = useTheme();
	return (
		<section>
			<ActionButtons />
			<Box sx={{
				pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
				pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
			}}
			>
				<SizedBox height={5} />
				<TitleLarge textAlign="center" sx={{ mr: { xl: Dimens.APP_BAR_PADDING, md: 0 } }}>
					{t('organizational_growth_info')}
				</TitleLarge>
				<Stack direction={{ md: 'row', xs: 'column' }} justifyContent="space-between" alignItems="center">
					<Box sx={{ width: { md: '45%', xs: '100%' } }}>
						<SizedBox height={8} />
						<TitleSmall>
							{t('who_we_are')}
						</TitleSmall>
						<SizedBox height={6} showLine />
						<BodyText sx={{ color: theme.palette.textMain.main }}>
							{t('about_ajtec_first_para')}
						</BodyText>
						<br />
						<BodyText sx={{ color: theme.palette.textMain.main }}>
							{t('about_ajtec_second_para')}
						</BodyText>
						<br />
						<BodyText sx={{ color: theme.palette.textMain.main }}>
							{t('about_ajtec_third_para')}
						</BodyText>
					</Box>
					<Box sx={{
						width: { md: '45%', xs: '100%' },
						height: '100%',
						position: 'relative',
						pt: { xl: 17, xs: 4 },
					}}
					>
						<Box sx={{
							position: 'absolute',
							top: 0,
							right: 0,
							height: '60%',
							width: '55%',
							backgroundImage: 'linear-gradient(180deg, rgba(103, 226, 194, 0.7) 0%, rgba(180, 255, 153, 0.5) 100%)',
							borderRadius: '50%',
							filter: 'blur(87px)',
							zIndex: -1,
						}}
						/>
						<Stack
							direction="row"
							alignItems="flex-end"
							justifyContent="center"
							spacing={2}
							sx={{
								width: '100%',
								zIndex: -1,
							}}
						>
							<img src={ajtecMeeting} alt="team meeting" width={isMd ? '70%' : '100%'} />
							{isMd && <img src={aboutUsImageOne} alt="CEO" width="50%" style={{ borderRadius: Dimens.FIELD_TOP_RIGHT_RADIUS, marginBottom: '20%' }} />}
						</Stack>
					</Box>
				</Stack>
			</Box>
		</section>
	);
};

export default AboutusHero;
