import { ILabs } from 'features/research-lab/domain/entities/lab-images';
import { GetLabs } from 'features/research-lab/domain/usecases/getLabs';
import { CResearchRepositoryImpl } from 'features/research-lab/data/repositories/research-repository-impl';
import React, { createContext } from 'react';

type ResearchContextProps = {
	loading: boolean;
	error: string;
	labs: ILabs[];
	getLabs: () => Promise<void>
}

const ResearchContext = createContext({} as ResearchContextProps);

export const ResearchProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [labs, setLabs] = React.useState([] as ILabs[]);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState('');

	const ResearchRepository = new CResearchRepositoryImpl();

	const getLabs = async () => {
		setLoading(true);
		const labsUseCase = new GetLabs(ResearchRepository);
		const labsResult = await labsUseCase.call();
		if (labsResult.isRight()) {
			setLabs(labsResult.value);
		} else {
			setError(labsResult.error);
		}
		setLoading(false);
	};

	const value = React.useMemo(() => ({
		labs,
		loading,
		error,
		getLabs,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [labs, loading, error]);

	return <ResearchContext.Provider value={value}>{children}</ResearchContext.Provider>;
};

export default function useResearch() {
	return React.useContext(ResearchContext);
}
