import { Either } from '@ajtec/either-typescript';
import { MobilityComment } from '../entities/mobility-comment';
import { IFleetMobilityRepository } from '../repositories/fleet-mobility-repository';

export class GetMobilityComments {
	repository: IFleetMobilityRepository;

	constructor(mobilityRepository: IFleetMobilityRepository) {
		this.repository = mobilityRepository;
	}

	call(): Promise<Either<string, MobilityComment[]>> {
		return this.repository.getMobilityComments();
	}
}
