import { CBusinessCoreRepositoryImpl } from 'features/phc-business-core/data/repositories/business-core-repository-impl';
import { Comment } from 'features/phc-business-core/domain/entities/comment';
import { Feature } from 'features/phc-business-core/domain/entities/feature';
import { GetComments } from 'features/phc-business-core/domain/usecases/get-comments';
import { GetFeatures } from 'features/phc-business-core/domain/usecases/get-features';
import React from 'react';

type TContextProps = {
	loading: boolean;
	error: string;
	features: Feature[];
	getFeatures: () => Promise<void>;
	commentsLoading: boolean;
	commentsError: string;
	comments: Comment[];
	getComments: () => Promise<void>
}

const BusinessCoreContext = React.createContext({} as TContextProps);

export const BusinessCoreProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState('');
	const [features, setfeatures] = React.useState([] as Feature[]);

	const [commentsLoading, setcommentsLoading] = React.useState(false);
	const [commentsError, setCommentsError] = React.useState('');
	const [comments, setComments] = React.useState([] as Comment[]);

	const phcBusinessRepository = new CBusinessCoreRepositoryImpl();

	const getFeatures = async () => {
		setLoading(true);
		const featureUseCase = new GetFeatures(phcBusinessRepository);
		const productResult = await featureUseCase.call();
		if (productResult.isRight()) {
			setfeatures(productResult.value);
		} else {
			setError(productResult.error);
		}
		setLoading(false);
	};

	const getComments = async () => {
		setcommentsLoading(true);
		const commentUseCase = new GetComments(phcBusinessRepository);
		const commentResult = await commentUseCase.call();
		if (commentResult.isRight()) {
			setComments(commentResult.value);
		} else {
			setCommentsError(commentResult.error);
		}
		setcommentsLoading(false);
	};

	const value = React.useMemo(() => ({
		loading,
		error,
		features,
		getFeatures,
		commentsLoading,
		commentsError,
		comments,
		getComments,
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [loading,
		error,
		features,
		commentsLoading,
		commentsError,
		comments]);

	return <BusinessCoreContext.Provider value={value}>{children}</BusinessCoreContext.Provider>;
};

export default function useBusinessCore() {
	return React.useContext(BusinessCoreContext);
}
