import { ChevronRight } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import { notfound } from 'assets/images';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppLinks from 'assets/applink.routes';
import DefaultButton from '../default-button/DefaultButton';
import SizedBox from '../sized-box/SizedBox';
import TitleSmall from '../title-small/TitleSmall';

const NotFound = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	return (
		<section>
			<Stack alignItems="flex-start" sx={{ pr: { md: Dimens.APP_BAR_PADDING, xs: 2 } }} direction={{ md: 'row', xs: 'column' }}>
				<Box sx={{ width: { md: '55%', xs: '100%' } }}><img src={notfound} alt="Not found" width="100%" /></Box>
				<Box sx={{
					width: { md: '45%', xs: '100%' }, display: 'flex', flexDirection: 'column', alignItems: 'center',
				}}
				>
					<SizedBox height={5} />
					<TitleSmall textAlign="center">
						{t('not_found_info')}
					</TitleSmall>
					<SizedBox height={12} />
					<DefaultButton
						onClick={() => navigate(AppLinks.PHC_SOLUTIONS)}
						endIcon={<ChevronRight />}
					>
						{t('discover_phc_solutions')}
					</DefaultButton>
					<SizedBox height={5} />
					<DefaultButton
						onClick={() => navigate(AppLinks.FUEL_TEC)}
						endIcon={<ChevronRight />}
					>
						{t('discover_fueltec_product')}
					</DefaultButton>
					<SizedBox height={5} />
				</Box>
			</Stack>
		</section>
	);
};

export default NotFound;
