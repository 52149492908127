import {
	Box, Stack, Typography, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import React from 'react';

interface Props {
	images: string[];
	title: string;
	description: string
}

const ProductListItem: React.FC<Props> = (props) => {
	const { images, title, description } = props;
	const { palette } = useTheme();
	return (
		<Stack alignItems="center">
			<Stack direction="row">
				{images.map((el, index) => <img width="100%" style={{ marginRight: '15px' }} src={el} alt={`products - ${index + 1}`} />)}
			</Stack>
			<Box sx={{ width: { md: Dimens.DEFAULT_CARD_WIDTH, xs: '100%' }, mt: 3 }}>
				<Typography
					textAlign="center"
					sx={{
						color: palette.textMain.main,
						fontWeight: Dimens.TEXT_WEIGHT_NORMAL,
						fontSize: {
							md: Dimens.TEXT_TITLE_EXTRA_SMALL,
							xs: Dimens.TEXT_TITLE_EXTRA_SMALL_MOBILE,
						},
						lineHeight: Dimens.LINE_HEIGHT_MEDIUM,
					}}
				>
					{title}
				</Typography>
				<Typography
					textAlign="center"
					sx={{
						color: palette.textLight.main,
						mt: 1,
						fontWeight: Dimens.TEXT_WEIGHT_LIGHT,
						fontSize: Dimens.TEXT_DEFAULT,
						lineHeight: Dimens.LINE_HEIGHT_MEDIUM_SMALL,
					}}
				>
					{description}

				</Typography>
			</Box>
		</Stack>
	);
};

export default ProductListItem;
