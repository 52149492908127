import { Either } from '@ajtec/either-typescript';
import { ICompanyFeature } from '../entities/company-features';
import { ILogisticsRepository } from '../repositories/logistic-repository';

export class GetCompanyFeatures {
	repository: ILogisticsRepository;

	constructor(logisticRepository: ILogisticsRepository) {
		this.repository = logisticRepository;
	}

	call(): Promise<Either<string, ICompanyFeature[]>> {
		return this.repository.getCompanyFeatures();
	}
}
