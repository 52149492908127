import { ArrowRightAlt } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import AppLinks from 'assets/applink.routes';
import Dimens from 'assets/dimens';
import { phcBusinessCoresLaptop } from 'assets/images';
import {
	ActionButtons, BodyText, DefaultButton, SizedBox, TitleLarge,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const PhcBusinessCoreHero = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	return (
		<section>
			<Box sx={{ pl: { md: Dimens.APP_BAR_PADDING, xs: 2 }, pr: { md: 0, xs: 2 }, pb: 5 }}>
				<ActionButtons text="Soluções PHC / Business Core" showLine />
			</Box>
			<Stack
				direction={{ md: 'row', xs: 'column' }}
				sx={{
					pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
				}}
			>
				<Box sx={{ width: { md: '60%', xs: '100%' } }}>
					<TitleLarge>
						{t('farward_looking_business_managment')}
					</TitleLarge>
					<SizedBox height={3} />
					<BodyText>
						{t('farward_looking_business_managment_info')}
					</BodyText>
					<SizedBox height={3} />
					<DefaultButton variant="text" onClick={() => navigate(AppLinks.CONTACT_US)} sx={{ p: 0, m: 0 }} endIcon={<ArrowRightAlt />}>
						{t('ask_free_diagnosis')}
					</DefaultButton>
				</Box>
				<Box sx={{
					mt: 7, width: { md: '40%' }, display: { md: 'flex', xs: 'none' },
				}}
				>
					<img src={phcBusinessCoresLaptop} width="100%" alt="PHC " />
				</Box>
			</Stack>
		</section>
	);
};

export default PhcBusinessCoreHero;
