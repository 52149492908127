import {
	Box, Stack, Typography, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { airdrop } from 'assets/images';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Space from '../space/Space';

const RemoteAssistance = () => {
	const theme = useTheme();
	const { t } = useTranslation('translations');

	const handleClick = () => {
		window.open('https://startcontrol.com/pin.php', '_blank');
	};

	return (
		<Stack direction="row" sx={{ '&:hover': { cursor: 'pointer' } }} onClick={handleClick}>
			<img src={airdrop} alt="Assistance" />
			<Space width={Dimens.XS_SPACING} />
			<Box>

				<Typography sx={{ fontSize: Dimens.TEXT_MEDIUM, fontWeight: Dimens.TEXT_WEIGHT_LIGHT }}>
					{t('remote_assistance')}
				</Typography>
				<Typography sx={{
					fontSize: Dimens.TEXT_DEFAULT,
					fontWeight: Dimens.TEXT_WEIGHT_LIGHT,
					color: theme.palette.textLight.main,
				}}
				>
					{t('computer_access_req')}
				</Typography>
			</Box>
		</Stack>
	);
};

export default RemoteAssistance;
