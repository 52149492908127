import React from 'react';
import { ContactProvider } from 'features/contact-us/presentation/provider/contact.provider';
import ContactPhcPos from './ContactPhcPos';
import ControlStoreSection from './ControlStoreSection';
import IntelligentSolutions from './IntelligentSolutions';
import PhcCsPosFeatures from './PhcCsPosFeatures';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import PhcposComments from './PhcposComments';
import PhcPosHero from './PhcPosHero';
import { PhcPosProvider } from './provider/phcpos.provider';

const PhcPos = () => (
	<main>
		<PhcPosProvider>
			<ContactProvider>
				<PhcPosHero />
				<PhcCsPosFeatures />
				<ControlStoreSection />
				<IntelligentSolutions />
				{/* <PhcposComments /> */}
				<ContactPhcPos />
			</ContactProvider>
		</PhcPosProvider>
	</main>
);

export default PhcPos;
