import { Either } from '@ajtec/either-typescript';
import { IPriceTecLab } from '../entities/pricetec-lab';
import { IPriceTecRepository } from '../repositories/pricetec-repository';

export class GetPriceTecLabs {
	repository: IPriceTecRepository;

	constructor(pricetecRepository: IPriceTecRepository) {
		this.repository = pricetecRepository;
	}

	call(): Promise<Either<string, IPriceTecLab[]>> {
		return this.repository.getPriceTecLabs();
	}
}
