import {
	Card, Stack, SvgIcon, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import React from 'react';
import BodyText from '../body-text/BodyText';
import SizedBox from '../sized-box/SizedBox';

interface Props {
	icon: React.ReactNode,
	title: string;
	description: string
}
const FeatureCard:React.FC<Props> = (props) => {
	const { icon, title, description } = props;
	const { palette } = useTheme();
	return (
		<Card
			elevation={4}
			sx={{
				pl: 2,
				pr: 2,
				// pt: 3,
				// pb: 3,
				borderTop: 4,
				mr: { md: Dimens.PADDING_MEDIUM, xs: Dimens.PADDING_MEDIUM_MOBILE },
				borderTopColor: palette.primary.main,
				minWidth: Dimens.FEATURE_CARD_WIDTH_MOBILE,
				width: { md: Dimens.FEATURE_CARD_WIDTH, xs: Dimens.FEATURE_CARD_WIDTH_MOBILE },
				height: { md: Dimens.FEATURE_CARD_HEIGHT, xs: Dimens.FEATURE_CARD_HEIGHT_MOBILE },
			}}
		>
			<Stack
				direction="column"
				alignItems="center"
				justifyContent="space-around"
				height={{ md: Dimens.FEATURE_CARD_HEIGHT, xs: Dimens.FEATURE_CARD_HEIGHT_MOBILE }}
			>
				<SvgIcon component="div" color="primary" sx={{ m: 1 }}>
					{icon}
				</SvgIcon>
				<SizedBox height={3} />
				<BodyText textAlign="center" sx={{ color: palette.textMain.main }}>
					{title}
				</BodyText>
				<SizedBox height={3} />
				<BodyText
					textAlign="center"
					sx={{ fontSize: Dimens.TEXT_DEFAULT }}
				>
					{description}
				</BodyText>
			</Stack>
		</Card>
	);
};

export default FeatureCard;
