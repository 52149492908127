import { Either, Left, Right } from '@ajtec/either-typescript';
import { ISupportFeature } from 'features/technical-support/domain/entities/support-feature';
import { ISupportReviews } from 'features/technical-support/domain/entities/support-reviews';
import { ITechnicalSupportRepository } from 'features/technical-support/domain/repositories/technical-support-repository';
import { TechnicalSupportApiImpl } from '../remote/technical-support-api-impl';

export class CTechnicalSupportRepositoryImpl implements ITechnicalSupportRepository {
	logisticApi = new TechnicalSupportApiImpl();

	async getSupportFeatures(): Promise<Either<string, ISupportFeature[]>> {
		try {
			const features = await this.logisticApi.getSupportFeatures();
			return new Right(features);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}

	async getSupportReviews(): Promise<Either<string, ISupportReviews[]>> {
		try {
			const reviews = await this.logisticApi.getSupportReviews();
			return new Right(reviews);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}
}
