/* eslint-disable class-methods-use-this */
import {
	Bag,
	Archivebox,
	Boxsearch,
	Clipboard,
	Gridlock,
	Moneyreceive,
	People,
	Smileys,
} from 'assets/images/icons';
import AppLinks from 'assets/applink.routes';
import { businessCore, fleet, teams } from 'assets/images/phc-products';
import { PhcProductModel } from '../dto/phc-product-model';
import { PhcSolutionModel } from '../dto/phc-solution-model';
import { PhcBusinessApi } from './phc-business-api';

export class PhcBusinessApiImpl implements PhcBusinessApi {
	getPhcProducts(): Promise<PhcProductModel[]> {
		try {
			const phcProducts = [{
				id: 1,
				title: 'Equipas e Projectos',
				description: 'Esta solução de gestão está equipada para que possa projetar todas as ações necessárias e concluí-las com todo o rigor.',
				image: teams,
				link: AppLinks.PHC_SOLUTIONS,
			},
			{
				id: 2,
				title: 'Business Core',
				description: 'Aumente a produtividade da sua empresa, enquanto reduz os custos. Conheça os módulos e características que compõe o core desta solução.',
				image: businessCore,
				link: AppLinks.PHC_BUSINESS_CORE,
			},
			{
				id: 3,
				title: 'Frota',
				description: 'Faça uma gestão inteligente da sua frota e tenha controle total sobre todos os custos.',
				image: fleet,
				link: AppLinks.PHC_SOLUTIONS_FLEET_MOBILITY,
			},
			];
			const partnerPromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(phcProducts);
				}, 300);
			});
			return partnerPromise.then(() => phcProducts);
		} catch (error) {
			throw new Error('An Error Occured');
		}
	}

	getPhcSolutions(): Promise<PhcSolutionModel[]> {
		try {
			const phcSolutions = [
				{
					id: 1,
					title: 'Solução POS',
					icon: Boxsearch,
					link: AppLinks.PHC_CS_POS,
				},
				{
					id: 2,
					title: 'Solução Financeira',
					icon: Moneyreceive,
					link: AppLinks.LOGISTIC_AND_DISTRIBUTION,
				},
				{
					id: 3,
					title: 'Solução CRM',
					icon: Smileys,
					link: AppLinks.PRICE_TEC,
				},
				{
					id: 4,
					title: 'Solução Business Core',
					icon: Archivebox,
					link: AppLinks.PHC_BUSINESS_CORE,
				},
				{
					id: 5,
					title: 'Solução Recursos Humanos',
					icon: People,
					link: AppLinks.PHC_CS_POS,
				},
				{
					id: 6,
					title: 'Solução Retalho',
					icon: Clipboard,
					link: AppLinks.PHC_SOLUTIONS,
				},
				{
					id: 7,
					title: 'Solução E-Commerce',
					icon: Bag,
					link: AppLinks.PHC_SOLUTIONS_FLEET_MOBILITY,
				},
				{
					id: 8,
					title: 'Solução RGPD',
					icon: Gridlock,
					link: AppLinks.FUEL_TEC,
				},
			];
			const solutionsPromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(phcSolutions);
				}, 300);
			});
			return solutionsPromise.then(() => phcSolutions);
		} catch (error) {
			throw new Error('An Error Occured');
		}
	}
}
