import { CTechnicalSupportRepositoryImpl } from 'features/technical-support/data/repositories/technical-support-repository-impl';
import { ISupportFeature } from 'features/technical-support/domain/entities/support-feature';
import { ISupportReviews } from 'features/technical-support/domain/entities/support-reviews';
import { GetSupportFeatures } from 'features/technical-support/domain/usecases/get-support-features';
import { GetSupportReviews } from 'features/technical-support/domain/usecases/get-support-reviews';
import React from 'react';

type TContextProps = {
	loading: boolean;
	error: string;
	technicalSupportFeatures: ISupportFeature[];
	getSupportFeatures: () => Promise<void>;

	reviewLoading: boolean;
	reviewsError: string;
	technicalSupportReviews: ISupportReviews[];
	getSupportReviews: () => Promise<void>
}

const TechnicalSupportContext = React.createContext({} as TContextProps);

export const TechnicalSupportProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState('');
	const [technicalSupportFeatures, setTechnicalFeature] = React.useState([] as ISupportFeature[]);

	const [reviewLoading, setReviewLoading] = React.useState(false);
	const [reviewsError, setReviewsError] = React.useState('');
	const [technicalSupportReviews, setSupportReviews] = React.useState([] as ISupportReviews[]);

	const technicalSupportRepository = new CTechnicalSupportRepositoryImpl();

	const getSupportFeatures = async () => {
		setLoading(true);
		const featureUseCase = new GetSupportFeatures(technicalSupportRepository);
		const featureResult = await featureUseCase.call();
		if (featureResult.isRight()) {
			setTechnicalFeature(featureResult.value);
		} else {
			setError(featureResult.error);
		}
		setLoading(false);
	};

	const getSupportReviews = async () => {
		setReviewLoading(true);
		const reviewUseCase = new GetSupportReviews(technicalSupportRepository);
		const reviewResult = await reviewUseCase.call();
		if (reviewResult.isRight()) {
			setSupportReviews(reviewResult.value);
		} else {
			setReviewsError(reviewResult.error);
		}
		setReviewLoading(false);
	};

	const value = React.useMemo(() => ({
		loading,
		error,
		technicalSupportFeatures,
		getSupportFeatures,
		reviewLoading,
		reviewsError,
		technicalSupportReviews,
		getSupportReviews,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [
		loading,
		error,
		technicalSupportFeatures,
		reviewLoading,
		reviewsError,
		technicalSupportReviews]);

	return (
		<TechnicalSupportContext.Provider
			value={value}
		>
			{children}
		</TechnicalSupportContext.Provider>
	);
};

export default function useTechnicalSupport() {
	return React.useContext(TechnicalSupportContext);
}
