import React from 'react';
import { Box, Stack } from '@mui/material';
import {
	curvedLine, curvedLineTwo, custom, nativeSoftware, research,
} from 'assets/images';
import { useTranslation } from 'react-i18next';
import { ProcessItem } from 'common/presentation/components';

const DevelopmentCycle = () => {
	const { t } = useTranslation('translations');

	return (
		<Stack direction={{ md: 'row', xs: 'column' }} justifyContent="center" alignItems={{ md: 'flex-start', xs: 'center' }}>
			<ProcessItem title={t('native_software_dev')} description={t('native_software_dev_info')} image={nativeSoftware} />
			<Box sx={{
				display: { xs: 'none', md: 'flex' }, marginRight: '-6%', marginLeft: '-6%', marginTop: '2%',
			}}
			>
				<img src={curvedLine} alt="Native software icon" width="100%" />
			</Box>
			<ProcessItem title={t('research_and_development')} description={t('research_and_development_info')} image={research} />
			<Box sx={{
				display: { xs: 'none', md: 'flex' }, marginRight: '-6%', marginLeft: '-6%', marginTop: '3.5%',
			}}
			>
				<img src={curvedLineTwo} alt="Native software icon" width="100%" />
			</Box>
			<ProcessItem title={t('personalized_follow_up')} description={t('personalized_follow_up_info')} image={custom} />
		</Stack>

	);
};

export default DevelopmentCycle;
