import { Either, Left, Right } from '@ajtec/either-typescript';
import { ICompanyFeature } from 'features/logistics/domain/entities/company-features';
import { ILogisticComments } from 'features/logistics/domain/entities/logistic-comments';
import { ILogisticsRepository } from 'features/logistics/domain/repositories/logistic-repository';
import { LogisticApiImpl } from '../remote/logistic-api-impl';

export class CLogisticRepositoryImpl implements ILogisticsRepository {
	logisticApi = new LogisticApiImpl();

	async getCompanyFeatures(): Promise<Either<string, ICompanyFeature[]>> {
		try {
			const companyFeatures = await this.logisticApi.getCompanyFeatures();
			return new Right(companyFeatures);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}

	async getLogisticComments(): Promise<Either<string, ILogisticComments[]>> {
		try {
			const logisticComment = await this.logisticApi.getLogisticComments();
			return new Right(logisticComment);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}
}
