import { KeyboardArrowRight } from '@mui/icons-material';
import {
	Stack, Typography, Box, useTheme, styled, Collapse,
	Link,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { robot, socialMedia } from 'assets/images';
import { ajtec } from 'assets/images/icons';
import {
	ActionButtons,
	BodyText,
	DefaultButton, Space, SubTitle, TitleLarge,
} from 'common/presentation/components';
import ContactDialog from 'common/presentation/components/contact-dialog/ContactDialog';
import SizedBox from 'common/presentation/components/sized-box/SizedBox';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';

const HeroSection = () => {
	const theme = useTheme();
	const { t } = useTranslation('translations');
	const [textArray] = useState([t('management'), t('web'), t('mobile'), t('infrastructure')]);
	const [activeIndex, setActiveIndex] = useState(0);
	const [reqDemo, setReqDemo] = useState(false);

	const SocialMediaText = styled(Typography)(() => ({
		fontSize: Dimens.TEXT_SMALL,
		color: theme.palette.textLight.main,
		fontWeight: Dimens.TEXT_WEIGHT_LIGHT,
		'&:hover': {
			cursor: 'pointer',
		},
	}));

	useEffectCustom(() => {
		const interval = setInterval(() => {
			setActiveIndex((prev) => {
				let mPrev = prev;
				if (mPrev !== textArray.length - 1) {
					mPrev += 1;
					return mPrev;
				}
				return 0;
			});
		}, 1500);
		return () => clearInterval(interval);
	}, []);

	const handleRequstDemoClick = () => {
		setReqDemo(true);
	};

	return (
		<section>
			<Box sx={{ pl: { md: Dimens.APP_BAR_PADDING, xs: 2 }, pr: { md: 0, xs: 2 } }}>
				<ActionButtons icon={ajtec} />
				<Stack direction="row" sx={{ mt: { xs: 0, md: theme.spacing(7) } }}>
					<Box sx={{ width: { md: '40%', xs: '100%' } }}>
						<TitleLarge>
							{t('welcome_message')}
						</TitleLarge>
						<SizedBox height={4} />
						<Stack direction="row">
							<SubTitle sx={{ color: theme.palette.success.main }}>
								{t('web_solutions')}
							</SubTitle>
							<TransitionGroup>
								<Collapse key={activeIndex}>
									<SubTitle sx={{ color: theme.palette.success.main, ml: 1 }}>
										{textArray[activeIndex]}
									</SubTitle>
								</Collapse>
							</TransitionGroup>
						</Stack>
						<SizedBox height={4} />
						<BodyText>
							{t('welcome_message_description')}
						</BodyText>
						<SizedBox height={4} />
						<DefaultButton onClick={handleRequstDemoClick} endIcon={<KeyboardArrowRight />}>{t('request_demo')}</DefaultButton>
						<SizedBox height={4} />
						<Stack direction="row" alignItems="center" display={{ md: 'flex', xs: 'none' }}>
							<img src={socialMedia} alt="social media icon" />
							<Space width={Dimens.XS_SPACING} />
							<Typography sx={{
								fontWeight: Dimens.TEXT_WEIGHT_BOLD,
								fontSize: Dimens.TEXT_DEFAULT,
								color: theme.palette.textMain.main,
							}}
							>
								{t('follow_us')}
							</Typography>
						</Stack>
						<Stack direction="row" sx={{ pl: 9 }} display={{ md: 'flex', xs: 'none' }}>
							<Link to="https://pt.linkedin.com/company/ajtec" target="_blank" component={RouterLink} color="inherit" underline="none">
								<SocialMediaText>Linked in</SocialMediaText>
							</Link>
							<Space width={Dimens.XS_SPACING} />
							<Link to="https://www.instagram.com/ajtec.pt/" target="_blank" component={RouterLink} color="inherit" underline="none">
								<SocialMediaText>Instagram</SocialMediaText>
							</Link>
							{/* <Space width={Dimens.XS_SPACING} />
							<SocialMediaText>Facebook</SocialMediaText> */}
						</Stack>
					</Box>
					<Box sx={{
						display: { md: 'flex', xs: 'none' },
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'flex-end',
						width: { md: '60%' },
						backgroundImage: `linear-gradient(to bottom right, ${theme.palette.background.default} 45%, rgba(103,226,194,0.5) 75%, rgba(180,255,153,0.2) 100%)`,

					}}
					>
						<img src={robot} alt="Robot" width="65%" height="95%" />
					</Box>
				</Stack>
			</Box>
			<ContactDialog open={reqDemo} handleClose={() => setReqDemo(false)} />
		</section>
	);
};

export default HeroSection;
