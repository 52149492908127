enum AppLinks {
	HOME = '/',
	ABOUT_US = '/about-us',
	RESEARCH_LAB = '/research',
	PHC_SOLUTIONS = '/phc-solutions',
	PHC_BUSINESS_CORE = '/phc-solutions/business-core',
	PHC_SOLUTIONS_FLEET_MOBILITY = '/phc-solutions/fleet-and-mobility',
	PHC_CS_POS = '/phc-solutions/phc-cs-pos',
	LOGISTIC_AND_DISTRIBUTION = '/phc-solutions/logistics-distribution',
	TECHNICAL_SUPPORT = '/phc-solutions/technical-support',
	FUEL_TEC = '/products/fuel-tec',
	PRICE_TEC = '/products/price-tec',
	PRODUCTS = '/products',
	CONTACT_US = '/contact-us'
}

export default AppLinks;
