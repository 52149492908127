import { Either } from '@ajtec/either-typescript';
import { AutomationFeature } from '../entities/automation-feature';
import { IFleetMobilityRepository } from '../repositories/fleet-mobility-repository';

export class GetAutomationFeatures {
	repository: IFleetMobilityRepository;

	constructor(homeRepository: IFleetMobilityRepository) {
		this.repository = homeRepository;
	}

	call(): Promise<Either<string, AutomationFeature[]>> {
		return this.repository.getAutomationFeatures();
	}
}
