import { Refresh } from '@mui/icons-material';
import { IconButton, useTheme } from '@mui/material';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import React, { MutableRefObject } from 'react';

interface Props {
	width: number;
	height: number;
	getCaptcha: (captcha: string) => void;
	redrawCaptcha: MutableRefObject<() => void>
}

const Captcha:React.FC<Props> = (props) => {
	const {
		width, height, getCaptcha, redrawCaptcha,
	} = props;
	const ref = React.createRef<HTMLCanvasElement>();
	const theme = useTheme();

	const drawCaptchaBackground = (ctx: CanvasRenderingContext2D) => {
		for (let x = 0; x < 15; x += 1) {
			const p1 = Math.random() * width;
			const p2 = Math.random() * height;
			ctx.beginPath();
			let hue = Math.random() * 360;
			ctx.strokeStyle = `hsl(${hue},100%,50%)`;
			ctx.moveTo(p1, p2);
			let s = 5 - Math.random() * 5;
			ctx.lineTo(p1 + s, p2 + s);
			ctx.stroke();
			ctx.closePath();
			ctx.beginPath();
			hue = Math.random() * 360;
			ctx.strokeStyle = `hsl(${hue},100%,50%)`;
			ctx.moveTo(p1 - 3, p2 + 3);
			s = 5 - Math.random() * 5;
			ctx.arc(p1, p2, 2, 0, 2 * Math.PI);
			ctx.stroke();
			ctx.closePath();
		}
	};

	const drawCaptchaFace = (ctx: CanvasRenderingContext2D) => {
		const xSpacing = 10; // Adjust the horizontal spacing between letters
		let x = 100;
		const y = 100;
		let str = '';
		for (let i = 0; i < 6; i += 1) {
			ctx.save();
			x = 8 + i * (30 + xSpacing); // Adjust x position with spacing
			const hue = Math.random() * 360;
			ctx.fillStyle = `hsl(${hue},50%,50%)`;
			let fontSize = 0;
			do {
				fontSize = Math.random() * 100;
			} while (fontSize < 20);
			ctx.font = `bolder ${fontSize}px Arial bold`;
			ctx.translate(x, y);
			const rot = Math.random() * 120;
			ctx.rotate((60 - rot) * (Math.PI / 180));
			ctx.translate(-x, -y);
			let char = 0;
			do {
				char = Math.random() * 122;
			} while (
				!(char >= 65 && char <= 90) && !(char >= 97 && char <= 122) && !(char >= 48 && char <= 57)
			);
			const ch = String.fromCharCode(char);
			str += ch;
			ctx.fillText(ch, x, y);
			ctx.restore();
		}
		getCaptcha(str);
	};

	const redraw = () => {
		if (!ref.current) return;
		const ctx = ref.current.getContext('2d');
		if (!ctx) return;
		ctx.clearRect(0, 0, width, height);
		ctx.fillStyle = 'white';
		ctx.fillRect(0, 0, width, height);
		drawCaptchaBackground(ctx);
		drawCaptchaFace(ctx);
	};

	useEffectCustom(() => {
		redraw();
	}, []);

	useEffectCustom(() => {
		redrawCaptcha.current = redraw;
	}, [redraw]);

	return (
		<div style={{
			height: height + 2,
			width: width + 2,
			borderRadius: '3px',
			border: `1px solid ${theme.palette.primary.main}`,
			position: 'relative',
		}}
		>
			<IconButton
				sx={{
					position: 'absolute',
					bottom: 0,
					right: 0,
				}}
				color="primary"
				onClick={redraw}
			>
				<Refresh />
			</IconButton>
			<canvas
				width={width}
				height={height}
				style={{
					borderRadius: '3px',
				}}
				ref={ref}
			/>
		</div>
	);
};

export default Captcha;
