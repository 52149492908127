/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Box,
	Card,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Slide,
	ListItemProps,
	ListItemButton,
	ListItemText,
	Collapse,
	List,
	Stack,
	useTheme,
} from '@mui/material';
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
	facebook, instagram, linkedin, logo,
} from 'assets/images';
import { Clear, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
	Link as RouterLink, useLocation,
} from 'react-router-dom';
import AppLinks from 'assets/applink.routes';
import { useTranslation } from 'react-i18next';
import Dimens from 'assets/dimens';
import ClientArea from '../client-area/ClientArea';
import Space from '../space/Space';
import SizedBox from '../sized-box/SizedBox';

const Transition = React.forwardRef((
	props: TransitionProps & {
    children: React.ReactElement;
  },
	ref: React.Ref<unknown>,
) => <Slide direction="down" ref={ref} {...props} />);

interface Props {
	open: boolean;
	handleClose: () => void
}

interface ListItemLinkProps extends ListItemProps {
  to: string;
  open?: boolean;
  title: string
}

const ListItemLink = (props: ListItemLinkProps) => {
	const location = useLocation();
	const [pathname, setPathName] = React.useState(location.pathname);
	const { palette } = useTheme();

	React.useEffect(() => {
		setPathName(location.pathname);
	}, [location]);

	const {
		to, open, title, ...other
	} = props;

	let icon = null;
	if (open != null) {
		icon = open ? <ExpandLess color="secondary" /> : <ExpandMore color="secondary" />;
	}

	return (
		<ListItemButton component={RouterLink as any} to={to} {...other}>
			<ListItemText
				sx={{ color: pathname === to ? palette.secondary.main : palette.textMain.main }}
				primary={title}
			/>
			{icon}
		</ListItemButton>
	);
};

const NavMenuMobile: React.FC<Props> = (props) => {
	const { open, handleClose } = props;
	const { t } = useTranslation('translations');

	const [openPhcItem, setOpenPhcItem] = React.useState(false);
	const [openProductItem, setOpenProductItem] = React.useState(false);

	const handleClick = () => {
		setOpenPhcItem((prevOpen) => !prevOpen);
	};

	const handleProductClick = () => {
		setOpenProductItem((prevOpen) => !prevOpen);
	};

	return (
		<Dialog fullScreen TransitionComponent={Transition} open={open} onClose={handleClose}>
			<DialogTitle boxShadow={2}>
				<Box
					sx={{
						display: 'flex',
						flex: 1,
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Box sx={{ mr: 1 }}>
						<img src={logo} alt="logo" width="80%" />
					</Box>
					<IconButton color="inherit" onClick={handleClose}>
						<Clear />
					</IconButton>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Card elevation={3} sx={{ p: 2, mt: 3 }}>
					<ClientArea />
				</Card>
				<Card elevation={3} sx={{ p: 2, mt: 3 }}>
					<ListItemLink onClick={handleClose} to={AppLinks.HOME} title={t('home_page')} />
					<ListItemLink onClick={handleClose} to={AppLinks.ABOUT_US} title={t('about_us')} />
					<ListItemLink onClick={handleClose} to={AppLinks.RESEARCH_LAB} title={t('r&d_lab')} />
					<ListItemLink to={AppLinks.PHC_SOLUTIONS} title={t('phc_solutions')} open={openPhcItem} onClick={handleClick} />
					<Collapse component="div" in={openPhcItem} timeout="auto" unmountOnExit>
						<List disablePadding>
							<ListItemLink sx={{ pl: 4 }} onClick={handleClose} to={AppLinks.PHC_SOLUTIONS} title={t('your_phc_software')} />
							<ListItemLink sx={{ pl: 4 }} onClick={handleClose} to={AppLinks.PHC_BUSINESS_CORE} title={t('business_core_solution')} />
							<ListItemLink sx={{ pl: 4 }} onClick={handleClose} to={AppLinks.PHC_CS_POS} title={t('pos_solution')} />
							<ListItemLink sx={{ pl: 4 }} onClick={handleClose} to={AppLinks.PHC_SOLUTIONS_FLEET_MOBILITY} title={t('fleet_and_mobility_solution')} />
							<ListItemLink sx={{ pl: 4 }} onClick={handleClose} to={AppLinks.LOGISTIC_AND_DISTRIBUTION} title={t('logistic_distribution_solution')} />
							<ListItemLink sx={{ pl: 4 }} onClick={handleClose} to={AppLinks.TECHNICAL_SUPPORT} title={t('technical_support_solution')} />
						</List>
					</Collapse>
					<ListItemLink to={AppLinks.FUEL_TEC} title={t('our_products')} open={openProductItem} onClick={handleProductClick} />
					<Collapse component="div" in={openProductItem} timeout="auto" unmountOnExit>
						<List disablePadding>
							<ListItemLink sx={{ pl: 4 }} onClick={handleClose} to={AppLinks.FUEL_TEC} title="FUELTEC" />
							<ListItemLink sx={{ pl: 4 }} onClick={handleClose} to={AppLinks.PRICE_TEC} title="PRICETEC" />
						</List>
					</Collapse>
					<ListItemLink onClick={handleClose} to={AppLinks.CONTACT_US} title={t('contact_us')} />
				</Card>
				<SizedBox height={2} />
				<Stack direction="row" justifyContent="center">
					<img src={linkedin} alt="Linkedin" />
					<Space width={Dimens.ICON_SPACING} />
					<img src={instagram} alt="Instagram" />
					<Space width={Dimens.ICON_SPACING} />
					<img src={facebook} alt="Facebook" />
				</Stack>
			</DialogContent>
		</Dialog>
	);
};

export default NavMenuMobile;
