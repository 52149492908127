import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import Dimens from 'assets/dimens';
import React from 'react';

interface Props extends LoadingButtonProps {
	children: string
}

const DefaultButton: React.FC<Props> = (props) => {
	const { children, sx } = props;
	return (
		<LoadingButton
			color="primary"
			variant="contained"
			sx={{
				pl: { md: '32px', xs: '18px' },
				pr: { md: '32px', xs: '18px' },
				pt: { md: '17.5px', xs: '15px' },
				pb: { md: '17.5px', xs: '15px' },
				borderRadius: 50 / 2,
				fontSize: { md: Dimens.TEXT_DEFAULT, xs: Dimens.TEXT_SMALL },
				fontWeight: Dimens.TEXT_WEIGHT_NORMAL,
				...sx,
			}}
			{...props}
		>
			{children}
		</LoadingButton>
	);
};

export default DefaultButton;
