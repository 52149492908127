import { Either } from '@ajtec/either-typescript';
import { IFuelTecPartner } from '../entities/fueltec-partner';
import { IFueltecRepository } from '../repositories/fueltec-repository';

export class GetFueltecPartners {
	repository: IFueltecRepository;

	constructor(homeRepository: IFueltecRepository) {
		this.repository = homeRepository;
	}

	call(): Promise<Either<string, IFuelTecPartner[]>> {
		return this.repository.getFueltecPartners();
	}
}
