import { Box, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import { Archivebook, Calender, Car } from 'assets/images';
import {
	BodyText, FeatureCard, SizedBox, TitleSmall,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FleetSolutions = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Box sx={{
				ml: { md: Dimens.PAGE_PADDING_LARGE, xs: 2 },
				mr: { md: Dimens.PAGE_PADDING_LARGE, xs: 2 },
				mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
			}}
			>
				<TitleSmall textAlign="center">
					{t('fleet_solution_for_business')}
				</TitleSmall>
				<SizedBox height={4} showLine align="center" />
				<BodyText textAlign="center">{t('fleet_solution_for_business_info')}</BodyText>
			</Box>
			<Stack
				direction="row"
				sx={{
					justifyContent: 'center',
					pt: Dimens.SECTION_SPACING_MOBILE,
					overflowX: 'scroll',
					'&::-webkit-scrollbar': { display: 'none' },
					scrollbarWidth: 'none',
					msOverflowStyle: 'none',
					pl: 2,
					pb: Dimens.PADDING_MEDIUM,
					mt: { md: Dimens.SECTION_SPACING_MOBILE, xs: Dimens.ICON_SPACING },
				}}
			>
				<FeatureCard icon={<Archivebook />} title={t('efficiency_managment')} description={t('efficiency_managment_info')} />
				<FeatureCard icon={<Calender />} title={t('schedule_vehicle_deadlines')} description={t('schedule_vehicle_deadlines_info')} />
				<FeatureCard icon={<Car />} title={t('global_fleet_view')} description={t('global_fleet_view_info')} />
			</Stack>
		</section>
	);
};

export default FleetSolutions;
