import React from 'react';
import { Box, CssBaseline, useTheme } from '@mui/material';
import {
	AppFooter, CookieSwitch, ScrollToTop, Topbar,
} from 'common/presentation/components';
import LanguageSelector from 'common/presentation/components/language-selector/LanguageSelector';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from 'router';
import ErrorDialog from 'common/presentation/components/error-dialog/ErrorDialog';

const Main = () => {
	const theme = useTheme();
	return (
		<BrowserRouter>
			<CssBaseline />
			<ScrollToTop />
			<ErrorDialog />
			<Box
				sx={{
					display: { xs: 'none', md: 'block' },
					position: 'fixed',
					bottom: '5%',
					left: theme.spacing(2),
					zIndex: 1000,
				}}
			>
				<CookieSwitch />
			</Box>
			<Box
				boxShadow={2}
				sx={{
					display: { xs: 'flex', md: 'none' },
					position: 'fixed',
					bottom: '5%',
					left: theme.spacing(2),
					borderRadius: 31 / 2,
					zIndex: 1000,
				}}
			>
				<LanguageSelector />
			</Box>
			<Topbar />
			<Box sx={{ pt: `${parseInt(String(theme.mixins.toolbar.minHeight), 10) + 41}px` }}>
				<AppRouter />
			</Box>
			<AppFooter />
		</BrowserRouter>
	);
};

export default Main;
