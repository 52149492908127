import { Stack } from '@mui/material';
import React from 'react';
import BodyText from '../body-text/BodyText';
import TitleSmall from '../title-small/TitleSmall';

interface Props {
	title: string;
	description: string;
	image: string
}

const ProcessItem: React.FC<Props> = (props) => {
	const { title, description, image } = props;
	return (
		<Stack alignItems="center" sx={{ width: '300px' }}>
			<img src={image} alt={`${title} icon`} height="131px" width="89px" />
			<TitleSmall textAlign="center" sx={{ mt: 2 }}>
				{title}
			</TitleSmall>
			<BodyText textAlign="center" sx={{ mt: 2 }}>
				{description}
			</BodyText>
		</Stack>
	);
};

export default ProcessItem;
