import React from 'react';
import { ContactProvider } from 'features/contact-us/presentation/provider/contact.provider';
import CompanyMoto from './CompanyMoto';
import Faqs from './Faqs';
import HeroSection from './HeroSection';
import OurPartners from './OurPartners';
import OurServices from './OurServices';
import OurSolutions from './OurSolutions';
import { HomeProvider } from './provider/home.provider';
import TalkToUs from './TalkToUs';

const HomeScreen = () => (
	<main>
		<HomeProvider>
			<ContactProvider>
				<HeroSection />
				<OurSolutions />
				<CompanyMoto />
				<OurServices />
				<OurPartners />
				<Faqs />
				<TalkToUs />
			</ContactProvider>
		</HomeProvider>
	</main>
);

export default HomeScreen;
