import AppLinks from 'assets/applink.routes';

export const getProductDetailUrl = (productId: number) => {
	switch (productId) {
	case 1:
		return AppLinks.PHC_SOLUTIONS;
	case 2:
		return AppLinks.PRICE_TEC;
	case 3:
		return AppLinks.FUEL_TEC;
	default:
		return AppLinks.ABOUT_US;
	}
};
