import { Box, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import {
	ActionButtons, BodyText, ImageScrollView, SizedBox, TitleLarge,
} from 'common/presentation/components';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInViewRef } from 'rooks';
import usePriceTec from './provider/pricetec-provider';

const PriceTecHero = () => {
	const { t } = useTranslation('translations');
	const [labRef, inView] = useInViewRef();
	const { pricetecLabs, getPriceTecLabs, loading } = usePriceTec();

	useEffectCustom(() => {
		if (inView && pricetecLabs.length === 0) {
			getPriceTecLabs();
		}
	}, [inView]);
	return (
		<section ref={labRef}>
			<Box sx={{ pl: { md: Dimens.APP_BAR_PADDING, xs: 2 } }}>
				<ActionButtons showLine text={`${t('our_products')}/PRICETEC`} />
			</Box>
			<Stack sx={{ mt: { md: 5, xs: 2 }, position: 'relative' }}>
				<Box sx={{
					pl: { md: Dimens.PAGE_PADDING_LARGE, xs: 2 },
					pr: { md: Dimens.PAGE_PADDING_LARGE, xs: 2 },
				}}
				>
					<Box sx={{
						position: 'absolute',
						top: 40,
						right: 10,
						height: '100%',
						width: '40%',
						backgroundImage: 'linear-gradient(180deg, rgb(103, 226, 194, 0.7) 0%,rgba(180, 255, 153, 0.5) 100%)',
						borderRadius: '50%',
						filter: 'blur(87px)',
						zIndex: -1,
					}}
					/>
					<SizedBox height={7} />
					<TitleLarge>
						{t('digital_pricing_for_filling_stations')}
					</TitleLarge>
				</Box>
				<SizedBox height={7} />
				<ImageScrollView loading={loading} labs={pricetecLabs} />
			</Stack>
			<Box sx={{
				pt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				pr: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
				pl: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
			}}
			>
				<BodyText textAlign="center" darktext>
					{t('price_tec_info_para_one')}
				</BodyText>
				<SizedBox height={3} />
				<BodyText textAlign="center" darktext>
					{t('price_tec_info_para_two')}
				</BodyText>
			</Box>
		</section>
	);
};

export default PriceTecHero;
