import useFeedback from 'common/presentation/providers/feedback.provider';
import { CContactRepositoryImpl } from 'features/contact-us/data/repositories/contact-repository-impl';
import { IContactReq, IContactStateValues } from 'features/contact-us/domain/entities/contact-req';
import { SubmitContact } from 'features/contact-us/domain/usecases/submit-contact';
import React, {
	createContext,
	useState,
	useMemo,
	useContext,
	useRef,
	MutableRefObject,
} from 'react';
import { useTranslation } from 'react-i18next';
import { validateEmail } from 'utils/validation';

type TContextProps = {
	loading: boolean,
	formInputs: IContactStateValues,
	setFormInputs: (params: IContactStateValues) => void
	formErrors: IContactStateValues,
	setFormErrors: (params: IContactStateValues) => void
	captcha: string
	setCaptcha: (params: string) => void
	redrawCaptcha: MutableRefObject<() => void>
	submitContactRequest: (data: IContactReq) => void
};

const ContactContext = createContext({} as TContextProps);

export const ContactProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { addDialog } = useFeedback();
	const { t } = useTranslation('translations');
	const [loading, setLoading] = useState(false);
	const [captcha, setCaptcha] = React.useState('');
	const [formInputs, setFormInputs] = React.useState({
		name: '',
		surname: '',
		companySize: '0-5',
		company: '',
		contact: '',
		email: '',
		message: '',
		captcha: '',
	});
	const [formErrors, setFormErrors] = React.useState({
		name: '',
		surname: '',
		companySize: '',
		company: '',
		contact: '',
		email: '',
		message: '',
		captcha: '',
	});
	const redrawCaptcha = useRef() as MutableRefObject<() => void>;

	const contactRepository = new CContactRepositoryImpl();

	const submitContactRequest = async (data: IContactReq) => {
		const errors = {
			interest: '',
			name: '',
			surname: '',
			companySize: '',
			company: '',
			contact: '',
			email: '',
			message: '',
			captcha: '',
		};
		if (captcha === '' || formInputs.captcha !== captcha) {
			errors.captcha = t('captcha_required');
			redrawCaptcha.current();
		}
		if (!formInputs.name) {
			errors.name = t('please_enter_name');
		}
		if (!formInputs.surname) {
			errors.surname = t('please_enter_surname');
		}
		if (!formInputs.company) {
			errors.company = t('please_select_company_size');
		}
		if (!formInputs.companySize) {
			errors.companySize = t('please_select_company_size');
		}
		if (!formInputs.contact) {
			errors.contact = t('please_enter_contact');
		}
		if (!formInputs.email || !validateEmail(formInputs.email)) {
			errors.email = t('invalid_email_format');
		}
		if (!formInputs.message) {
			errors.message = t('please_enter_message');
		}
		setFormErrors(errors);
		if (errors.name
			|| errors.surname
			|| errors.companySize
			|| errors.contact
			|| errors.email
			|| errors.message
			|| errors.captcha) {
			return;
		}
		setLoading(true);
		const contactUseCase = new SubmitContact(contactRepository);
		const contactResult = await contactUseCase.call(data);
		if (contactResult.isRight()) {
			addDialog({
				title: t('success'),
				message: contactResult.value,
				error: false,
			});
			setFormInputs({
				name: '',
				surname: '',
				companySize: '0-5',
				company: '',
				contact: '',
				email: '',
				message: '',
				captcha: '',
			});
		} else {
			addDialog({
				title: t('error'),
				message: contactResult.error,
				error: false,
			});
		}
		setLoading(false);
	};

	const value = useMemo(() => ({
		loading,
		submitContactRequest,
		formInputs,
		setFormInputs,
		formErrors,
		setFormErrors,
		captcha,
		setCaptcha,
		redrawCaptcha,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [
		loading,
		formInputs,
		setFormInputs,
		formErrors,
		setFormErrors,
		captcha,
		setCaptcha,
		redrawCaptcha,
	]);

	return <ContactContext.Provider value={value}>{children}</ContactContext.Provider>;
};

export function useContact() {
	return useContext(ContactContext);
}
