/* eslint-disable class-methods-use-this */
import { Either, Left, Right } from '@ajtec/either-typescript';
import { Partner } from 'features/home/domain/entities/partner';
import { Product } from 'features/home/domain/entities/product';
import { Question } from 'features/home/domain/entities/question';
import { IHomeRepository } from 'features/home/domain/repositories/home-repository';
import { HomeApiImpl } from '../remote/home-api-impl';

export class CHomeRepositoryImpl implements IHomeRepository {
	homeApi = new HomeApiImpl();

	async getProducts(): Promise<Either<string, Product[]>> {
		try {
			const products = await this.homeApi.getProducts();
			return new Right(products);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}

	async getPartners(): Promise<Either<string, Partner[]>> {
		try {
			const partners = await this.homeApi.getPartners();
			return new Right(partners);
		} catch (error) {
			return new Left('An Error Occured');
		}
	}

	async getQuestions(lang: string): Promise<Either<string, Question[]>> {
		try {
			const questions = await this.homeApi.getQuestions(lang);
			return new Right(questions);
		} catch (error) {
			return new Left('An Error Occured');
		}
	}
}
