import { CHomeRepositoryImpl } from 'features/home/data/repositories/home-repository-impl';
import { Partner } from 'features/home/domain/entities/partner';
import { Product } from 'features/home/domain/entities/product';
import { Question } from 'features/home/domain/entities/question';
import { GetPartners } from 'features/home/domain/usecases/get-partners';
import { GetProducts } from 'features/home/domain/usecases/get-products';
import { GetQuestions } from 'features/home/domain/usecases/get-questions';
import React, {
	createContext, useContext, useMemo, useState,
} from 'react';

type TContextProps = {
	loading: boolean;
	error: string;
	products: Product[];
	getProducts: () => Promise<void>;
	partnerLoading: boolean;
	partnerError: string;
	partners: Partner[];
	getPartners: () => Promise<void>;
	questionLoading: boolean;
	questionError: string;
	questions: Question[];
	getQuestions: (lang: string) => Promise<void>
}

const HomeContext = createContext({} as TContextProps);

export const HomeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [products, setProducts] = useState([] as Product[]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');

	const [partners, setPartners] = useState([] as Partner[]);
	const [partnerLoading, setPartnerLoading] = useState(false);
	const [partnerError, setPartnerError] = useState('');

	const [questions, setQuestions] = useState([] as Question[]);
	const [questionLoading, setQuestionLoading] = useState(false);
	const [questionError, setQuestionError] = useState('');

	const homeRepository = new CHomeRepositoryImpl();

	const getProducts = async () => {
		setLoading(true);
		const productUseCase = new GetProducts(homeRepository);
		const productResult = await productUseCase.call();
		if (productResult.isRight()) {
			setProducts(productResult.value);
		} else {
			setError(productResult.error);
		}
		setLoading(false);
	};

	const getPartners = async () => {
		setPartnerLoading(true);
		const partnerUseCase = new GetPartners(homeRepository);
		const partnerResult = await partnerUseCase.call();
		if (partnerResult.isRight()) {
			setPartners(partnerResult.value);
		} else {
			setPartnerError(partnerResult.error);
		}
		setPartnerLoading(false);
	};

	const getQuestions = async (lang: string) => {
		setQuestionLoading(true);
		const questionUseCase = new GetQuestions(homeRepository);
		const questionsResult = await questionUseCase.call(lang);
		if (questionsResult.isRight()) {
			setQuestions(questionsResult.value);
		} else {
			setQuestionError(questionsResult.error);
		}
		setQuestionLoading(false);
	};

	const value = useMemo(() => ({
		products,
		loading,
		error,
		getProducts,
		partnerLoading,
		partners,
		partnerError,
		getPartners,
		questionLoading,
		questionError,
		questions,
		getQuestions,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [
		loading,
		error,
		products,
		partnerError,
		partnerLoading,
		partners,
		questions,
		questionError,
		questionLoading,
	]);

	return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>;
};

export default function useHome() {
	return useContext(HomeContext);
}
