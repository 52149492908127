import { CLogisticRepositoryImpl } from 'features/logistics/data/repositories/logistic-repository-impl';
import { ICompanyFeature } from 'features/logistics/domain/entities/company-features';
import { ILogisticComments } from 'features/logistics/domain/entities/logistic-comments';
import { GetCompanyFeatures } from 'features/logistics/domain/usecases/get-company-features';
import { GetLogisticComments } from 'features/logistics/domain/usecases/get-logistic-comment';
import React from 'react';

type TContextProps = {
	loading: boolean;
	error: string;
	companyFeatures: ICompanyFeature[];
	getCompanyFeatures: () => Promise<void>;

	commentsLoading: boolean;
	commentsError: string;
	comments: ILogisticComments[];
	getComments: () => Promise<void>
}

const LogisticContext = React.createContext({} as TContextProps);

export const LogisticProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState('');
	const [companyFeatures, setCompanyFeature] = React.useState([] as ICompanyFeature[]);

	const [commentsLoading, setCommentsLoading] = React.useState(false);
	const [commentsError, setCommentsError] = React.useState('');
	const [comments, setComments] = React.useState([] as ILogisticComments[]);

	const logisticRepository = new CLogisticRepositoryImpl();

	const getCompanyFeatures = async () => {
		setLoading(true);
		const featureUseCase = new GetCompanyFeatures(logisticRepository);
		const featureResult = await featureUseCase.call();
		if (featureResult.isRight()) {
			setCompanyFeature(featureResult.value);
		} else {
			setError(featureResult.error);
		}
		setLoading(false);
	};

	const getComments = async () => {
		setCommentsLoading(true);
		const commentsUseCase = new GetLogisticComments(logisticRepository);
		const commentsResult = await commentsUseCase.call();
		if (commentsResult.isRight()) {
			setComments(commentsResult.value);
		} else {
			setCommentsError(commentsResult.error);
		}
		setCommentsLoading(false);
	};

	const value = React.useMemo(() => ({
		loading,
		error,
		companyFeatures,
		getCompanyFeatures,
		commentsLoading,
		commentsError,
		comments,
		getComments,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [
		loading,
		error,
		companyFeatures,
		commentsLoading,
		commentsError,
		comments]);

	return <LogisticContext.Provider value={value}>{children}</LogisticContext.Provider>;
};

export default function useLogistic() {
	return React.useContext(LogisticContext);
}
