import { Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import { BodyText, SizedBox, TitleSmall } from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const InnovationSection = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Stack
				alignItems="center"
				sx={{
					pl: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
					pr: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
					pt: {
						md: Dimens.SECTION_SPACING,
						xs: Dimens.SECTION_SPACING_MOBILE,
					},
					pb: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				}}
			>
				<TitleSmall>{`${t('innovation_and_quatlity')} AJTEC`}</TitleSmall>
				<SizedBox height={8} showLine />
				<BodyText textAlign="center" darktext>{t('innovation_and_quatlity_info_para_one')}</BodyText>
				<SizedBox height={3} />
				<BodyText textAlign="center" darktext>{t('innovation_and_quatlity_info_para_two')}</BodyText>
			</Stack>
		</section>
	);
};

export default InnovationSection;
