import { ArrowRightAlt, Clear, LiveHelpOutlined } from '@mui/icons-material';
import {
	Box,
	Dialog, DialogContent, DialogTitle, Stack, Switch, Theme, styled, useMediaQuery,
} from '@mui/material';
import React from 'react';
import { useCookies } from 'common/presentation/providers/cookies.provider';
import Dimens from 'assets/dimens';
import { useTranslation } from 'react-i18next';
import { gingerbread } from 'assets/images';
import DefaultButton from '../default-button/DefaultButton';
import BodyText from '../body-text/BodyText';
import Space from '../space/Space';

interface CookiesDialogProps {
	show: boolean;
	setShow: () => void
}

type SwitchProps = {
	width: number
	thumbwidth: number
	label: string
}

const MaterialUISwitch = styled(Switch)<SwitchProps>(({
	theme, width, thumbwidth, label,
}) => ({
	width,
	height: 55,
	padding: 1,
	'& .MuiSwitch-switchBase': {
		margin: 1,
		padding: 2,
		transform: 'translateX(0px)',
		'&.Mui-checked': {
			color: theme.palette.secondary.main,
			transform: `translateX(${thumbwidth + 5}px)`,
			'& .MuiSwitch-thumb:before': { backgroundColor: theme.palette.success.main, borderRadius: 59 / 2 },
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor: theme.palette.textMain.main,
			},
		},
	},
	'& .MuiSwitch-thumb': {
		width: thumbwidth,
		height: 48,
		borderRadius: 24,
		'&:before': {
			content: label,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			position: 'absolute',
			width: '100%',
			height: '100%',
			left: 0,
			top: 0,
			fontSize: '11px',
			color: theme.palette.background.paper,
			backgroundColor: theme.palette.error.main,
			borderRadius: 24,
		},
	},
	'& .MuiSwitch-track': {
		opacity: 1,
		backgroundColor: theme.palette.textMain.main,
		borderRadius: 59 / 2,
	},
}));

const CookiesDialog: React.FC<CookiesDialogProps> = (props) => {
	const { show, setShow } = props;
	const { t } = useTranslation('translations');
	const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
	const { handleChangeCookie } = useCookies();

	const acceptCookie = () => {
		handleChangeCookie();
	};

	return (
		<Dialog fullWidth maxWidth="md" open={show}>
			<DialogTitle align="right">
				<DefaultButton onClick={setShow} variant="text" color="inherit" sx={{ m: 0, p: 0 }} endIcon={<Clear />}>
					{t('close')}
				</DefaultButton>
			</DialogTitle>
			<DialogContent>
				<Stack alignItems="center" sx={{ pl: { md: 10, xs: 2 }, pr: { md: 10, xs: 2 }, pb: { md: 10, xs: 2 } }}>
					{isMd && <img src={gingerbread} alt="Cookies Illustration" />}
					<BodyText textAlign="center">
						{t('cookies_info')}
					</BodyText>
				</Stack>
				<DefaultButton sx={{ p: 0, m: 0 }} variant="text" endIcon={<ArrowRightAlt />}>{t('cookies_policy').toUpperCase()}</DefaultButton>
				<Stack direction={{ md: 'row', xs: 'column' }} justifyContent="space-between">
					<Box>
						<BodyText>{t('service_managment')}</BodyText>
						<DefaultButton sx={{ p: 0, m: 0, textTransform: 'none' }} variant="text" startIcon={<LiveHelpOutlined />}>Medição de audiência</DefaultButton>
					</Box>
					<MaterialUISwitch width={isMd ? 432 : 245} thumbwidth={isMd ? 210 : 116} onChange={acceptCookie} sx={{ textAlign: 'center' }} label='"ACEITAR TODOS OS COOKIES"' />
				</Stack>
				<Stack direction={{ md: 'row', xs: 'column' }} justifyContent="space-between" sx={{ mt: 4 }}>
					<Box>
						<BodyText darktext>
							Google Analytics (Universal)
						</BodyText>
						<BodyText>
							{t('no_cookies_installed')}
						</BodyText>
						<Stack direction="row">
							<DefaultButton sx={{ p: 0, m: 0, textTransform: 'none' }} variant="text">{t('more_information')}</DefaultButton>
							<Space width={Dimens.ICON_SPACING} />
							<DefaultButton sx={{ p: 0, m: 0, textTransform: 'none' }} variant="text">{t('see_official_website')}</DefaultButton>
						</Stack>
					</Box>
					<MaterialUISwitch width={245} thumbwidth={116} label='"AUTORIZAR"' />
				</Stack>
			</DialogContent>
		</Dialog>
	);
};

export default CookiesDialog;
