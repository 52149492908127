import { Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import {
	Archivetick, ChartCircle,
	AddItem,
} from 'assets/images';
import { FeatureCard } from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PhcCsPosFeatures = () => {
	const { t } = useTranslation('translations');
	return (
		<Stack
			direction="row"
			sx={{
				mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				justifyContent: 'center',
				pt: Dimens.SECTION_SPACING_MOBILE,
				overflowX: 'scroll',
				'&::-webkit-scrollbar': { display: 'none' },
				scrollbarWidth: 'none',
				msOverflowStyle: 'none',
				pl: 2,
				pb: 2,
			}}
		>
			<FeatureCard icon={<AddItem />} title={t('diverse_payment_methods')} description={t('diverse_payment_methods_info')} />
			<FeatureCard icon={<ChartCircle />} title={t('easy_documents_issuance')} description={t('easy_documents_issuance_info')} />
			<FeatureCard icon={<Archivetick />} title={t('define_promotions_for_customers')} description={t('define_promotions_for_customers_info')} />
			<FeatureCard icon={<Archivetick />} title={t('navigation_simultaneous_screens')} description={t('navigation_simultaneous_screens_info')} />
		</Stack>
	);
};

export default PhcCsPosFeatures;
