/* eslint-disable @typescript-eslint/no-explicit-any */
import { CheckCircle, Error } from '@mui/icons-material';
import {
	Dialog as DialogMui,
	Slide,
	DialogTitle,
	useTheme,
	DialogContent,
	DialogContentText,
	DialogActions,

} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import DefaultButton from '../default-button/DefaultButton';

const ICON_STRING = {
	success: 'success',
	error: 'error',
};

interface DialogProps {
	icon?: 'success' | 'error';
	open: boolean;
	handleClose: () => void;
	title?: string | React.ReactNode;
	description?: string | React.ReactNode;
	primaryButtonText?: string;
	secondaryButtonText?: string;
	primaryButtonAction?: () => void;
	secondaryButtonAction?: () => void
}

const Transition = React.forwardRef((
	props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
	ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

const Dialog: React.FC<DialogProps> = (props) => {
	const {
		open,
		handleClose,
		icon,
		title,
		description,
		primaryButtonText,
		primaryButtonAction,
		secondaryButtonAction,
		secondaryButtonText,
	} = props;
	const theme = useTheme();
	return (
		<DialogMui
			sx={{ paddingLeft: theme.spacing(5), paddingRight: theme.spacing(5), zIndex: 100000 }}
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			aria-describedby="alert-dialog-slide-description"
		>
			{icon && (icon === ICON_STRING.success
				? (
					<DialogTitle sx={{ textAlign: 'center' }}>
						<CheckCircle color="success" fontSize="large" />
					</DialogTitle>
				)
				: (
					<DialogTitle sx={{ textAlign: 'center' }}>
						<Error color="error" fontSize="large" />
					</DialogTitle>
				))}
			<DialogTitle
				sx={{
					textAlign: 'center',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					color: theme.palette.textMain.main,
				}}
			>
				{title}
			</DialogTitle>
			<DialogContent sx={{ textAlign: 'center' }}>
				{typeof description === 'string' ? (
					<DialogContentText sx={{ color: theme.palette.textMain.main }} id="alert-dialog-description">
						{`${description}`}
					</DialogContentText>
				) : description}

			</DialogContent>
			<DialogActions>
				<DefaultButton
					onClick={() => primaryButtonAction?.call(0) || handleClose()}
				>
					{primaryButtonText || 'OK'}
				</DefaultButton>
				{secondaryButtonText && (
					<DefaultButton
						color="secondary"
						onClick={() => secondaryButtonAction?.call(0)}
					>
						{secondaryButtonText}
					</DefaultButton>
				)}
			</DialogActions>
		</DialogMui>
	);
};

export default Dialog;
