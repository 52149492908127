import { Either } from '@ajtec/either-typescript';
import { ISupportFeature } from '../entities/support-feature';
import { ITechnicalSupportRepository } from '../repositories/technical-support-repository';

export class GetSupportFeatures {
	repository: ITechnicalSupportRepository;

	constructor(technicalSupportRepository: ITechnicalSupportRepository) {
		this.repository = technicalSupportRepository;
	}

	call(): Promise<Either<string, ISupportFeature[]>> {
		return this.repository.getSupportFeatures();
	}
}
