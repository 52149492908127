import { Either, Left, Right } from '@ajtec/either-typescript';
import { ILabs } from 'features/about-us/domain/entities/lab-images';
import { IAboutRepository } from 'features/about-us/domain/repositories/about-repository';
import { CAboutApiImpl } from '../remote/about-api-impl';

export class CAboutRepositoryImpl implements IAboutRepository {
	homeApi = new CAboutApiImpl();

	async getLabs(): Promise<Either<string, ILabs[]>> {
		try {
			const products = await this.homeApi.getLabs();
			return new Right(products);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}
}
