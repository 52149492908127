import { Box, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import { laptopLogistics } from 'assets/images';
import {
	ActionButtons, BodyText, SizedBox, TitleMedium,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LogisticsHero = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Stack
				sx={{
					pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
				}}
			>
				<ActionButtons text={`${t('phc_solutions')} / ${t('logistics_and_distribution')}`} showLine />
				<Stack
					direction={{ md: 'row', xs: 'column' }}
					sx={{
						mt: { md: Dimens.SECTION_SPACING_MOBILE, xs: Dimens.MD_SPACING },
						pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					}}
				>
					<Box sx={{ width: { md: '55%', xs: '100%' } }}>
						<TitleMedium>
							{t('maximize_logistic_processes')}
						</TitleMedium>
						<SizedBox height={5} />
						<BodyText>
							{t('maximize_logistic_processes_info')}
						</BodyText>
						<SizedBox height={5} />
						{/* <DefaultButton variant="text" endIcon={<ArrowRightAlt />}
						sx={{ m: 0, p: 0 }}>{t('know_our_success_cases')}</DefaultButton> */}
					</Box>
					<Box sx={{ display: { md: 'flex', xs: 'none' }, width: '45%', mt: 9 }}>
						<img src={laptopLogistics} width="85%" alt="Distribution and Logistics" />
					</Box>
				</Stack>
			</Stack>
		</section>
	);
};

export default LogisticsHero;
