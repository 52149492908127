import { Either } from '@ajtec/either-typescript';
import { IFueltecReview } from '../entities/fueltec-reviews';
import { IFueltecRepository } from '../repositories/fueltec-repository';

export class GetFueltecReviews {
	repository: IFueltecRepository;

	constructor(fueltecRepository: IFueltecRepository) {
		this.repository = fueltecRepository;
	}

	call(): Promise<Either<string, IFueltecReview[]>> {
		return this.repository.getFuelTecReviews();
	}
}
