import { Grid, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import { FeatureListItem, SizedBox, TitleSmall } from 'common/presentation/components';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInViewRef } from 'rooks';
import useLogistic from './provider/logistic.provider';

const CompanyGains = () => {
	const { getCompanyFeatures, companyFeatures, loading } = useLogistic();
	const { t } = useTranslation('translations');

	const [featureRef, inView] = useInViewRef();

	useEffectCustom(() => {
		if (inView && companyFeatures.length === 0) {
			getCompanyFeatures();
		}
	}, [inView]);

	return (
		<section id="Company Gains" ref={featureRef}>
			<Stack sx={{
				pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
				pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
				pt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
			}}
			>
				<TitleSmall sx={{ width: { md: '567px' } }}>
					{t('company_gain')}
				</TitleSmall>
				<SizedBox height={6} showLine />
				<Grid container spacing={4}>
					{!loading && companyFeatures.map((el) => (
						<Grid item xl={4} md={6} xs={12} key={el.id}>
							<FeatureListItem key={el.id} title={el.title} />
						</Grid>
					))}
				</Grid>
			</Stack>
		</section>
	);
};

export default CompanyGains;
