import { ChevronRight } from '@mui/icons-material';
import { Stack } from '@mui/material';
import AppLinks from 'assets/applink.routes';
import Dimens from 'assets/dimens';
import {
	BodyText, DefaultButton, Label, SizedBox, TitleSmall,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const WhatWeDo = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	return (
		<section>
			<Stack
				alignItems="center"
				sx={{
					pl: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
					pr: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
					pt: {
						md: Dimens.SECTION_SPACING,
						xs: Dimens.SECTION_SPACING_MOBILE,
					},
					pb: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				}}
			>
				<Label title={t('innovation_and_quatlity')} />
				<SizedBox height={3} />
				<TitleSmall>
					{t('what_we_do')}
				</TitleSmall>
				<SizedBox height={6} showLine />
				<BodyText textAlign="center">
					{t('web_erp_team_info')}
				</BodyText>
				<br />
				<BodyText textAlign="center">
					{t('multiplatform_software_info')}
				</BodyText>
				<br />
				<BodyText textAlign="center">
					{t('research_lab_info')}
				</BodyText>
				<SizedBox height={9} />
				<DefaultButton variant="text" onClick={() => navigate(AppLinks.RESEARCH_LAB)} endIcon={<ChevronRight />}>{t('know_research_lab')}</DefaultButton>
			</Stack>
		</section>
	);
};

export default WhatWeDo;
