import { Box, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import {
	BodyText, CircularProgress, SizedBox, TitleMedium,
} from 'common/presentation/components';
import ContactUsForm from 'features/contact-us/presentation/components/ContactUsForm';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ContactPhcPos = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Box sx={{
				mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				pl: { xl: Dimens.APP_BAR_PADDING, xs: 2 },
				pr: { xl: Dimens.APP_BAR_PADDING, xs: 2 },
				mb: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
			}}
			>
				<TitleMedium>
					{t('get_in_touch_with_us_pos')}
				</TitleMedium>
				<SizedBox height={8} showLine />
				<Stack
					direction={{ md: 'row', xs: 'column' }}
					justifyContent="space-between"
					spacing={3}
				>
					<Box sx={{ width: { md: '40%', xs: '100%' }, position: 'relative' }}>
						<Box sx={{
							position: 'absolute',
							top: 0,
							left: 10,
							height: { xl: '500px', xs: '300px' },
							width: { xl: '500px', xs: '300px' },
							backgroundImage: 'linear-gradient(180deg, rgb(103, 226, 194, 0.5) 0%,rgba(180, 255, 153, 0.3) 100%)',
							borderRadius: '250px',
							filter: 'blur(87px)',
							zIndex: -1,
						}}
						/>
						<BodyText darktext>
							{t('best_way_to_discover_our_solution')}
						</BodyText>
						<Stack direction="row" sx={{ mt: 5 }} justifyContent="space-between" alignItems="center">
							<CircularProgress value={31} />
							<BodyText sx={{ width: '60%' }}>
								{t('happy_employee_productivity')}
							</BodyText>
						</Stack>
						<Stack direction="row" sx={{ mt: 5 }} justifyContent="space-between" alignItems="center">
							<CircularProgress value={37} />
							<BodyText sx={{ width: '60%' }}>
								{t('productivity_and_sales')}
							</BodyText>
						</Stack>
						<BodyText sx={{ mt: 5 }}>
							{t('free_your_employee_from_stress')}
						</BodyText>
					</Box>
					<Box sx={{ width: { md: '50%', xs: '100%' } }}>
						<ContactUsForm />
					</Box>
				</Stack>
			</Box>
		</section>
	);
};

export default ContactPhcPos;
