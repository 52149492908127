import {
	Box, Stack, Typography, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import { user } from 'assets/images';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Space from '../space/Space';

const ClientArea = () => {
	const theme = useTheme();
	const { t } = useTranslation('translations');

	const handleClick = () => {
		window.open('http://clientes.ajtec.pt/programs/login.aspx', '_blank');
	};

	return (
		<Stack direction="row" sx={{ cursor: 'pointer' }} onClick={handleClick}>
			<img src={user} alt="Client Area" />
			<Space width={Dimens.XS_SPACING} />
			<Box>

				<Typography sx={{ fontSize: Dimens.TEXT_MEDIUM, fontWeight: Dimens.TEXT_WEIGHT_LIGHT }}>
					{t('client_area')}
				</Typography>
				<Typography sx={{
					fontSize: Dimens.TEXT_DEFAULT,
					fontWeight: Dimens.TEXT_WEIGHT_LIGHT,
					color: theme.palette.textLight.main,
				}}
				>
					{t('reserved_access')}
				</Typography>
			</Box>
		</Stack>
	);
};

export default ClientArea;
