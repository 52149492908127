import { Either } from '@ajtec/either-typescript';
import { ISupportReviews } from '../entities/support-reviews';
import { ITechnicalSupportRepository } from '../repositories/technical-support-repository';

export class GetSupportReviews {
	repository: ITechnicalSupportRepository;

	constructor(technicalSupportRepository: ITechnicalSupportRepository) {
		this.repository = technicalSupportRepository;
	}

	call(): Promise<Either<string, ISupportReviews[]>> {
		return this.repository.getSupportReviews();
	}
}
