import React from 'react';
import { ContactProvider } from 'features/contact-us/presentation/provider/contact.provider';
import ContactFueltec from './ContactFueltec';
import FuelTecHero from './FuelTecHero';
import FuelTecPartners from './FuelTecPartners';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import FueltecReviews from './FueltecReviews';
import { FuelTecProvider } from './provider/fueltec.provider';
import SecurityGuarantee from './SecurityGuarantee';

const FuelTec = () => (
	<main>
		<FuelTecProvider>
			<ContactProvider>
				<FuelTecHero />
				<FuelTecPartners />
				<SecurityGuarantee />
				{/* <FueltecReviews /> */}
				<ContactFueltec />
			</ContactProvider>
		</FuelTecProvider>
	</main>
);

export default FuelTec;
