import { Box, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import { technicalSupport } from 'assets/images';
import {
	ActionButtons, BodyText, SizedBox, TitleLarge,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TechnicalSupportHero = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Stack sx={{
				pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
			}}
			>
				<ActionButtons text={`${t('phc_solutions')} / ${t('technical_support')}`} showLine />
				<Stack direction={{ xl: 'row', xs: 'column' }} sx={{ mt: { md: Dimens.SECTION_SPACING_MOBILE, xs: Dimens.MD_SPACING }, pr: { md: Dimens.APP_BAR_PADDING, xs: 2 } }}>
					<Box sx={{ width: { xl: '50%', xs: '100%' } }}>
						<TitleLarge>{t('gain_time_quality_effectiveness')}</TitleLarge>
						<SizedBox height={6} />
						<BodyText>
							{t('gain_time_quality_effectiveness_info')}
						</BodyText>
						<SizedBox height={5} />
						{/* <DefaultButton sx={{ m: 0, p: 0 }} variant="text"
						endIcon={<ArrowRightAlt />}>{t('know_our_success_cases')}</DefaultButton> */}
					</Box>
					<Stack direction="column" alignItems="flex-end" sx={{ mt: 4, width: { xl: '50%' }, display: { md: 'flex', xs: 'none' } }}>
						<img src={technicalSupport} width="80%" alt="Technical Support" />
					</Stack>
				</Stack>
			</Stack>
		</section>
	);
};

export default TechnicalSupportHero;
