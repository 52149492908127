import { Box, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import {
	ActionButtons, ImageScrollView, SizedBox, TitleLarge,
} from 'common/presentation/components';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInViewRef } from 'rooks';
import useResearch from './provider/research.provider';

const ResearchHero = () => {
	const { t } = useTranslation('translations');
	const { labs, getLabs, loading } = useResearch();
	const [labRef, inView] = useInViewRef();

	useEffectCustom(() => {
		if (inView && labs.length === 0) {
			getLabs();
		}
	}, [inView]);

	return (
		<section id="research-hero" ref={labRef}>
			<ActionButtons />
			<Stack sx={{ mt: { md: 5, xs: 2 }, position: 'relative' }}>
				<Box sx={{ pl: { md: Dimens.APP_BAR_PADDING, xs: 2 } }}>
					<Box sx={{
						position: 'absolute',
						top: 40,
						right: 10,
						height: '100%',
						width: '40%',
						backgroundImage: 'linear-gradient(180deg, rgb(103, 226, 194, 0.5) 0%,rgba(180, 255, 153, 0.3) 100%)',
						borderRadius: '50%',
						filter: 'blur(87px)',
						zIndex: -1,
					}}
					/>
					<SizedBox height={7} />
					<TitleLarge sx={{ pr: { md: Dimens.APP_BAR_PADDING, xs: 2 } }}>
						{t('lab_aspect_info')}
					</TitleLarge>
				</Box>
				<SizedBox height={7} />
				<ImageScrollView labs={labs} loading={loading} />

			</Stack>
		</section>
	);
};

export default ResearchHero;
