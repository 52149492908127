import { Box, Stack, useTheme } from '@mui/material';
import Dimens from 'assets/dimens';
import { flowDiagramEn, flowDiagramPt } from 'assets/images/icons';
import i18n from 'assets/translations';
import { BodyText, SizedBox, TitleSmall } from 'common/presentation/components';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const WorkMethod = () => {
	const { t } = useTranslation('translations');
	const theme = useTheme();
	const [flowImage, setFlowImage] = useState(flowDiagramPt);
	const { language } = i18n;

	useEffectCustom(() => {
		if (language === 'en') {
			setFlowImage(flowDiagramEn);
		} else {
			setFlowImage(flowDiagramPt);
		}
	}, [language]);

	return (
		<section>
			<Stack
				direction={{ xl: 'row', xs: 'column' }}
				justifyContent="space-between"
				alignItems="center"
				sx={{
					pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
				}}
			>
				<Box sx={{ width: { xl: '35%', xs: '100%' } }}>
					<TitleSmall>
						{t('work_method')}
					</TitleSmall>
					<SizedBox height={3} showLine />
					<SizedBox height={6} />
					<BodyText sx={{ color: theme.palette.textMain.main }}>{t('work_method_info_one')}</BodyText>
					<SizedBox height={3} />
					<BodyText sx={{ color: theme.palette.textMain.main }}>{t('work_method_info_two')}</BodyText>
				</Box>
				<Box sx={{ width: { xl: '55%', xs: '100%' } }}>
					<img src={flowImage} alt="Flow Diagram" width="90%" />
				</Box>
			</Stack>
		</section>
	);
};

export default WorkMethod;
