/* eslint-disable class-methods-use-this */
import { labortory, sensorLight } from 'assets/images/pricetec';
import { PriceTecLabModel } from '../dto/pricetec-lab-model';
import { PriceTecReviewModel } from '../dto/pricetec-review-model';
import { PriceTecApi } from './pricetec-api';

export class PricetecApiImpl implements PriceTecApi {
	getPricetecLabs(): Promise<PriceTecLabModel[]> {
		try {
			const labs = [{
				id: 1,
				image: labortory,
			},
			{
				id: 2,
				image: sensorLight,
			}, {
				id: 1,
				image: labortory,
			},
			{
				id: 2,
				image: sensorLight,
			}, {
				id: 1,
				image: labortory,
			},
			{
				id: 2,
				image: sensorLight,
			},
			];
			const labsPromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(labs);
				}, 300);
			});

			return labsPromise.then(() => labs);
		} catch (error) {
			throw new Error('An Error Occured');
		}
	}

	getPriceTecReviews(): Promise<PriceTecReviewModel[]> {
		try {
			const comments = [
				{
					id: 1, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 2, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 3, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 4, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 5, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
			];
			const commentPromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(comments);
				}, 300);
			});
			return commentPromise.then(() => comments);
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
