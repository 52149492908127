import React from 'react';
import { ContactProvider } from 'features/contact-us/presentation/provider/contact.provider';
import GetInTouch from './GetInTouch';
import PhcBusinessCoreHero from './PhcBusinessCoreHero';
import { BusinessCoreProvider } from './provider/phc-business.provider';
import ReachNextLevel from './ReachNextLevel';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Reviews from './Reviews';
import SolutionAdvantage from './SolutionAdvantage';

const PhcBusinessCore = () => (
	<main>
		<BusinessCoreProvider>
			<ContactProvider>
				<PhcBusinessCoreHero />
				<SolutionAdvantage />
				<ReachNextLevel />
				{/* <Reviews /> */}
				<GetInTouch />
			</ContactProvider>
		</BusinessCoreProvider>
	</main>
);

export default PhcBusinessCore;
