import AppLinks from 'assets/applink.routes';
import { NotFound } from 'common/presentation/components';
import AboutUs from 'features/about-us/presentation';
import ContactUs from 'features/contact-us/presentation';
import FleetAndMobility from 'features/fleet-mobility/presentation';
import HomeScreen from 'features/home/presentation';
import LogisticsAndDistribution from 'features/logistics/presentation';
import PhcBusinessCore from 'features/phc-business-core/presentation';
import PhcBusinessManagment from 'features/phc-business-managment/presentation';
import PhcPos from 'features/phc-pos/presentation';
import FuelTec from 'features/products-fueltec/presentation';
import PriceTec from 'features/products-pricetec/presentation';
import ResearchLab from 'features/research-lab/presentation';
import TechnicalSupport from 'features/technical-support/presentation';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

const AppRouter = () => (
	<Routes>
		<Route path={AppLinks.HOME} element={<HomeScreen />} />
		<Route path={AppLinks.ABOUT_US} element={<AboutUs />} />
		<Route path={AppLinks.CONTACT_US} element={<ContactUs />} />
		<Route path={AppLinks.RESEARCH_LAB} element={<ResearchLab />} />
		<Route path={AppLinks.PHC_SOLUTIONS} element={<PhcBusinessManagment />} />
		<Route path={AppLinks.PHC_BUSINESS_CORE} element={<PhcBusinessCore />} />
		<Route path={AppLinks.PHC_SOLUTIONS_FLEET_MOBILITY} element={<FleetAndMobility />} />
		<Route path={AppLinks.PHC_CS_POS} element={<PhcPos />} />
		<Route path={AppLinks.LOGISTIC_AND_DISTRIBUTION} element={<LogisticsAndDistribution />} />
		<Route path={AppLinks.TECHNICAL_SUPPORT} element={<TechnicalSupport />} />
		<Route path={AppLinks.FUEL_TEC} element={<FuelTec />} />
		<Route path={AppLinks.PRICE_TEC} element={<PriceTec />} />
		<Route path="*" element={<NotFound />} />
	</Routes>
);

export default AppRouter;
