import { Box, Skeleton, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import { SizedBox, TitleSmall } from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInViewRef } from 'rooks';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import useHome from './provider/home.provider';

const OurPartners = () => {
	const { t } = useTranslation('translations');
	const { partnerLoading, partners, getPartners } = useHome();
	const [partnerRef, inView] = useInViewRef();

	useEffectCustom(() => {
		if (inView && partners.length === 0) {
			getPartners();
		}
	}, [inView]);

	return (
		<section ref={partnerRef} id="Partners">
			<Stack
				alignItems="center"
				sx={{
					pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				}}
			>
				<TitleSmall>{t('our_partners')}</TitleSmall>
				<SizedBox showLine height={6} />
				<Stack
					direction="row"
					flexWrap="wrap"
					rowGap={2}
					spacing={2}
					justifyContent="space-around"
					alignItems="center"
					sx={{ width: '100%' }}
				>
					{partnerLoading && <Skeleton variant="rectangular" width="100%" height={100} />}
					{!partnerLoading && partners.map((el) => <Box key={el.id}><img src={el.image} alt="partners" /></Box>)}
				</Stack>
			</Stack>
		</section>
	);
};

export default OurPartners;
