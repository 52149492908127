import { Box, Grid } from '@mui/material';
import Dimens from 'assets/dimens';
import { FeatureListItem, SizedBox, TitleMedium } from 'common/presentation/components';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInViewRef } from 'rooks';
import useBusinessCore from './provider/phc-business.provider';

const SolutionAdvantage = () => {
	const { t } = useTranslation('translations');
	const { getFeatures, features } = useBusinessCore();

	const [featuresRef, inView] = useInViewRef();

	useEffectCustom(() => {
		if (inView && features.length === 0) {
			getFeatures();
		}
	}, [inView]);

	return (
		<section id="Our solution advantages" ref={featuresRef}>
			<Box sx={{
				pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
				pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
				mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
			}}
			>
				<TitleMedium sx={{ width: { md: '50%', xs: '100%' } }}>{t('solution_advantages')}</TitleMedium>
				<SizedBox showLine height={6} />
				<Grid container spacing={4} sx={{ position: 'relative' }}>
					<Box sx={{
						position: 'absolute',
						bottom: 0,
						right: 10,
						height: { xl: '500px', xs: '300px' },
						width: { xl: '500px', xs: '300px' },
						backgroundImage: 'linear-gradient(180deg, rgb(103, 226, 194, 0.5) 0%,rgba(180, 255, 153, 0.3) 100%)',
						borderRadius: '250px',
						filter: 'blur(87px)',
						zIndex: -1,
					}}
					/>
					{features.length !== 0 && features.map((el) => (
						<Grid item xl={4} md={6} xs={12} key={el.id}>
							<FeatureListItem key={el.id} title={el.title} />
						</Grid>
					))}
				</Grid>
			</Box>
		</section>
	);
};

export default SolutionAdvantage;
