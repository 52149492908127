/* eslint-disable class-methods-use-this */
import { CompanyFeatureModel } from '../dto/company-feature-model';
import { LogisticCommentModel } from '../dto/logistic-comments-model';
import { LogisticApi } from './logistic-api';

export class LogisticApiImpl implements LogisticApi {
	getCompanyFeatures(): Promise<CompanyFeatureModel[]> {
		try {
			const features = [{
				id: 1,
				title: 'Controlo global sobre todos os artigos',
			},
			{
				id: 2,
				title: 'Mais agilidade na gestão dos armazéns',
			},
			{
				id: 3,
				title: 'Stocks controlados e monitorizados',
			},
			{
				id: 4,
				title: 'Expedição mais rápida',
			},
			{
				id: 5,
				title: 'Redução de custos operacionais',
			},
			{
				id: 6,
				title: 'Simplificação de processos',
			},
			{
				id: 7,
				title: 'Maior automatização nas encomendas',
			},
			{
				id: 8,
				title: 'Informação integrada entre as várias áreas',
			},
			{
				id: 9,
				title: 'Criação direta de documentos',
			},
			{
				id: 10,
				title: 'Melhoria das relações com clientes e fornecedores',
			},
			];

			const featurePromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(features);
				}, 300);
			});
			return featurePromise.then(() => features);
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}

	getLogisticComments(): Promise<LogisticCommentModel[]> {
		try {
			const comments = [
				{
					id: 1, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 2, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 3, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 4, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 5, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
			];
			const commentPromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(comments);
				}, 300);
			});
			return commentPromise.then(() => comments);
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
