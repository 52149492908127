/* eslint-disable class-methods-use-this */
import { AutomationFeatureModel } from '../dto/automation-feature-model';
import { MobilityCommentModel } from '../dto/mobility-comment-model';
import { FleetMobilityApi } from './fleet-mobility-api';

export class FleetMobilityApiImpl implements FleetMobilityApi {
	getAutomationFeatures(): Promise<AutomationFeatureModel[]> {
		try {
			const features = [{
				id: 1,
				title: 'Historial detalhado de cada viatura',
			},
			{
				id: 2,
				title: 'Gestão de manutenção passada e futura',
			},
			{
				id: 3,
				title: 'Controlo de custos ',
			},
			{
				id: 4,
				title: 'Monitorização dos vários acessórios',
			},
			{
				id: 5,
				title: 'Análises de atividade da frota',
			},
			{
				id: 6,
				title: 'Estastísticas e gráficos sobre o negócio',
			},
			{
				id: 7,
				title: 'Integração entre ferramentas',
			},
			{
				id: 8,
				title: 'Simplificação e automatização de processos',
			},
			{
				id: 9,
				title: 'Gestão de motoristas e viagens',
			},
			{
				id: 10,
				title: 'Controlo de seguros e “leasings”',
			},
			];

			const automationFeaturesPromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(features);
				}, 300);
			});
			return automationFeaturesPromise.then(() => features);
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}

	getMobilityComments(): Promise<MobilityCommentModel[]> {
		try {
			const comments = [
				{
					id: 1, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 2, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 3, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 4, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 5, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
			];
			const commentPromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(comments);
				}, 300);
			});
			return commentPromise.then(() => comments);
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
