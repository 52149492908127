import { Card, useTheme } from '@mui/material';
import Dimens from 'assets/dimens';
import { BodyText } from 'common/presentation/components';
import React from 'react';
// import { useTranslation } from 'react-i18next';

interface Props {
	image: string,
	title: string,
	description: string,
	focused?: boolean,
	onClick: () => void
}

const ProductCard: React.FC<Props> = (props) => {
	const {
		image, title, description, focused, onClick,
	} = props;
	// const { t } = useTranslation('translations');
	const theme = useTheme();
	const bgColor = focused ? theme.palette.primary.main : theme.palette.background.default;
	const elevation = focused ? 10 : 0;
	const border = focused ? 'none' : `1px solid ${theme.palette.divider}`;
	const width = focused ? { md: Dimens.DEFAULT_CARD_WIDTH, xs: Dimens.DEFAULT_CARD_WIDTH_MOBILE }
		: { md: Dimens.CARD_WIDTH_UNFOCUSED, xs: Dimens.CARD_WIDTH_UNFOCUSED_MOBILE };
	const height = focused ? { md: Dimens.DEFAULT_CARD_HEIGHT, xs: Dimens.DEFAULT_CARD_HEIGHT_MOBILE }
		: { md: Dimens.CARD_HEIGHT_UNFOCUSED, xs: Dimens.CARD_HEIGHT_UNFOCUSED_MOBILE };

	return (
		<Card
			elevation={elevation}
			sx={{
				bgcolor: bgColor,
				width,
				height,
				borderRadius: { md: Dimens.CARD_BORDER_RADIUS, xs: Dimens.CARD_BORDER_RADIUS_MOBILE },
				border,
				m: { xl: 3, xs: 1 },
			}}
		>
			<Card
				elevation={elevation}
				sx={{
					width: '100%',
					height: '99%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-around',
					alignItems: 'center',
					p: 1,
					cursor: 'pointer',
					borderRadius: { md: Dimens.CARD_BORDER_RADIUS, xs: Dimens.CARD_BORDER_RADIUS_MOBILE },
				}}
				onClick={onClick}
			>
				<img src={image} height={150} alt="phc" />
				<BodyText
					maxLines={1}
					sx={{
						color: theme.palette.textMain.main,
						fontSize: {
							md: Dimens.TEXT_TITLE_EXTRA_SMALL,
							xs: Dimens.TEXT_TITLE_EXTRA_SMALL_MOBILE,
						},
						fontWeight: Dimens.TEXT_WEIGHT_NORMAL,
					}}
				>
					{title}

				</BodyText>
				<BodyText textAlign="center" sx={{ fontSize: Dimens.TEXT_DEFAULT }} maxLines={focused ? 100 : 3}>{description}</BodyText>
				{/* <DefaultButton
						onClick={() => onClick()}
						variant="text"
						sx={{ color: theme.palette.primary.main }}
						endIcon={<ArrowRightAlt />}
					>
					{t('know_solutions')}
				</DefaultButton> */}
			</Card>
		</Card>
	);
};

export default ProductCard;
