/* eslint-disable class-methods-use-this */

import { CommentModel } from '../dto/comments-model';
import { FeatureModel } from '../dto/feature-model';
import { BusinessCoreApi } from './business-core-api';

export class BusinessCoreApiImpl implements BusinessCoreApi {
	getFeatures(): Promise<FeatureModel[]> {
		try {
			const features = [{
				id: 1,
				title: 'Controlo integral sobre os dados da empresa',
			},
			{
				id: 2,
				title: 'Maior agilidade e produtividade',
			},
			{
				id: 3,
				title: 'Configuração personalizada para as suas necessidades',
			},
			{
				id: 4,
				title: 'Análises pormenorizadas sobre áreas vitais',
			},
			{
				id: 5,
				title: 'Apoio no cumprimento de prazos ',
			},
			{
				id: 6,
				title: 'Simplificação de processos',
			},
			{
				id: 7,
				title: 'Automatização e agendamento de tarefas',
			},
			{
				id: 8,
				title: 'Informação mais concentrada',
			},
			{
				id: 9,
				title: 'Maior mobilidade e adaptabilidade',
			},
			{
				id: 10,
				title: 'Melhoria das relações com clientes e fornecedores',
			},
			{
				id: 11,
				title: 'Preparado para as questões legais',
			},
			];

			const featurePromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(features);
				}, 300);
			});
			return featurePromise.then(() => features);
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}

	getComments(): Promise<CommentModel[]> {
		try {
			const comments = [
				{
					id: 1, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 2, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 3, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 4, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 5, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
			];
			const commentPromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(comments);
				}, 300);
			});
			return commentPromise.then(() => comments);
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
