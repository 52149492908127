import { Either } from '@ajtec/either-typescript';
import { IHomeRepository } from 'features/home/domain/repositories/home-repository';
import { Question } from '../entities/question';

export class GetQuestions {
	repository: IHomeRepository;

	constructor(homeRepository: IHomeRepository) {
		this.repository = homeRepository;
	}

	call(lang: string): Promise<Either<string, Question[]>> {
		return this.repository.getQuestions(lang);
	}
}
