import {
	Checkbox, FormControlLabel, FormGroup, Grid,
} from '@mui/material';
import {
	DefaultButton, DefaultInput, SizedBox, Captcha,
	DefaultDropdwon,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useContact } from '../provider/contact.provider';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ContactUsForm = () => {
	const { t } = useTranslation('translations');
	const {
		submitContactRequest,
		loading,
		formInputs,
		setFormInputs,
		formErrors,
		setCaptcha,
		redrawCaptcha,
	} = useContact();

	const getCaptcha = (mCaptcha: string) => {
		setCaptcha(mCaptcha);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setFormInputs({ ...formInputs, [name]: value });
	};

	const handleFormSubmit = () => {
		submitContactRequest(formInputs);
	};

	return (
		<>
			{/* <Grid container spacing={3}>
				<Grid item md={12} xs={12}>
					<DefaultDropdwon
						label={t('interest')}
						onChange={() => { console.log('test'); }}
						options={['test1', 'test2']}
						value="test"
					/>
				</Grid>
			</Grid> */}
			<Grid container spacing={3}>
				<Grid item md={6} xs={12}>
					<DefaultInput
						name="name"
						value={formInputs.name}
						error={!!formErrors.name}
						helperText={formErrors.name}
						disabled={loading}
						title={t('name')}
						onChange={handleChange}
						placeholder={t('your_full_name')}
					/>
				</Grid>
				<Grid item md={6} xs={12}>
					<DefaultInput
						name="surname"
						value={formInputs.surname}
						error={!!formErrors.surname}
						helperText={formErrors.surname}
						disabled={loading}
						onChange={handleChange}
						title={t('surname')}
						placeholder={t('your_surname')}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item md={6} xs={12}>
					<DefaultInput
						name="company"
						value={formInputs.company}
						error={!!formErrors.company}
						helperText={formErrors.company}
						disabled={loading}
						onChange={handleChange}
						title={t('company')}
						placeholder={t('your_company_name')}
					/>
				</Grid>
				<Grid item md={6} xs={12}>
					<DefaultDropdwon
						options={['0-5', '6-10', '11-20', '21-50', '50+']}
						title={t('company_size')}
						label={t('tailor_made_solution')}
						value={formInputs.companySize}
						error={!!formErrors.companySize}
						helperText={formErrors.companySize}
						disabled={loading}
						onChange={(e) => {
							setFormInputs({ ...formInputs, companySize: e.target.value });
						}}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item md={6} xs={12}>
					<DefaultInput
						name="contact"
						value={formInputs.contact}
						error={!!formErrors.contact}
						onChange={handleChange}
						helperText={formErrors.contact}
						disabled={loading}
						title={t('contact')}
						placeholder={t('your_contact_number')}
					/>
				</Grid>
				<Grid item md={6} xs={12}>
					<DefaultInput
						name="email"
						value={formInputs.email}
						error={!!formErrors.email}
						helperText={formErrors.email}
						onChange={handleChange}
						disabled={loading}
						title={t('email')}
						placeholder={t('enter_your_email')}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item md={12} xs={12}>
					<DefaultInput
						name="message"
						value={formInputs.message}
						error={!!formErrors.message}
						helperText={formErrors.message}
						disabled={loading}
						onChange={handleChange}
						title={t('message')}
						placeholder={t('message_info')}
						rows={5}
						multiline
					/>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item md={5} xs={12}>
					<SizedBox height={2} />
					<Captcha
						width={300}
						height={150}
						getCaptcha={getCaptcha}
						redrawCaptcha={redrawCaptcha}
					/>

				</Grid>
				<Grid item md={7} xs={12}>
					<DefaultInput
						name="captcha"
						error={!!formErrors.captcha}
						helperText={formErrors.captcha}
						value={formInputs.captcha}
						disabled={loading}
						onChange={handleChange}
						title={t('verification_code')}
						placeholder={t('write_verification_code')}
					/>
					<FormGroup>
						<FormControlLabel control={<Checkbox defaultChecked />} label={t('accept_collection_of_info')} />
					</FormGroup>
					<DefaultButton loading={loading} onClick={handleFormSubmit} size="small">{t('submit_request')}</DefaultButton>
				</Grid>
			</Grid>
		</>
	);
};

export default ContactUsForm;
