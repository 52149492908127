import { Stack, useTheme } from '@mui/material';
import Dimens from 'assets/dimens';
import { BodyText, SizedBox, TitleLarge } from 'common/presentation/components';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInViewRef } from 'rooks';
import { useNavigate } from 'react-router-dom';
import {
	Bag,
	Archivebox,
	Boxsearch,
	Clipboard,
	Gridlock,
	Moneyreceive,
	People,
	Smileys,
} from 'assets/images/icons';
import usePhcBusiness from './provider/phc-business.provider';
import SolutionCard from './components/SolutionCard';

const NextLevelBusiness = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const { phcSolutions, getPhcSolutions } = usePhcBusiness();
	const [solutionRef, inView] = useInViewRef();
	const theme = useTheme();

	const ICONS = [
		<Bag />,
		<Archivebox />,
		<Boxsearch />,
		<Clipboard />,
		<Gridlock />,
		<Moneyreceive />,
		<People />,
		<Smileys />,
	];

	useEffectCustom(() => {
		if (inView && phcSolutions.length === 0) {
			getPhcSolutions();
		}
	}, [inView]);

	return (
		<section id="Solutions" ref={solutionRef}>
			<Stack
				alignItems="center"
				sx={{
					pl: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
					pr: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
					pt: {
						md: Dimens.SECTION_SPACING,
						xs: Dimens.SECTION_SPACING_MOBILE,
					},
				}}
			>
				<TitleLarge textAlign="center">
					{t('take_business_to_next_level')}
				</TitleLarge>
				<SizedBox height={3} showLine />
				<BodyText textAlign="center" sx={{ color: theme.palette.textMain.main }}>
					{t('take_business_to_next_level_info_para_one')}
				</BodyText>
				<SizedBox height={1} />
				<BodyText textAlign="center" sx={{ color: theme.palette.textMain.main }}>
					{t('take_business_to_next_level_info_para_two')}
				</BodyText>
			</Stack>
			<Stack
				direction="row"
				justifyContent={{
					xl: 'center', lg: 'flex-start', md: 'flex-start', sm: 'flex-start', xs: 'flex-start',
				}}
				sx={{
					pt: Dimens.USER_ACTION_SPACING,
					pb: Dimens.USER_ACTION_SPACING,
					overflowX: 'scroll',
					'&::-webkit-scrollbar': { display: 'none' },
					scrollbarWidth: 'none',
					msOverflowStyle: 'none',
				}}
			>
				{phcSolutions.map((el) => (
					<SolutionCard
						key={el.title}
						title={el.title}
						icon={ICONS[el.id - 1]}
						onClick={() => navigate(el.link)}
					/>
				))}
			</Stack>
		</section>
	);
};

export default NextLevelBusiness;
