import { Either } from '@ajtec/either-typescript';
import { ILabs } from '../entities/lab-images';
import { IResearchRepository } from '../repositories/research-repository';

export class GetLabs {
	repository: IResearchRepository;

	constructor(homeRepository: IResearchRepository) {
		this.repository = homeRepository;
	}

	call(): Promise<Either<string, ILabs[]>> {
		return this.repository.getLabs();
	}
}
