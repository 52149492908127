import React from 'react';
import { ContactProvider } from 'features/contact-us/presentation/provider/contact.provider';
import BusinessNeeds from './BusinessNeeds';
import CompanyGains from './CompanyGains';
import GetInTouchLogistic from './GetInTouchLogistic';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import LogisticComments from './LogisticComments';
import LogisticsHero from './LogisticsHero';
import { LogisticProvider } from './provider/logistic.provider';

const LogisticsAndDistribution = () => (
	<main>
		<LogisticProvider>
			<ContactProvider>
				<LogisticsHero />
				<CompanyGains />
				<BusinessNeeds />
				{/* <LogisticComments /> */}
				<GetInTouchLogistic />
			</ContactProvider>
		</LogisticProvider>
	</main>
);

export default LogisticsAndDistribution;
