import { Either, Left, Right } from '@ajtec/either-typescript';
import { ILabs } from 'features/about-us/domain/entities/lab-images';
import { IResearchRepository } from 'features/research-lab/domain/repositories/research-repository';
import { CResearchApiImpl } from '../remote/research-api-impl';

export class CResearchRepositoryImpl implements IResearchRepository {
	homeApi = new CResearchApiImpl();

	async getLabs(): Promise<Either<string, ILabs[]>> {
		try {
			const products = await this.homeApi.getLabs();
			return new Right(products);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}
}
