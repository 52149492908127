/* eslint-disable class-methods-use-this */

import { PhcCommentModel } from '../dto/phc-comment-model';
import { PhcPosApi } from './phc-pos-api';

export class PhcPosApiImpl implements PhcPosApi {
	getPhcComments(): Promise<PhcCommentModel[]> {
		try {
			const comments = [
				{
					id: 1, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 2, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 3, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 4, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 5, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
			];
			const commentPromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(comments);
				}, 300);
			});
			return commentPromise.then(() => comments);
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
