import { CancelOutlined, KeyboardArrowDown } from '@mui/icons-material';
import {
	Box,
	Card,
	IconButton,
	Menu,
	Stack, styled, Typography, useTheme,
} from '@mui/material';
import AppLinks from 'assets/applink.routes';
import Dimens from 'assets/dimens';
import {
	Archivebox, BoxIcon, Graph, GreenCar, Keyboardopen, People, submenu,
} from 'assets/images';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SubMenuItem from '../submenu-item/SubMenuItem';

interface Props {
	title: string
}

const PhcSolutions: React.FC<Props> = (props) => {
	const { palette } = useTheme();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const { t } = useTranslation('translations');
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const { title } = props;
	const RouteText = styled(Typography)(({ theme }) => ({
		color: theme.palette.textMain.main,
		fontWeight: Dimens.TEXT_WEIGHT_BOLD,
		fontSize: Dimens.TEXT_DEFAULT,
		lineHeight: Dimens.LINE_HEIGHT_DEFAULT,
	}));

	return (
		<div>
			<Stack direction="row" alignItems="center" sx={{ '&:hover': { cursor: 'pointer' } }} onClick={handleClick}>
				<RouteText>{title}</RouteText>
				<KeyboardArrowDown color="secondary" />
				{open && <Box sx={{ bgcolor: 'secondary', height: 5 }} />}
			</Stack>
			<Menu
				elevation={4}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<Card
					elevation={0}
					sx={{
						height: Dimens.LARGE_MENU_HEIGHT,
						width: Dimens.LARGE_MENU_WIDTH,
						pt: Dimens.PADDING_MEDIUM,
						pr: Dimens.PADDING_SMALL,
						pb: Dimens.PADDING_SMALL,
					}}
				>
					<Stack
						direction="column"
						justifyContent="space-between"
						sx={{ height: '100%' }}
					>
						<Stack direction="row" justifyContent="flex-end">
							<IconButton onClick={handleClose}>
								<CancelOutlined color="primary" sx={{ color: palette.textLight.main }} />
							</IconButton>
						</Stack>
						<Stack direction="row" justifyItems="space-between">
							<SubMenuItem close={handleClose} route={AppLinks.PHC_SOLUTIONS} icon={<Graph />} title={t('your_phc_software')} subtitle={t('Management software')} items={[t('meet_the_phc'), t('operating_sectors'), t('advantages_phc_for_companies'), t('free_evaluation_request')]} />
							<SubMenuItem close={handleClose} route={AppLinks.PHC_CS_POS} icon={<Keyboardopen />} title={t('pos_solution')} subtitle={t('phc_software')} items={[t('discover_this_solution'), t('what_our_customer_say'), t('pos_assessment_request')]} />
						</Stack>
						<Stack direction="row" justifyItems="space-between">
							<SubMenuItem close={handleClose} route={AppLinks.PHC_BUSINESS_CORE} icon={<Archivebox />} title={t('business_core_solution')} subtitle={t('phc_software')} items={[t('discover_this_solution'), t('what_our_customer_say'), t('business_core_trail_request')]} />
							<SubMenuItem close={handleClose} route={AppLinks.LOGISTIC_AND_DISTRIBUTION} icon={<BoxIcon />} title={t('logistic_distribution_solution')} subtitle={t('phc_software')} items={[t('discover_this_solution'), t('what_our_customer_say'), t('logistic_evaluation_request')]} />
						</Stack>
						<Stack direction="row" justifyItems="space-between">
							<SubMenuItem close={handleClose} route={AppLinks.PHC_SOLUTIONS_FLEET_MOBILITY} icon={<GreenCar />} title={t('fleet_and_mobility_solution')} subtitle={t('phc_software')} items={[t('discover_this_solution'), t('what_our_customer_say'), t('fleet_assessment_request')]} />
							<SubMenuItem close={handleClose} route={AppLinks.TECHNICAL_SUPPORT} icon={<People color={palette.secondary.main} width="30px" />} title={t('technical_support_solution')} subtitle={t('phc_software')} items={[t('discover_this_solution'), t('what_our_customer_say'), t('technical_support_evaluation_request')]} />
						</Stack>
						<Stack direction="row" justifyContent="flex-end">
							<img src={submenu} alt="coding icon" />
						</Stack>
					</Stack>
				</Card>
			</Menu>
		</div>
	);
};

export default React.memo(PhcSolutions);
