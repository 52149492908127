import { CancelOutlined, KeyboardArrowDown } from '@mui/icons-material';
import {
	Card, IconButton, Menu, Stack, styled, Typography, useTheme, Box,
} from '@mui/material';
import AppLinks from 'assets/applink.routes';
import Dimens from 'assets/dimens';
import {
	Graph, illustra, illustra1, illustra2, Keyboardopen,
} from 'assets/images';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SubMenuItem from '../submenu-item/SubMenuItem';

interface Props {
	title: string
}

const OurProductsMenu:React.FC<Props> = (props) => {
	const { palette } = useTheme();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const { t } = useTranslation('translations');
	const { title } = props;
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const RouteText = styled(Typography)(({ theme }) => ({
		color: theme.palette.textMain.main,
		fontWeight: Dimens.TEXT_WEIGHT_BOLD,
		fontSize: Dimens.TEXT_DEFAULT,
		lineHeight: Dimens.LINE_HEIGHT_DEFAULT,
	}));
	return (
		<div>
			<Stack direction="row" alignItems="center" sx={{ '&:hover': { cursor: 'pointer' } }} onClick={handleClick}>
				<RouteText>{title}</RouteText>
				<KeyboardArrowDown color="secondary" />
				{open && <Box sx={{ bgcolor: 'secondary', height: 5 }} />}
			</Stack>
			<Menu
				elevation={4}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<Card
					elevation={0}
					sx={{
						width: Dimens.LARGE_MENU_WIDTH,
						pt: Dimens.PADDING_SMALL,
						pr: Dimens.PADDING_MEDIUM,
						pb: Dimens.PADDING_SMALL,
					}}
				>
					<Stack
						direction="column"
						justifyContent="space-between"
						sx={{ height: '100%' }}
					>
						<Stack direction="row" justifyContent="flex-end">
							<IconButton onClick={handleClose}>
								<CancelOutlined color="primary" sx={{ color: palette.textLight.main }} />
							</IconButton>
						</Stack>
						<Stack direction="row" justifyItems="space-between">
							<SubMenuItem close={handleClose} route={AppLinks.FUEL_TEC} icon={<Graph />} title="FUELTEC" subtitle={t('our_products')} items={[t('discover_fueltec')]} />
							<SubMenuItem close={handleClose} route={AppLinks.PRICE_TEC} icon={<Keyboardopen />} title="PRICETEC" subtitle={t('our_products')} items={[t('price_tec_info_para_one')]} />
						</Stack>
						<Stack direction="row" justifyContent="space-between" sx={{ pl: Dimens.PADDING_MEDIUM, pr: Dimens.PADDING_MEDIUM }}>
							<Box>
								<img src={illustra2} alt="coding icon" />
								<img src={illustra} alt="coding icon" />
							</Box>
							<img src={illustra1} alt="coding icon" />
						</Stack>
					</Stack>
				</Card>
			</Menu>
		</div>
	);
};

export default OurProductsMenu;
