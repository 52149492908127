import { Either, Left, Right } from '@ajtec/either-typescript';
import { AutomationFeature } from 'features/fleet-mobility/domain/entities/automation-feature';
import { MobilityComment } from 'features/fleet-mobility/domain/entities/mobility-comment';
import { IFleetMobilityRepository } from 'features/fleet-mobility/domain/repositories/fleet-mobility-repository';
import { FleetMobilityApiImpl } from '../remote/fleet-mobility-api-impl';

export class CFleetMobilityRepositoryImpl implements IFleetMobilityRepository {
	fleetMobilityApi = new FleetMobilityApiImpl();

	async getAutomationFeatures(): Promise<Either<string, AutomationFeature[]>> {
		try {
			const products = await this.fleetMobilityApi.getAutomationFeatures();
			return new Right(products);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}

	async getMobilityComments(): Promise<Either<string, MobilityComment[]>> {
		try {
			const comments = await this.fleetMobilityApi.getMobilityComments();
			return new Right(comments);
		} catch (error) {
			return new Left('An Error Occurred');
		}
	}
}
