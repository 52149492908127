import {
	Box, Divider, Stack, styled, SvgIcon, Typography, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import SizedBox from '../sized-box/SizedBox';

interface Props {
	title: string;
	subtitle: string;
	items: string[];
	icon: React.ReactNode;
	route: string;
	close: () => void
}

const SubMenuItem: React.FC<Props> = (props) => {
	const {
		title, subtitle, items, icon, route, close,
	} = props;
	const { palette } = useTheme();
	const navigate = useNavigate();

	const TitleText = styled(Typography)(() => ({
		fontWeight: Dimens.TEXT_WEIGHT_BOLD,
		fontSize: Dimens.TEXT_DEFAULT,
		lineHeight: Dimens.LINE_HEIGHT_DEFAULT,
	}));

	const SubTitleText = styled(Typography)(({ theme }) => ({
		color: theme.palette.textLight.main,
		fontWeight: Dimens.TEXT_WEIGHT_NORMAL,
		fontSize: Dimens.TEXT_SMALL,
		lineHeight: Dimens.LINE_HEIGHT_DEFAULT,
	}));

	const handleClick = () => {
		navigate(route);
		close();
	};

	return (
		<Box sx={{ width: '45%', ml: 4 }}>
			<Stack
				onClick={handleClick}
				direction="row"
				sx={{
					ml: 2,
					pb: 2,
					'&:hover': { cursor: 'pointer' },
				}}
			>
				<SvgIcon component="div" sx={{ m: 1 }}>
					{icon}
				</SvgIcon>
				<Box sx={{ ml: 2 }}>
					<SubTitleText>{subtitle}</SubTitleText>
					<TitleText color="secondary">{title}</TitleText>
				</Box>
			</Stack>
			<Divider />
			{items.map((el) => (
				<Box>
					<SizedBox height={2.5} />
					<TitleText
						sx={{ ml: 8.5, fontWeight: Dimens.TEXT_WEIGHT_NORMAL, color: palette.textMain.main }}
					>
						{el}
					</TitleText>
				</Box>
			))}
		</Box>
	);
};

export default SubMenuItem;
