import { Box, Grid } from '@mui/material';
import Dimens from 'assets/dimens';
import { FeatureListItem, SizedBox, TitleMedium } from 'common/presentation/components';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInViewRef } from 'rooks';
import useFleetMobility from './provider/fleet-mobility.provider';

const BusinessAutomation = () => {
	const { t } = useTranslation('translations');
	const { loading, getAutomationFeatures, automationFeatures } = useFleetMobility();
	const [businessAutomationRef, inView] = useInViewRef();

	useEffectCustom(() => {
		if (inView && automationFeatures.length === 0) {
			getAutomationFeatures();
		}
	}, [inView]);

	return (
		<section id="Business Automation" ref={businessAutomationRef}>
			<Box sx={{
				pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
				pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
				mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
			}}
			>
				<TitleMedium sx={{ width: '673px' }}>{t('business_automation')}</TitleMedium>
				<SizedBox height={6} showLine />
				<Grid container spacing={4}>
					{!loading && automationFeatures.map((el) => (
						<Grid item xl={4} md={6} xs={12} key={el.id}>
							<FeatureListItem key={el.id} title={el.title} />
						</Grid>
					))}
				</Grid>
			</Box>
		</section>
	);
};

export default BusinessAutomation;
