import React from 'react';
import { ContactProvider } from 'features/contact-us/presentation/provider/contact.provider';
import ContactPriceTec from './ContactPriceTec';
import PriceTecHero from './PriceTecHero';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import PricetecReviews from './PricetecReviews';
import PriceTecSolution from './PriceTecSolution';
import { PriceTecProvider } from './provider/pricetec-provider';

const PriceTec = () => (
	<main>
		<PriceTecProvider>
			<ContactProvider>
				<PriceTecHero />
				<PriceTecSolution />
				{/* <PricetecReviews /> */}
				<ContactPriceTec />
			</ContactProvider>
		</PriceTecProvider>
	</main>
);

export default PriceTec;
