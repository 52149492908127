import {
	Box, Grid, Stack,
} from '@mui/material';
import Dimens from 'assets/dimens';
import {
	phcCs, phcGo, phcGoDev, phcAdvance,
} from 'assets/images/partners';
import {
	FeatureListItem,
	SizedBox, TitleSmall,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ProductListItem from './components/ProductListItem';

const SoftwareExpectation = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Box sx={{
				pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
				pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
			}}
			>
				<TitleSmall>
					{t('what_you_get_with_software')}
				</TitleSmall>
				<SizedBox height={6} showLine />
				<Grid container spacing={4}>
					<Grid item xl={4} md={6} xs={12}>
						<FeatureListItem title={t('more_productivity_more_time')} description={t('more_productivity_more_time_info')} />
					</Grid>
					<Grid item xl={4} md={6} xs={12}>
						<FeatureListItem title={t('boost_your_business')} description={t('boost_your_business_info')} />
					</Grid>
					<Grid item xl={4} md={6} xs={12}>
						<FeatureListItem title={t('software_evolution')} description={t('software_evolution_info')} />
					</Grid>
					<Grid item xl={4} md={6} xs={12}>
						<FeatureListItem title={t('information_gathering_process')} description={t('information_gathering_process_info')} />
					</Grid>
					<Grid item xl={4} md={6} xs={12}>
						<FeatureListItem title={t('improve_business_relation')} description={t('improve_business_relation_info')} />
					</Grid>
					<Grid item xl={4} md={6} xs={12}>
						<FeatureListItem title={t('improve_business_relation')} description={t('improve_business_relation_info')} />
					</Grid>
				</Grid>
				<Stack direction={{ xl: 'row', md: 'column' }} sx={{ mt: { md: Dimens.APP_BAR_PADDING, xs: Dimens.SECTION_SPACING_MOBILE } }} justifyContent="space-around">
					<ProductListItem
						images={[phcGo, phcGoDev, phcCs]}
						title={t('micro_small_business')}
						description={t('micro_small_business_info')}
					/>
					<ProductListItem
						images={[phcAdvance]}
						title={t('mid_big_business')}
						description={t('mid_big_business_info')}
					/>
				</Stack>
			</Box>
		</section>
	);
};

export default SoftwareExpectation;
