/* eslint-disable class-methods-use-this */
import { SupportFeatureModel } from '../dto/support-feature-model';
import { SupportReviewModel } from '../dto/support-reviews-model';
import { TechnicalSupportApi } from './technical-support-api';

export class TechnicalSupportApiImpl implements TechnicalSupportApi {
	getSupportFeatures(): Promise<SupportFeatureModel[]> {
		try {
			const features = [{
				id: 1,
				title: 'Gestão diária da assistência pós-venda',
			},
			{
				id: 2,
				title: 'Análises detalhadas sobre o estado do seu suporte',
			},
			{
				id: 3,
				title: 'Automatização e agendamento de tarefas',
			},
			{
				id: 4,
				title: 'Simplificação de processos',
			},
			{
				id: 5,
				title: 'Configuração personalizada para as suas necessidades',
			},
			{
				id: 6,
				title: 'Informação acessível mais rapidamente',
			},
			{
				id: 7,
				title: 'Assistência a clientes via Internet',
			},
			{
				id: 8,
				title: 'Integração com a gestão financeira',
			},
			];

			const featurePromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(features);
				}, 300);
			});
			return featurePromise.then(() => features);
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}

	getSupportReviews(): Promise<SupportReviewModel[]> {
		try {
			const comments = [
				{
					id: 1, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 2, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 3, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 4, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
				{
					id: 5, name: 'Carlos Abreu', designation: 'Diretor de Informática', company: 'AJ TEC', comment: 'Os cinco principais benefícios reconhecidos pelas empresas que implementam sistemas de gestão e ERP são: facilidade no acesso à informação; melhoria da produtividade e eficiência; aumento da interação entre departamentos; eliminação de esforços duplicados; melhoria na qualidade e credibilidade da informação.”',
				},
			];
			const commentPromise = new Promise((resolve) => {
				setTimeout(() => {
					resolve(comments);
				}, 300);
			});
			return commentPromise.then(() => comments);
		} catch (error) {
			throw new Error('An Error Occurred');
		}
	}
}
