import { Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import { Archivebook, Calender, Timer } from 'assets/images';
import {
	BodyText, FeatureCard, SizedBox, TitleSmall,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BusinessNeeds = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Stack sx={{
				pt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				pr: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
				pl: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
			}}
			>
				<TitleSmall textAlign="center">
					{t('logistics_and_distribution_solution')}
				</TitleSmall>
				<SizedBox height={6} showLine align="center" />
			</Stack>
			<Stack
				direction="row"
				sx={{
					justifyContent: 'center',
					overflowX: 'scroll',
					'&::-webkit-scrollbar': { display: 'none' },
					scrollbarWidth: 'none',
					msOverflowStyle: 'none',
					pl: 2,
					pb: Dimens.PADDING_MEDIUM,
					mt: { md: Dimens.SECTION_SPACING_MOBILE, xs: Dimens.ICON_SPACING },
				}}
			>
				<FeatureCard icon={<Archivebook />} title={t('ensure_correct_inventory')} description={t('ensure_correct_inventory_info')} />
				<FeatureCard icon={<Calender />} title={t('compliance_with_delivery')} description={t('compliance_with_delivery_info')} />
				<FeatureCard icon={<Timer />} title={t('manage_volume_of_documents')} description={t('manage_volume_of_documents_info')} />
			</Stack>
			<Stack sx={{
				pt: { md: 9, xs: 5 },
				pr: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
				pl: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
			}}
			>
				<BodyText textAlign="center" darktext>{t('ideal_module_para_one')}</BodyText>
				<SizedBox height={3} />
				<BodyText textAlign="center" darktext>{t('ideal_module_para_two')}</BodyText>
			</Stack>
		</section>
	);
};

export default BusinessNeeds;
