import { CPhcBusinessRepositoryImpl } from 'features/phc-business-managment/data/repositories/phc-business-repository-impl';
import { PhcProduct } from 'features/phc-business-managment/domain/entities/phc-product';
import { PhcSolution } from 'features/phc-business-managment/domain/entities/phc-solution';
import { GetPhcProducts } from 'features/phc-business-managment/domain/usecases/get-phc-products';
import { GetPhcSolutions } from 'features/phc-business-managment/domain/usecases/get-phc-solutions';
import React, { useMemo } from 'react';

type TContextProps = {
	loading: boolean;
	error: string;
	phcProducts: PhcProduct[];
	getPhcProducts: () => Promise<void>
	solutionLoading: boolean;
	solutionError: string;
	phcSolutions: PhcSolution[];
	getPhcSolutions: () => Promise<void>
}

const PhcBusinessContext = React.createContext({} as TContextProps);

export const PhcBusinessProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState('');
	const [phcProducts, setPhcProducts] = React.useState([] as PhcProduct[]);

	const [solutionLoading, setSolutionLoading] = React.useState(false);
	const [solutionError, setSolutionError] = React.useState('');
	const [phcSolutions, setPhcSolutions] = React.useState([] as PhcSolution[]);

	const phcBusinessRepository = new CPhcBusinessRepositoryImpl();

	const getPhcProducts = async () => {
		setLoading(true);
		const phcProductUseCase = new GetPhcProducts(phcBusinessRepository);
		const productResult = await phcProductUseCase.call();
		if (productResult.isRight()) {
			setPhcProducts(productResult.value);
		} else {
			setError(productResult.error);
		}
		setLoading(false);
	};
	const getPhcSolutions = async () => {
		setSolutionLoading(true);
		const phcSolutionUseCase = new GetPhcSolutions(phcBusinessRepository);
		const solutionResult = await phcSolutionUseCase.call();
		if (solutionResult.isRight()) {
			setPhcSolutions(solutionResult.value);
		} else {
			setSolutionError(solutionResult.error);
		}
		setSolutionLoading(false);
	};

	const value = useMemo(() => ({
		loading,
		error,
		phcProducts,
		getPhcProducts,
		solutionError,
		solutionLoading,
		phcSolutions,
		getPhcSolutions,
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}), [
		loading,
		error,
		phcProducts,
		solutionError,
		solutionLoading,
		phcSolutions,
	]);

	return <PhcBusinessContext.Provider value={value}>{children}</PhcBusinessContext.Provider>;
};

export default function usePhcBusiness() {
	return React.useContext(PhcBusinessContext);
}
