/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Box,
	MenuItem,
	TextField as MuiTextField,
	OutlinedTextFieldProps,
	Typography,
	styled,
	useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import React from 'react';

type MuiOutlinedTextFieldProps = Omit<OutlinedTextFieldProps, 'variant'>

const TextField = styled(MuiTextField)(({ theme }) => ({
	fieldset: {
		borderColor: theme.palette.secondary.main,
	},
	'& label.Mui-focused': {
		color: theme.palette.secondary.main,
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: theme.palette.secondary.main,
	},
	'& .MuiFilledInput-underline:after': {
		borderBottomColor: theme.palette.secondary.main,
	},
	'& .MuiOutlinedInput-root': {
		'&.Mui-focused fieldset': {
			borderColor: theme.palette.secondary.main,
		},
	},
}));

interface Props extends MuiOutlinedTextFieldProps {
	label: string
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	options: string[]
	value: string
	title: string
}

const DefaultDropdwon: React.FC<Props> = (props) => {
	const {
		label,
		onChange,
		options,
		value,
		title,
		...rest
	} = props;
	const theme = useTheme();

	const StyledText = styled(Typography)(() => ({
		fontSize: Dimens.TEXT_MEDIUM,
		fontWeight: Dimens.TEXT_WEIGHT_LIGHT,
		lineHeight: Dimens.LINE_HEIGHT_MEDIUM,
		color: theme.palette.textMain.main,
	}));
	return (
		<Box sx={{ mt: 2 }}>
			<StyledText>{title}</StyledText>
			<TextField
				fullWidth
				placeholder={label}
				value={value}
				select
				onChange={onChange}
				size="small"
				variant="outlined"
				InputProps={{
					style: {
						borderTopLeftRadius: Dimens.FIELD_TOP_LEFT_RADIUS,
						borderTopRightRadius: Dimens.FIELD_TOP_RIGHT_RADIUS,
						borderBottomLeftRadius: Dimens.FIELD_BOTTOM_LEFT_RADIUS,
						borderBottomRightRadius: Dimens.FIELD_BOTTOM_RIGHT_RADIUS,
					},
				}}
				{...rest}
			>
				{options.map((mValue) => <MenuItem value={mValue}>{mValue}</MenuItem>)}
			</TextField>
		</Box>
	);
};

export default DefaultDropdwon;
