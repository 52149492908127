import { Box, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import { phcManagment } from 'assets/images';
import {
	ActionButtons, BodyText, SizedBox, TitleLarge,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ManagmentHero = () => {
	const { t } = useTranslation('translations');
	return (
		<section>
			<Box sx={{ pl: { md: Dimens.APP_BAR_PADDING, xs: 2 }, pr: { md: 0, xs: 2 }, pb: 5 }}>
				<ActionButtons text="Soluções PHC" showLine />
			</Box>
			<Stack
				alignItems="center"
				sx={{
					pl: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
					pr: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
					pt: Dimens.PADDING_MEDIUM,
					pb: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				}}
			>
				<TitleLarge textAlign="center">
					{t('phc_managment_software_business')}
				</TitleLarge>
				<SizedBox height={3} />
				<BodyText textAlign="center">
					{t('right_solution_for_company_one')}
				</BodyText>
				<BodyText textAlign="center">
					{t('right_solution_for_company_two')}
				</BodyText>
				<SizedBox height={3} />
				<img src={phcManagment} alt="PHC Software" width="100%" />
				<BodyText textAlign="center">
					{t('text_and_images_phc')}
				</BodyText>
			</Stack>
		</section>
	);
};

export default ManagmentHero;
