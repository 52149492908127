import React from 'react';
import { Box } from '@mui/material';
import ContactUsForm from 'features/contact-us/presentation/components/ContactUsForm';
import { ContactProvider } from 'features/contact-us/presentation/provider/contact.provider';
import Dimens from 'assets/dimens';
import CompanySolution from './CompanySolution';
import CompetitiveManagment from './CompetitiveManagment';
import ManagmentHero from './ManagmentHero';
import NextLevelBusiness from './NextLevelBusiness';
import { PhcBusinessProvider } from './provider/phc-business.provider';
import SoftwareExpectation from './SoftwareExpectation';

const PhcBusinessManagment = () => (
	<main>
		<PhcBusinessProvider>
			<ContactProvider>
				<ManagmentHero />
				<CompanySolution />
				<CompetitiveManagment />
				<SoftwareExpectation />
				<NextLevelBusiness />
				<Box sx={{
					pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					mt: Dimens.SECTION_SPACING_MOBILE,
					pb: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				}}
				>
					<ContactUsForm />
				</Box>
			</ContactProvider>
		</PhcBusinessProvider>
	</main>
);

export default PhcBusinessManagment;
