/* eslint-disable react-hooks/exhaustive-deps */
import { CFuelTecRepositoryImpl } from 'features/products-fueltec/data/repositories/fueltec-repository-impl';
import { IFuelTecPartner } from 'features/products-fueltec/domain/entities/fueltec-partner';
import { IFueltecReview } from 'features/products-fueltec/domain/entities/fueltec-reviews';
import { GetFueltecPartners } from 'features/products-fueltec/domain/usecases/get-fueltec-partners';
import { GetFueltecReviews } from 'features/products-fueltec/domain/usecases/get-fueltec-reviews';
import React from 'react';

type TContextProps = {
	loading: boolean;
	error: string;
	partners: IFuelTecPartner[];
	getPartners: () => Promise<void>;

	reviewsLoading: boolean;
	reviewsError: string;
	reviews: IFueltecReview[];
	getReviews: () => Promise<void>
}

export const FuelTecContext = React.createContext({} as TContextProps);

export const FuelTecProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState('');
	const [partners, setPartners] = React.useState([] as IFuelTecPartner[]);

	const [reviewsLoading, setReviewsLoading] = React.useState(false);
	const [reviewsError, setReviewsError] = React.useState('');
	const [reviews, setReviews] = React.useState([] as IFueltecReview[]);

	const fuelTecRepository = new CFuelTecRepositoryImpl();

	const getPartners = async () => {
		setLoading(true);
		const getFuelTecPartnerUseCase = new GetFueltecPartners(fuelTecRepository);
		const partnerResult = await getFuelTecPartnerUseCase.call();
		if (partnerResult.isRight()) {
			setPartners(partnerResult.value);
		} else {
			setError(partnerResult.error);
		}
		setLoading(false);
	};

	const getReviews = async () => {
		setReviewsLoading(true);
		const getReviewsUsecase = new GetFueltecReviews(fuelTecRepository);
		const reviewResult = await getReviewsUsecase.call();
		if (reviewResult.isRight()) {
			setReviews(reviewResult.value);
		} else {
			setReviewsError(reviewResult.error);
		}
		setReviewsLoading(false);
	};

	const value = React.useMemo(() => ({
		loading,
		error,
		partners,
		getPartners,
		reviewsLoading,
		reviewsError,
		reviews,
		getReviews,
	}), [loading,
		error,
		partners,
		reviewsLoading,
		reviewsError,
		reviews]);

	return <FuelTecContext.Provider value={value}>{children}</FuelTecContext.Provider>;
};

export default function useFuelTec() {
	return React.useContext(FuelTecContext);
}
