import { Either } from '@ajtec/either-typescript';
import { PhcSolution } from '../entities/phc-solution';
import { IPhcBusinessRepository } from '../repositories/phc-business-repository';

export class GetPhcSolutions {
	repository: IPhcBusinessRepository;

	constructor(homeRepository: IPhcBusinessRepository) {
		this.repository = homeRepository;
	}

	call(): Promise<Either<string, PhcSolution[]>> {
		return this.repository.getPhcSolutions();
	}
}
