import {
	Box, Stack, Typography, useTheme,
} from '@mui/material';
import Dimens from 'assets/dimens';
import React from 'react';
import ClientArea from '../client-area/ClientArea';
import RemoteAssistance from '../remote-assistance/RemoteAssistance';
import Space from '../space/Space';

interface Props {
	text?: string;
	showLine?: boolean;
	icon?: string;
}

const ActionButtons: React.FC<Props> = (props) => {
	const { text, showLine, icon } = props;
	const theme = useTheme();
	return (
		<Stack
			direction="row"
			justifyContent={(text || icon) ? 'space-between' : 'flex-end'}
			sx={{ mr: { xl: Dimens.APP_BAR_PADDING, md: 0 } }}
			display={{ md: 'flex', xs: 'none' }}
		>
			{icon && (
				<img src={icon} alt="Ajtec icon" width="60px" />
			)}
			{text && (
				<Stack direction="row" alignItems="center">
					{showLine && (
						<Box
							sx={{
								mr: 1,
								bgcolor: theme.palette.success.main,
								height: '2px',
								width: '50px',
								opacity: 0.3,
								borderRadius: '2px 0px',
								filter: 'blur(0.5px)',
							}}
						/>
					)}
					<Typography
						sx={{
							textShadow: showLine ? 'none' : '0px 3px 7px rgba(26, 72, 85, 0.25)',
							fontSize: showLine ? Dimens.TEXT_MEDIUM_SMALL : Dimens.TEXT_SUBTITLE,
							fontWeight: '600',
							color: theme.palette.textMain.main,
						}}
					>
						{text}
					</Typography>
				</Stack>
			)}
			<Stack direction="row">
				<RemoteAssistance />
				<Space width={Dimens.USER_ACTION_SPACING} />
				<ClientArea />
			</Stack>
		</Stack>
	);
};

export default ActionButtons;
