// import { Skeleton, Stack } from '@mui/material';
import { ImageScrollView } from 'common/presentation/components';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import React from 'react';
import { useInViewRef } from 'rooks';
import useAbout from './provider/about.provider';

const LabTour = () => {
	const { loading, getLabs, labs } = useAbout();

	const [labRef, inView] = useInViewRef();
	useEffectCustom(() => {
		if (inView && labs.length === 0) {
			getLabs();
		}
	}, [inView]);

	return (
		<section id="labs" ref={labRef}>
			<ImageScrollView labs={labs} loading={loading} />
		</section>
	);
};

export default LabTour;
