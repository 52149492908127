import { Either, Right } from '@ajtec/either-typescript';
import { IFuelTecPartner } from 'features/products-fueltec/domain/entities/fueltec-partner';
import { IFueltecReview } from 'features/products-fueltec/domain/entities/fueltec-reviews';
import { IFueltecRepository } from 'features/products-fueltec/domain/repositories/fueltec-repository';
import { FuelTecApiImpl } from '../remote/fueltec-api-impl';

export class CFuelTecRepositoryImpl implements IFueltecRepository {
	fueltecApi = new FuelTecApiImpl();

	async getFueltecPartners(): Promise<Either<string, IFuelTecPartner[]>> {
		try {
			const partners = await this.fueltecApi.getFueltecPartners();
			return new Right(partners);
		} catch (error) {
			throw new Error('An Error Occured');
		}
	}

	async getFuelTecReviews(): Promise<Either<string, IFueltecReview[]>> {
		try {
			const reviews = await this.fueltecApi.getFuelTecReviews();
			return new Right(reviews);
		} catch (error) {
			throw new Error('An Error Occured');
		}
	}
}
