import { Skeleton, Stack } from '@mui/material';
import Dimens from 'assets/dimens';
import { SizedBox, TitleMedium } from 'common/presentation/components';
import useEffectCustom from 'common/presentation/hooks/useEffectCustom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInViewRef } from 'rooks';
import i18n from 'assets/translations';
import { QuestionItem } from './components';
import useHome from './provider/home.provider';

const Faqs = () => {
	const { t } = useTranslation('translations');
	const [questionRef, inView] = useInViewRef();
	const { getQuestions, questions, questionLoading } = useHome();
	const lang = i18n.language;

	useEffectCustom(() => {
		if (inView && questions.length === 0) {
			getQuestions(lang);
		}
	}, [inView]);

	return (
		<section id="Questions" ref={questionRef}>
			<Stack
				alignItems="center"
				sx={{
					pl: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pr: { md: Dimens.APP_BAR_PADDING, xs: 2 },
					pt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
				}}
			>
				<TitleMedium textAlign="center">
					{t('common_questions')}
				</TitleMedium>
				<SizedBox height={7} />
				{!questionLoading && questions.map((el) => (
					<div key={el.id}>
						<QuestionItem question={el.question} answer={el.answer} />
						<SizedBox height={5} />
					</div>
				))}
				{questionLoading && [1, 2, 3, 4].map((el) => (
					<div key={el}>
						<Skeleton variant="text" sx={{ width: { md: '800px', xs: '250px' } }} height={40} />
						<SizedBox height={5} />
					</div>
				))}
			</Stack>
		</section>
	);
};

export default Faqs;
