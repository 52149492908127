import { ArrowRightAlt } from '@mui/icons-material';
import { Box, Card, useTheme } from '@mui/material';
import AppLinks from 'assets/applink.routes';
import Dimens from 'assets/dimens';
import {
	BodyText, DefaultButton, SizedBox, TitleMedium, TitleSmall,
} from 'common/presentation/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const IntelligentSolutions = () => {
	const { t } = useTranslation('translations');
	const { palette } = useTheme();
	const navigate = useNavigate();
	return (
		<section>
			<Box
				sx={{
					mt: { md: Dimens.SECTION_SPACING, xs: Dimens.SECTION_SPACING_MOBILE },
					ml: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
					mr: { xl: Dimens.PAGE_PADDING_LARGE, xs: 2 },
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<TitleMedium textAlign="center">{t('intelligent_solutions_for_client_success')}</TitleMedium>
				<SizedBox height={3} />
				<BodyText textAlign="center">{t('about_fueltec')}</BodyText>
				<SizedBox height={3} />
				<Card
					elevation={4}
					sx={{
						bgcolor: palette.primary.main,
						width: { md: Dimens.PARTNER_CARD_WIDTH, xs: Dimens.PARTNER_CARD_WIDTH_MOBILE },
						height: { md: Dimens.PARTNER_CARD_HEIGHT, xs: Dimens.PARTNER_CARD_HEIGHT_MOBILE },
						borderRadius: { md: Dimens.CARD_BORDER_RADIUS, xs: Dimens.CARD_BORDER_RADIUS_MOBILE },
						m: { xl: 3, xs: 1 },
					}}
				>
					<Card
						elevation={10}
						sx={{
							width: '100%',
							height: '99%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-end',
							alignItems: 'center',
							borderRadius: { md: Dimens.CARD_BORDER_RADIUS, xs: Dimens.CARD_BORDER_RADIUS_MOBILE },
						}}
					>
						<TitleSmall textAlign="center">PHC ADVANCE PARTNER</TitleSmall>
						<SizedBox height={2} />
						<BodyText sx={{ width: { md: '471px' } }} textAlign="center">
							{t('discover_fueltec')}
						</BodyText>
						<SizedBox height={4} />
						<DefaultButton variant="text" sx={{ p: 0, m: 0, mb: 5 }} onClick={() => navigate(AppLinks.FUEL_TEC)} endIcon={<ArrowRightAlt />}>{t('know_solutions')}</DefaultButton>
					</Card>
				</Card>
			</Box>
		</section>
	);
};

export default IntelligentSolutions;
